import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ModalComponent from '../../../components/ModalComponent';
import Paginator from '../../../components/Paginator';
import prefectures, { Pref } from '../../../services/prefs';
import { User } from '../../../services/user';
import { farmSchema } from '../../../validation/schemas/farmSchema';
import { Farm as FarmType } from './types';
import submit from '../../../utils/submit';
import Select from '../../../components/Form/Select';
import Input from '../../../components/Form/Input';
import Search from '../../../components/Search/Search';
import Table from '../../../components/Table/Table';
import ActionButton from '../../../components/Table/ActionButton';
import Card from '../Card';
import farm from '../../../services/farm';
import veterinarianService from '../../../services/veterinarian';

function Farm() {
  const formRef = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [prefs, setPrefs] = useState<Pref[]>([] as Pref[]);
  const [users, setUsers] = useState<User[]>([] as User[]);
  const [farms, setFarms] = useState<FarmType[]>([] as FarmType[]);
  const [selectedFarm, setSelectedFarm] = useState<FarmType>(null);
  const [links, setLinks] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [total, setTotal] = useState(0);
  const [deleting, setDeleting] = useState(false);
  const [creating, setCreating] = useState(false);
  const [backendErrorMessage, setBackendErrorMessage] = useState('');

  const {
    register, handleSubmit, formState: { errors }, reset,
  } = useForm<FarmType>({
    mode: 'onBlur',
    resolver: yupResolver(farmSchema),
    context: { isCreate: !!selectedFarm?.user_id },
    criteriaMode: 'all',
    defaultValues: selectedFarm,
  });

  const getFarms = (page?: number) => {
    if (!page) page = currentPage;
    farm.get(page, searchText).then((res) => {
      if (res.data.data.length === 0 && currentPage > 1) {
        getFarms(currentPage - 1);
      } else {
        setFarms(res.data.data.map((farm) => {
          farm.user_name = String(farm?.veterinarian?.veterinarian_name || '');
          farm.veterinarian_user_id = String(farm?.veterinarian?.user_id || '');
          return farm;
        }));
        setLinks(res.data.links);
        setCurrentPage(res.data.current_page);
        setTotal(res.data.total);
      }
    });
  };

  useEffect(() => {
    getFarms();
  }, [searchText]);

  useEffect(() => {
    prefectures.get().then((res) => {
      setPrefs(res.data.prefs);
    });

    veterinarianService.get(1, null, 99999).then((res) => {
      setUsers(res.data.data);
    });
  }, []);

  const resetForm = () => {
    reset({
      farm_id: '',
      pref_code: '',
      veterinarian_user_id: '',
      password: '',
      email: '',
    });
    setBackendErrorMessage('');
  };

  useEffect(() => {
    if (!selectedFarm) {
      resetForm();
    } else {
      reset(selectedFarm);
    }
  }, [selectedFarm]);

  const onDelete = () => {
    setDeleting(true);
    farm.destroy(selectedFarm.user_id).then(() => {
      getFarms();
    }).finally(() => {
      setShowDeleteModal(false);
      setDeleting(false);
      resetForm();
    });
  };

  const changePage = (page: number) => {
    getFarms(page);
  };

  const postOk = () => {
    getFarms();
    resetForm();
    setShowCreateModal(false);
    setCreating(false);
  };

  const postNg = (err: any) => {
    setBackendErrorMessage(err?.response?.data?.message || '');
    setCreating(false);
  };

  const onSubmit = handleSubmit(async (data) => {
    setCreating(true);
    if (selectedFarm && selectedFarm.farm_id) {
      farm.put(data).then(postOk).catch(postNg);
    } else {
      farm.post(data).then(postOk).catch(postNg);
    }
  });

  return (
    <main className="content p-3">
      <ModalComponent
        loading={creating}
        errorMessage={backendErrorMessage}
        closeOnConfirm={false}
        size="lg"
        title={`農場${selectedFarm ? '編集' : '新規作成'}`}
        confirmButtonText="OK"
        onConfirm={() => submit(formRef)}
        show={showCreateModal}
        closeModal={() => { setSelectedFarm(null); setShowCreateModal(false); }}
      >
        <div className=" m-3">
          <form
            onSubmit={onSubmit}
            ref={formRef}
          >
            <Input disabled={!!selectedFarm} title="ユーザーID" name="farm_id" error={errors?.farm_id?.message} register={register} />
            <Input type="password" title="ログインパスワード" name="password" error={errors?.password?.message} register={register} />
            <Select
              title="都道府県"
              name="pref_code"
              items={prefs?.map((pref) => ({ value: pref.pref_code, label: pref.pref_name }))}
              register={register}
              error={errors?.pref_code?.message}
            />
            <Select
              title="担当獣医師"
              name="veterinarian_user_id"
              items={users?.map((user) => ({ value: String(user.user_id), label: String(user.name) })) || []}
              register={register}
              error={errors?.veterinarian_user_id?.message}
            />
            <Input title="メールアドレス" name="email" error={errors?.email?.message} register={register} />
          </form>
        </div>
      </ModalComponent>

      <ModalComponent
        loading={deleting}
        closeOnConfirm={false}
        body={`${selectedFarm?.farm_id || ''}を削除してもよろしいですか？`}
        title="削除確認"
        confirmButtonText="削除"
        confirmButtonColor="danger"
        onConfirm={onDelete}
        show={showDeleteModal}
        closeModal={() => { setShowDeleteModal(false); setSelectedFarm(null); }}
      />

      <Card title="ユーザー管理" subTitle="農場一覧" onClickCreate={() => setShowCreateModal(true)}>
        <div className="row row-cols-auto g-1">
          <Search onSearch={(text) => setSearchText(text)} />
          <Paginator currentPage={currentPage} changePage={changePage} links={links} />
        </div>
        <Table>
          <thead>
            <tr>
              <th>農場ID</th>
              <th>都道府県</th>
              <th>担当獣医師</th>
              <th>メールアドレス</th>
              <th style={{ width: '9rem' }}>操作</th>
            </tr>
          </thead>
          <tbody>
            {farms.length === 0 && (
            <tr>
              <td colSpan={10} className="text-center">データはありません。</td>
            </tr>
            )}
            {
              farms && farms.map((farm) => (
                <tr key={farm.farm_id}>
                  <td>{farm.farm_id}</td>
                  <td>{farm.pref_name}</td>
                  <td>{farm.user_name}</td>
                  <td>{farm.email}</td>
                  <td className="table-action text-center">
                    <ActionButton
                      onClickDelete={() => {
                        setSelectedFarm({ ...farm }); setShowDeleteModal(true);
                      }}
                      onClickEdit={() => { setSelectedFarm({ ...farm }); setShowCreateModal(true); }}
                    />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        <div className="row row-cols-auto g-1 align-items-center">
          <div className="col ">
            <div className="page-item-count text-sm">
              全
              {' '}
              <span>{total}</span>
              {' '}
              件
            </div>
          </div>
          <Paginator currentPage={currentPage} changePage={changePage} links={links} />
        </div>
      </Card>
    </main>
  );
}

export default Farm;
