import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import farm, { FarmInfo } from '../services/farm';

export interface MonthsColorInterface {
  datePickerKey: number
  setDatePickerKey: Dispatch<SetStateAction<number>>,
  farmYearInfo: FarmInfo
  getInfo: () => void
}

export default function useMonthsColor(farms: any[], farmId: string): MonthsColorInterface {
  const [farmYearInfo, setFarmYearInfo] = useState<FarmInfo>(null);
  const [datePickerKey, setDatePickerKey] = useState(0);
  const getInfo = () => {
    if (farms.length > 0) {
      farm.getYearInfo(farmId).then((res) => {
        const newObject: FarmInfo = {};
        Object.keys(res.data).forEach((key) => {
          newObject[Number(key)] = res.data[Number(key)].map((item) => {
            item.color = (() => {
              if (item.registration_state === undefined || item.registration_state === null || item.registration_state === 0) {
                return 'month-green';
              }
              if (item.registration_state === 1) {
                return 'month-orange';
              }
              if (item.registration_state === 2) {
                return '';
              }
              return '';
            })();
            return item;
          });
        });

        setFarmYearInfo(newObject);
      });
    }
  };

  useEffect(() => {
    getInfo();
  }, [farmId]);

  return {
    datePickerKey,
    setDatePickerKey,
    farmYearInfo,
    getInfo,
  };
}
