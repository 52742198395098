import { AxiosResponse } from 'axios';
import api from '../contexts/GlobalContext';

interface Farm {
  farm_id: string
  value: number
}

interface LineItem {
  current_farm: number
  top10: number
  bottom10: number
  center: number
}

interface RegionLineItem {
  region_0: number
  region_1: number
  region_2: number
  region_3: number
  region_4: number
  region_5: number
  region_6: number
  region_7: number
}

interface ScaleLineItem {
  item_1: number
  item_2: number
  item_3: number
  item_4: number
}

interface Result {
  tickY: number
  tickX: number
  axisX: string[]
  axisY: number[]
  data: Record<string, Farm[]>
  name: string
  unit: string
}

interface RegionBoxPlotData {
  tickY: number
  tickX: number
  axisX: string[]
  axisY: number[]
  data: {
    bottom25: number
    center: number
    max: number
    min: number
    top25: number
    data: number[]
  }[]
  currentFarm: {
    farm_id: string
    region: string
    value: number
  }
  name: string
  unit: string
  currentFarmValue: number
}

interface ScaleBoxPlotData {
  tickY: number
  tickX: number
  axisX: string[]
  axisY: number[]
  data: {
    bottom25: number
    center: number
    max: number
    min: number
    top25: number
    data: number[]
  }[]
  currentFarm: {
    farm_id: string
    mother_pig: number
    value: number
  }
  name: string
  unit: string
  currentFarmValue: number
}

interface LineResult {
  tickY: number
  axisX: string[]
  axisY: number[]
  data: Record<number, LineItem>
  name: string
  unit: string
  currentFarmValue: number
}

interface RegionLineData {
  tickY: number
  axisX: string[]
  axisY: number[]
  data: Record<number, RegionLineItem>
  name: string
  unit: string
}

interface ScaleLineData {
  tickY: number
  axisX: string[]
  axisY: number[]
  data: Record<number, ScaleLineItem>
  name: string
  unit: string
}

export interface ReportCardResultRanking {
  name: string
  unit: string
  top10: number
  top25: number
  bottom10: number
  bottom25: number
  center: number
  data_count: number
  current_farm: {
    value : number
    rank: number
    score: number
  }
}

export interface ReportCardResult {
  data: Record<string, {
    name: string
    unit: string
    top10: number
    top25: number
    bottom10: number
    bottom25: number
    center: number
    data_count: number
    current_farm: {
      value : number
      rank: number
      score: string
    }
  }>
  name: string
}

export interface ProductivityTree {
  data: Record<string, {
    name: string
    unit: string
    top10: number
    top25: number
    bottom10: number
    bottom25: number
    center: number
    data_count: number
    current_farm: {
      value : number
      rank: number
      name: string
      score: number
    }
  }>
  name: string
}

export interface InfoData {
  count: number
  from: string
  to: string
}

export interface ChartBarData {
  current_farm: {
    current_farm_average: number
    count: number
  }[]
  data: Result[]
}

export interface ReportTransitionI {
  data: Record<number, Record<string, number>>
  labels: Record<string, {
    name: string
    unit: string
  }>
  name: string
}

export interface ChartLineData {
  data: LineResult[]
}

export const getBarData = async (calc: string, year: string, toYear: string, fromMonth: string, toMonth: string, farm: string): Promise<AxiosResponse<Result>> => api.get<Result>('/stats/histogram/year', {
  params: {
    farm_id: farm,
    year,
    toYear,
    from_month: fromMonth,
    to_month: toMonth,
    calc,
  },
});
export const getBoxPlotData = async (calc: string, year: string, toYear: string, fromMonth: string, toMonth: string, farm: string): Promise<AxiosResponse<RegionBoxPlotData>> => api.get<RegionBoxPlotData>('/stats/regionBoxPlot', {
  params: {
    farm_id: farm,
    year,
    toYear,
    from_month: fromMonth,
    to_month: toMonth,
    calc,
  },
});

export const getScaleBoxPlotData = async (calc: string, year: string, toYear: string, fromMonth: string, toMonth: string, farm: string): Promise<AxiosResponse<ScaleBoxPlotData>> => api.get<ScaleBoxPlotData>('/stats/scaleBoxPlot', {
  params: {
    farm_id: farm,
    year,
    toYear,
    from_month: fromMonth,
    to_month: toMonth,
    calc,
  },
});

export const getScaleLineData = async (calc: string, year: string, toYear: string, fromMonth: string, toMonth: string, farm: string): Promise<AxiosResponse<ScaleLineData>> => api.get<ScaleLineData>('/stats/scaleLine/year', {
  params: {
    farm_id: farm,
    year,
    toYear,
    from_month: fromMonth,
    to_month: toMonth,
    calc,
  },
});

export const getLineData = async (calc: string, year: string, toYear: string, fromMonth: string, toMonth: string, farm: string): Promise<AxiosResponse<LineResult>> => api.get<LineResult>('/stats/line/year', {
  params: {
    farm_id: farm,
    year,
    toYear,
    from_month: fromMonth,
    to_month: toMonth,
    calc,
  },
});

export const getRegionLineData = async (calc: string, year: string, toYear: string, fromMonth: string, toMonth: string, farm: string): Promise<AxiosResponse<RegionLineData>> => api.get<RegionLineData>('/stats/regionLine/year', {
  params: {
    farm_id: farm,
    year,
    toYear,
    from_month: fromMonth,
    to_month: toMonth,
    calc,
  },
});

export const reportCardData = async (year: string, toYear: string, fromMonth: string, toMonth: string, farm: string): Promise<AxiosResponse<ReportCardResult>> => api.get<ReportCardResult>('/stats/resultSheet', {
  params: {
    farm_id: farm,
    year,
    toYear,
    from_month: fromMonth,
    to_month: toMonth,
  },
});

export const resultSheetBenchmark = async (year: string, toYear: string, fromMonth: string, toMonth: string, farm: string): Promise<AxiosResponse<ReportCardResult>> => api.get<ReportCardResult>('/stats/resultSheetBenchmark', {
  params: {
    farm_id: farm,
    year,
    toYear,
    from_month: fromMonth,
    to_month: toMonth,
  },
});

export const reportTransitionData = async (year: string, toYear: string, fromMonth: string, toMonth: string, farm: string): Promise<AxiosResponse<ReportTransitionI>> => api.get<ReportTransitionI>('/stats/transitionGrade', {
  params: {
    farm_id: farm,
    year,
    toYear,
    from_month: fromMonth,
    to_month: toMonth,
  },
});

export const reportTransitionCenter = async (year: string, toYear: string, fromMonth: string, toMonth: string, farm: string): Promise<AxiosResponse<ReportTransitionI>> => api.get<ReportTransitionI>('/stats/transitionGrade/center', {
  params: {
    farm_id: farm,
    year,
    toYear,
    from_month: fromMonth,
    to_month: toMonth,
  },
});

export const productivityTreeData = async (year: string, toYear: string, fromMonth: string, toMonth: string, farm: string): Promise<AxiosResponse<ProductivityTree>> => api.get<ProductivityTree>('/stats/productivityTree', {
  params: {
    farm_id: farm,
    year,
    toYear,
    from_month: fromMonth,
    to_month: toMonth,
  },
});
export const reportRankingRadarChartData = async (year: string, toYear: string, fromMonth: string, toMonth: string, farm: string): Promise<AxiosResponse<ProductivityTree>> => api.get<ProductivityTree>('/stats/rank/item', {
  params: {
    farm_id: farm,
    year,
    toYear,
    from_month: fromMonth,
    to_month: toMonth,
  },
});

export const info = async (year: string, toYear: string, fromMonth: string, toMonth: string, farm: string): Promise<AxiosResponse<InfoData>> => api.get<InfoData>('/stats/info', {
  params: {
    farm_id: farm,
    year,
    toYear,
    from_month: fromMonth,
    to_month: toMonth,
  },
});
