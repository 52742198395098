import { AxiosResponse } from 'axios';
import api from '../contexts/GlobalContext';
import { Link } from '../components/Paginator';
import { defaultLimit } from './common';

export interface NoticeRequest {
  notice: string;
}

export interface Notice {
  notice_id: number;
  from_user_id: number;
  to_user_id: number;
  notice: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export type UserResponseNotice = Record<number, {
  farm_id: number;
  pref_code: string;
  veterinarian: {
    veterinarian_id: number;
    user_id: number;
    veterinarian_name: string;
  }
  user_id: number;
  login_id: string;
  name: string;
  type: number;
  pref_name: string;
}>;

interface NoticeResponse {
  data: {
    notices: Notice[]
    users:UserResponseNotice
  },
  links: Link[]
  current_page: number
  total: number
}

type NoticeDelete = {
  notice_id_list: number[]
};

export const get = async (page: number, searchText?: string, limit?: number): Promise<AxiosResponse<NoticeResponse>> => api.get<NoticeResponse>(`/notice/list?page=${page}&search=${searchText || ''}&limit=${limit || defaultLimit}`);

export const post = async (data: NoticeRequest): Promise<AxiosResponse<NoticeRequest>> => api.post<NoticeRequest>('/notice/post', data);

export const destroy = async (data: number) => {
  api.post<NoticeDelete>('/notice/delete', { notice_id_list: [data] });
};

export default {
  get,
  post,
  destroy,
};
