import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,

} from 'chart.js';

import { Radar } from 'react-chartjs-2';
import { Spinner } from 'react-bootstrap';
import numeral from 'numeral';
import { ProductivityTree, reportRankingRadarChartData } from '../../services/analysis';
import If from '../../components/If/If';
import useFetch from './analisysHook';
import { useAnalysisContext } from '../../contexts/AnalysisContext';
import formatDate from '../../utils/formatDate';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,

);

interface Props {
  onLoading?: (isLoading: boolean) => void
  largePageName?: string
  isPrintAll?: boolean
  printYear?: string
  printToYear?: string
  printFromMonth?: string
  printToMonth?: string
}

const reportRankingRadarChart: React.FC<Props> = ({
  onLoading, largePageName, isPrintAll, printYear, printToYear, printFromMonth, printToMonth,
}) => {
  // name: 項目別ランキング

  const [calcs] = useState({
    calc59: ['粗利益', '（/母豚/年）'],
    calc52: ['売上飼料比率'],
    calc48: ['農場枝肉FCR'],
    calc45: ['出荷頭数（/母豚/年）'],
    calc47: ['出荷枝肉重量'],
    calc67: ['飼料費（/枝肉）'],
    calc44: ['飼料価格（/ｋｇ）'],
    calc63: ['分娩回転率'],
    calc62: ['分娩率'],
    calc53: ['離乳後事故率'],
    calc57: ['販売額', '（/母豚/年）'],
    calc46: ['枝肉重量', '（/頭）'],
    calc43: ['枝肉価格', '（/ｋｇ）'],
    calc58: ['飼料費母豚（母豚/年）'],
    calc55: ['飼料費（/母豚）'],
    calc60: ['離乳子豚数（母豚/年）'],
    calc64: ['総産子数（/腹）'],
    calc65: ['生存産子数（/腹）'],
    calc66: ['離乳子豚数（/腹）'],
    calc69: ['哺乳中死亡率'],
    calc51: ['ワクチン抗菌材費（/肉豚）'],
    calc70: ['ワクチン抗菌材費', '（/母豚）'],
  });

  const [radarData, setRadarData] = useState({
    labels: [['']],
    datasets: [{
      label: '',
      data: [''],
      backgroundColor: 'rgba(153, 153, 255, 0.2)',
      borderColor: 'rgb(153, 153, 255)',
      pointBackgroundColor: 'rgb(153, 153, 255)',
      pointHoverBorderColor: 'rgb(153, 153, 255)',
    }],
  });

  const [radarOptions] = useState({
    animation: false as const,
    responsive: true,
    maintainAspectRatio: true,

    plugins: {
      tooltip: {
        // enabled: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        ticks: {
          display: false,
        },
        min: 0,
        max: 100,
        reverse: false,
        angleLines: {
          display: false,
        },
        pointLabels: {
          display: true,
          padding: 0,
          font: {
            size: 10,
          },
        },
      },
    },
  });

  const parseData = (data: ProductivityTree) => {
    const newObj = {
      ...radarData,
    };

    newObj.datasets[0].data = [];
    newObj.labels = [];

    Object.keys(data.data).forEach((key) => {
      const value = data.data[key].current_farm.rank === null ? null : 100 - ((data.data[key].current_farm.rank / data.data[key].data_count) * 100);
      newObj.datasets[0].data.push(value ? numeral(value.toString()).format('0,0.[0]') : null);
      newObj.labels.push(calcs[key as keyof typeof calcs]);
    });

    setRadarData({
      ...newObj,
    });
  };

  const { gettingData, farmName } = useFetch<ProductivityTree>(reportRankingRadarChartData, isPrintAll, onLoading, parseData);
  const { currentDataYear, currentDataToYear, currentDataToMonth, currentDataFromMonth } = useAnalysisContext();

  useEffect(() => () => (onLoading ? onLoading(false) : null), []);
  return (
    <>
      <If test={gettingData}>
        <div className="text-center p-5">
          <Spinner animation="border" />
        </div>
      </If>
      <section id="reportRankingRadarChart" style={{ display: gettingData ? 'none' : 'block' }}>
        <div className="page-break-before" />
        <div className="mt-2 row">
          <div className="flex-fill text-sm-start col-4">
            農場ID:
            {farmName}
            <label className="text-muted fs-sm" style={{ fontSize: '0.8rem' }}>
              （期間:
              <span>
                {printYear ?? currentDataYear}
                /
                { formatDate(printFromMonth ?? currentDataFromMonth, 'MM')}
                ～
                {printToYear ?? currentDataToYear}
                /
                {formatDate(printToMonth ?? currentDataToMonth, 'MM')}
                )
              </span>
            </label>
          </div>
          <div className="flex-fill text-sm-center fw-bold col-4">項目別ランキング</div>
          <div className="flex-fill text-sm-end text-sm col-4">{largePageName}</div>
        </div>
        <div className="border-bottom my-1" />

        <div className="text-sm text-danger">※順位を示しています。外側は上位、内側が下位になります。</div>
        <div className="text-sm text-danger">※個々の点にポインターを当てるとパーセンタイル（下位からの位置をパーセントで示した数値）を示します。</div>

        <div className="p-2 mt-2 ">
          <div className="row">
            <div className="col align-self-center ">
              <div className="chart chartRadarBox">
                {
                  !gettingData
                  && (
                  <Radar
                    height="520"
                    style={{
                      display: 'block', boxSizing: 'border-box', height: '520px', width: '979px',
                    }}
                    width="979"
                    id="chartLine"
                    options={radarOptions}
                    data={radarData}
                  />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default reportRankingRadarChart;
