import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Spinner } from 'react-bootstrap';
import dayjs from 'dayjs';
import { cancelState, sendData } from '../../services';
import DatePicker from '../../components/Form/DatePicker';
import DataTable from './Jspreadsheet/DataTable';
import FormulasTable from './Jspreadsheet/FormulasTable';
import { useJspreadsheetContext } from '../../contexts/JspreadsheetContext';
import If from '../../components/If/If';
import Select from '../../components/Select';
import ModalComponent from '../../components/ModalComponent';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { useUserContext } from '../../contexts/UserContext';
import config from '../../config';
import useMonthsColor from '../../hooks/useMonthsColor';
import useGetFarms from '../../hooks/useGetFarms';

function DataEntry() {
  const { jspreadsheetDataHasChanged, setJspreadsheetDataHasChanged } = useGlobalContext();
  const {
    getJsDataEntryPage, gettingData, setGettingData, setSendingData, entryData, backendData, date, setDate, setBackendErrorMessage, backendErrorMessage, setCalcsData, outOfRangeCount,
  } = useJspreadsheetContext();
  const { userCan, user } = useUserContext();
  const {
    farmId, farms, setFarmId, gettingFarms,
  } = useGetFarms();
  const {
    datePickerKey, setDatePickerKey, farmYearInfo, getInfo,
  } = useMonthsColor(farms, farmId);

  const [showConfirmExitModal, setShowConfirmExitModal] = useState(false);

  useEffect(() => {
    setDatePickerKey((prev) => prev + 1);
  }, [farmYearInfo]);

  const getData = (farm_id: string) => {
    if (jspreadsheetDataHasChanged) {
      setShowConfirmExitModal(true);
    } else {
      setBackendErrorMessage('');
      getJsDataEntryPage(date, farm_id);
    }
  };

  useEffect(() => {
    if (backendData) {
      getInfo();
    }
  }, [backendData]);

  useEffect(() => {
    if (farmId) {
      getData(farmId);
    }
  }, [date]);

  useEffect(() => {
    if (user.type === config.userType.farm) {
      setFarmId(user.farm_id);
      setGettingData(true);
      getJsDataEntryPage(date, user.farm_id || '');
    }
  }, []);

  useEffect(() => {
    if (farms.length > 0) {
      getData(farmId);
    }
  }, [farmId]);

  const sendCancelState = async () => {
    await cancelState(
      {
        registration_year: Number(date.split('-')[0]),
        registration_month: Number(date.split('-')[1]),
        farm_id: farmId,
      },
    ).then(() => {
      getJsDataEntryPage(date, farmId);
    });
  };

  const canShowCompleteButton = () => {
    if (!userCan('input:complete')) {
      return false;
    }
    if (!backendData) {
      return false;
    }
    if (backendData.currentMonth.registration_state === null) {
      return true;
    }
    if (!backendData?.currentMonth?.farm_id) {
      return false;
    }
    return [undefined, 0].includes(backendData.currentMonth.registration_state);
  };
  const canShowConfirmButton = () => {
    if (!userCan('input:confirm')) {
      return false;
    }
    if (!backendData?.currentMonth) {
      return false;
    }
    return [1, 2].includes(backendData.currentMonth.registration_state);
  };

  const handleData = async (registrationState: number, isFromEditing: boolean = false) => {
    setBackendErrorMessage('');
    if (backendData?.currentMonth?.farm_id === null) {
      backendData.currentMonth.registration_year = Number(date.split('-')[0]);
      backendData.currentMonth.registration_month = Number(date.split('-')[1]);
    }
    const removeComma = (value: string) => String(value).replace(/,/g, '');
    const newEntryData = {
      farm_id: backendData.farm.farm_id,
      registration_year: backendData.currentMonth.registration_year,
      registration_month: backendData.currentMonth.registration_month,
      variety_male: String(entryData[0]),
      variety_female: String(entryData[1]),
      mother_pig: removeComma(entryData[3]),
      units_shipped: removeComma(entryData[4]),
      self_produced_candidates: removeComma(entryData[5]),
      shipping_carcass_weight: removeComma(entryData[6]),
      total_carcass_sales_amount: removeComma(entryData[7]),
      place_expenses: removeComma(entryData[8]),
      fare: removeComma(entryData[9]),
      net_income_of_total_carcass_sales: removeComma(entryData[10]),
      total_feed_purchase_amount_overall: removeComma(entryData[11]),
      total_feed_purchase_amount_sire_pig: removeComma(entryData[12]),
      total_feed_purchase_price_overall: removeComma(entryData[13]),
      fund_reserve_fund: removeComma(entryData[14]),
      fund_compensation_money: removeComma(entryData[15]),
      total_feed_purchase_price_sire_pig: removeComma(entryData[16]),
      vaccine_antibacterial_agent_costs: removeComma(entryData[17]),
      pigs_in_stock_after_weaning: removeComma(entryData[18]),
      suckling_pigs_in_stock: removeComma(entryData[19]),
      management_form: entryData[20],
      accidents_after_weaning: removeComma(entryData[21]),
      copulation: removeComma(entryData[22]),
      confinement: removeComma(entryData[23]),
      total_offspring: removeComma(entryData[24]),
      surviving_offspring: removeComma(entryData[25]),
      wean_piglets: removeComma(entryData[26]),
      pig_farm_workers: removeComma(entryData[27]),
      delivery_crates: removeComma(entryData[28]),
      fattening_area: removeComma(entryData[29]),
      presence_or_absence_of_prrs: removeComma(entryData[30]),
      piglets_shipped: removeComma(entryData[31]),
      net_income_of_piglet_sales: removeComma(entryData[32]),
      day_old_of_shipping_piglets: removeComma(entryData[33]),
      average_shipping_weight_of_piglets: removeComma(entryData[34]),
    };
    setSendingData(true);
    sendData({ ...newEntryData, registration_state: registrationState || (backendData.currentMonth.registration_state || 0) }).then((response: any) => {
      setCalcsData(response?.data?.data?.calcs || {});
      setJspreadsheetDataHasChanged(false);
      // if (refresh) {
      getJsDataEntryPage(date, farmId, false, isFromEditing);
      // }
      getInfo();
    }).catch((e) => {
      if (e.response && e.response.data && e.response.data.message) {
        setBackendErrorMessage(e.response.data.message);
      }
    }).finally(() => {
      setSendingData(false);
    });
  };

  const registrationStateIsCommitted = () => backendData?.currentMonth?.registration_state === config.registrationState.committed;

  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalBody2, setModalBody2] = useState('');
  const [modalBody3, setModalBody3] = useState('');
  const [modalButtonText, setModalButtonText] = useState('');
  const [modalButtonColor, setModalButtonColor] = useState('');
  const [desiredAction, setDesiredAction] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const checkEmptyFields = (action: number) => {
    let hasEmptyValue = false;
    entryData.forEach((item: any, index) => {
      if (index !== 2 && index !== 34 && index !== 35 && item === '') {
        hasEmptyValue = true;
      }
    });
    setModalBody(registrationStateIsCommitted() ? '' : hasEmptyValue ? '・未入力の項目があります。データを入力してください。' : '');
    setModalBody2(registrationStateIsCommitted() ? '' : outOfRangeCount > 0 ? '・入力値を用いた計算結果の値が基準値を超えています。' : '');
    setModalBody3((outOfRangeCount > 0 || hasEmptyValue) ? 'データ入力を完了してよろしいですか？' : 'データを完了します。よろしいですか？');
    if (action === config.registrationState.done) {
      setModalTitle('完了確認');
      setModalButtonText('完了する');
      setDesiredAction(config.registrationState.done);
      setModalButtonColor('primary');
      setModalBody3((outOfRangeCount > 0 || hasEmptyValue) ? 'データ入力を完了してよろしいですか？' : 'データを完了します。よろしいですか？');
      setShowModal(true);
    } else if (action === config.registrationState.committed) {
      setModalTitle(registrationStateIsCommitted() ? '確定解除確認' : '確定確認');
      setModalButtonText(registrationStateIsCommitted() ? '確定を解除する' : '確定する');
      setDesiredAction(registrationStateIsCommitted() ? null : config.registrationState.committed);
      setModalBody3(registrationStateIsCommitted() ? '確定を解除します。よろしいですか？' : (outOfRangeCount > 0 || hasEmptyValue) ? 'データ入力を確定してよろしいですか？' : 'データを確定します。よろしいですか？');
      setModalButtonColor(registrationStateIsCommitted() ? 'warning' : 'primary');
      setShowModal(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <main className="content p-3 h-100 ">
        <div className="container-fluid p-0 h-100 ">
          <div className="mb-1 d-flex justify-content-between">
            <h4 className="h4 d-inline align-middle">データ入力</h4>
          </div>
          <div className="row h-100">
            <div className="col-12 h-100">

              <div className="card " style={{ minHeight: '96%', height: '100% !important' }}>
                <div className="card-header">
                  <div className="row row-cols-auto g-1">
                    <If test={userCan('input:search')}>
                      <div className="col">
                        <div className="row row-cols-auto g-1 me-1">
                          <label className="col col-form-label">農場：</label>
                          <div className="col">
                            <Select hasSelectMessage={farms.length === 0} disabled={user.type === config.userType.farm} value={farmId} options={farms?.map((farm) => ({ value: farm.farm_id, label: farm.farm_id }))} onChange={(param) => setFarmId(param)} />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row row-cols-auto g-1 me-2">
                          <div className="col">
                            <label className="form-control-plaintext"> 年月： </label>
                          </div>
                          <div className="col">
                            <DatePicker
                              key={datePickerKey}
                              monthsColor={farmYearInfo}
                              initialDate={dayjs(date).format('YYYY/MM') || dayjs().format('YYYY/MM')}
                              onChange={(e) => setDate(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </If>

                    <div className="col ms-auto">
                      <If test={canShowCompleteButton()}>
                        <Button
                          variant="primary"
                          onClick={() => checkEmptyFields(config.registrationState.done)}
                          className="me-2">
                          完了
                        </Button>
                      </If>
                      <If test={canShowConfirmButton()}>
                        <Button
                          variant={registrationStateIsCommitted() ? 'warning' : 'primary'}
                          onClick={() => (checkEmptyFields(config.registrationState.committed))}
                          className="me-2"
                        >
                          {registrationStateIsCommitted() ? '確定解除' : '確定'}
                        </Button>
                      </If>
                      <div className="text-danger mt-2">
                        { backendErrorMessage }
                      </div>
                      <ModalComponent
                        title={modalTitle}
                        body={(
                          <div>
                            <div style={{ color: '#FF0000', fontSize: '17px' }}>
                              <div>
                                {modalBody}
                              </div>
                              <div>
                                {modalBody2}
                              </div>
                            </div>
                            {modalBody3}
                          </div>
                        )}
                        confirmButtonText={modalButtonText}
                        confirmButtonColor={modalButtonColor}
                        onConfirm={() => (desiredAction === null ? sendCancelState() : handleData(desiredAction))}
                        show={showModal}
                        closeModal={() => setShowModal(false)}
                      />
                    </div>
                  </div>
                </div>

                <If test={gettingData || gettingFarms}>
                  <div className="text-center p-5">
                    <Spinner animation="border" />
                  </div>
                </If>
                <div className="card-body pb-0 mb-0" style={{ display: (gettingData || gettingFarms) ? 'none' : 'block' }}>
                  <DataTable onChange={() => handleData(null, true)} />
                  <span className="me-2" />
                  <FormulasTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ModalComponent
        title="警告"
        body="入力内容が保存されていません。検索を実行した場合、入力内容が破棄されます。"
        confirmButtonText="検索"
        confirmButtonColor="success"
        onConfirm={() => getJsDataEntryPage(date, farmId)}
        show={showConfirmExitModal}
        closeModal={() => setShowConfirmExitModal(false)}
      />
    </>

  );
}

export default DataEntry;