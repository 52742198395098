import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'react-bootstrap';

interface Props {
  title: string;
  body?: any | string;
  cancelButtonText?: string;
  cancelButtonColor?: string;
  confirmButtonText?: string;
  confirmButtonColor?: string;
  onConfirm?: () => void;
  show: boolean;
  closeModal: () => void;
  size?: 'sm' | 'lg' | 'xl';
  children?: React.ReactNode;
  closeOnConfirm?: boolean;
  onCloseModal?: () => void;
  loading?: boolean;
  errorMessage?: string;
}

const ModalComponent: React.FC<Props> = ({
  title, body, cancelButtonText = 'キャンセル', cancelButtonColor = 'secondary',
  confirmButtonText, confirmButtonColor = 'primary', onConfirm, show,
  closeModal, size = '', children, closeOnConfirm = true, onCloseModal = () => {}, loading = false, errorMessage = '',
}) => {
  const handleClose = () => {
    closeModal();
    onCloseModal();
  };
  const handleConfirm = () => {
    if (closeOnConfirm) {
      handleClose();
    }
    onConfirm();
  };

  return (
    <Modal className={`modal-${size}`} show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="text-danger">
          {errorMessage}
        </div>
        {body}
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} variant={cancelButtonColor} onClick={handleClose}>
          {cancelButtonText}
        </Button>
        {confirmButtonText && (
        <Button disabled={loading} variant={confirmButtonColor} onClick={handleConfirm}>
          {confirmButtonText}
          {' '}
          {loading ? <Spinner animation="border" size="sm" /> : ''}
        </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;
