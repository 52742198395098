import yup from '../yup';

// const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = [
  'application/pdf',
];
export const reportSchema = yup.object().shape({
  files: yup
    .mixed()
    .required('ファイルを選択してください。')
    // .test(
    //   'fileSize',
    //   'File too large',
    //   (value) => value && value.size <= FILE_SIZE,
    // )
    .test(
      'fileFormat',
      'Unsupported Format',
      (value) => {
        if (value && value?.length > 0) {
          return SUPPORTED_FORMATS.includes(value[0].type);
        }
        return false;
      },
    ),
  comment: yup.string()
    .nullable()
    // .required('コメントを入力してください。')
    // .max(1000, '入力文字数は1000文字以下にしてください。')
    .label('コメント'),
});
