import React, { useEffect, useState } from 'react';
import {
  faChartColumn, faCommentAlt, faDatabase, faFileAlt, faFilePdf, faInfoCircle, faPenToSquare, faSignal,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useUserContext } from '../../contexts/UserContext';
import comment, { Comment as CommentInterface, UserResponse } from '../../services/comment';
import notice, { Notice as NoticeInterface, UserResponseNotice } from '../../services/notice';
import config from '../../config';
import parse from 'html-react-parser';

dayjs.extend(relativeTime);

const Main: React.FC = () => {
  const navigate = useNavigate();
  const { userCan } = useUserContext();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, path: string) => {
    e.preventDefault();
    navigate(path);
  };

  const [comments, setComments] = useState<CommentInterface[]>([] as CommentInterface[]);
  const [notices, setNotices] = useState<NoticeInterface[]>([] as NoticeInterface[]);
  const [users, setUsers] = useState({} as UserResponse);
  const [usersNotice, setUsersNotice] = useState({} as UserResponseNotice);
  const { user } = useUserContext();

  const getComments = (page?: number) => {
    comment.get(page, '', 99999999999).then((res) => {
      setComments(res.data.data.messages);
      setUsers(res.data.data.users);
    });
  };

  const getNotices = (page?: number) => {
    notice.get(page, '', 99999999999).then((res) => {
      setNotices(res.data.data.notices);
      setUsersNotice(res.data.data.users);
    });
  };

  useEffect(() => {
    getComments();
    getNotices();
  }, []);

  return (
    <main className="content p-3">
      <div className="container-fluid p-0">

        {
          notices.length > 0
          && (
            <div className="row">
              <div className="col mb-md-0">
                <div className="card" style={{ height: '450px', minHeight: '300px', maxHeight: '600px' }}>
                  <div className="card-header">
                    <h5 className="card-title mb-0">お知らせ</h5>
                  </div>
                  <div className="card-body h-100 pt-3">

                    <div className="border p-3 mb-2 overflow-auto" style={{ height: '310px' }}>
                      {notices.map((notice, index) => (

                        <React.Fragment key={notice.notice_id}>
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <small className="float-end text-navy">{dayjs(notice.created_at).locale('ja').fromNow()}</small>
                              <strong>{usersNotice[notice.from_user_id]?.name || usersNotice[notice.from_user_id].farm_id || usersNotice[notice.from_user_id].login_id}</strong>
                              <br />
                              <small className="text-muted">{dayjs(notice.created_at).format('YYYY/MM/DD HH:mm')}</small>
                              <br />

                              <div className="py-1 mt-2">
                                {parse(notice.notice)}
                              </div>
                            </div>
                          </div>
                          {
                            notices.length - 1 !== index && <hr className="my-2" />
                          }
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="d-grid">
                      <a href="#" onClick={(e) => handleClick(e, '/notice')} className="btn btn-lg btn-secondary py-2 mt-1">お知らせ一覧へ</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        {
          (user.type === config.userType.farm || user.type === config.userType.veterinarian)
          && (
          <div className="row">
            <div className="col mb-md-0">
              <div className="card" style={{ height: '450px', minHeight: '300px', maxHeight: '600px' }}>
                <div className="card-header">
                  <h5 className="card-title mb-0">最新コメント</h5>
                </div>
                <div className="card-body h-100 pt-3">

                  <div className="border p-3 mb-2 overflow-auto" style={{ height: '310px' }}>
                    {comments.map((comment, index) => (

                      <React.Fragment key={comment.message_id}>
                        <div className="d-flex align-items-start">
                          <div className="flex-grow-1">
                            <small className="float-end text-navy">{dayjs(comment.created_at).locale('ja').fromNow()}</small>
                            <strong>{users[comment.from_user_id].name || users[comment.from_user_id].farm_id}</strong>
                            →
                            <strong>{users[comment.to_user_id].name || users[comment.to_user_id].farm_id}</strong>
                            <br />
                            <small className="text-muted">{dayjs(comment.created_at).format('YYYY/MM/DD HH:mm')}</small>
                            <br />

                            <div className="py-1 mt-2">
                              {comment.message}
                            </div>
                          </div>
                        </div>
                        {
                          comments.length - 1 !== index && <hr className="my-2" />
                        }
                      </React.Fragment>
                    ))}
                  </div>
                  <div className="d-grid">
                    <a href="#" onClick={(e) => handleClick(e, '/comment')} className="btn btn-lg btn-secondary py-2 mt-1">コメント一覧へ</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )
        }

        <div className="row pt-2">
          <div className="col mb-md-0">
            <div className="card border shadow-none">
              <div className="card-body h-100">
                <div className="row">
                  <div className="col-sm-4 mb-3 mb-md-0">
                    <a
                      onClick={(e) => handleClick(e, '/data-entry')}
                      href="#"
                      className="btn btn-menu-lg btn-tertiary w-100 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div className="fs-3">
                        <FontAwesomeIcon icon={faPenToSquare} />
                        {' '}
                        データ入力
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-4 mb-3 mb-md-0">
                    <a
                      onClick={(e) => handleClick(e, '/analysis')}
                      href="#"
                      className="btn btn-menu-lg btn-tertiary w-100 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div className="fs-3">
                        <FontAwesomeIcon icon={faChartColumn} />
                        {' '}
                        データ解析
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-4 mb-3 mb-md-0">
                    <a
                      href="#"
                      onClick={(e) => handleClick(e, '/comment')}
                      className="btn btn-menu-lg btn-tertiary w-100 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div className="fs-3">
                        <FontAwesomeIcon icon={faCommentAlt} />
                        {' '}
                        コメント
                      </div>
                    </a>
                  </div>
                </div>
                {
                userCan('user_management:view')
                && (
                <div className="row pt-4">
                  <div className="col-sm-4 mb-3 mb-md-0">
                    <a
                      onClick={(e) => handleClick(e, '/farm_master')}
                      href="#"
                      className="btn btn-menu-lg btn-secondary w-100 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div className="fs-3">
                        <FontAwesomeIcon icon={faDatabase} />
                        {' '}
                        農場管理
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-4 mb-3 mb-md-0">
                    <a
                      onClick={(e) => handleClick(e, '/veterinarian_master')}
                      href="#"
                      className="btn btn-menu-lg btn-secondary w-100 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div className="fs-3">
                        <FontAwesomeIcon icon={faDatabase} />
                        {' '}
                        獣医師管理
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-4 mb-3 mb-md-0">
                    <a
                      onClick={(e) => handleClick(e, '/clerk_master')}
                      href="#"
                      className="btn btn-menu-lg btn-secondary w-100 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div className="fs-3">
                        <FontAwesomeIcon icon={faDatabase} />
                        {' '}
                        事務局管理
                      </div>
                    </a>
                  </div>
                </div>
                )
                }

                <div className="row pt-4">
                  <div className="col-sm-4 mb-3 mb-md-0">
                    <a
                      onClick={(e) => handleClick(e, '/notice')}
                      href="#"
                      className="btn btn-menu-lg btn-secondary w-100 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div className="fs-3">
                        <FontAwesomeIcon icon={faInfoCircle}/>
                        {' '}
                        お知らせ
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-4 mb-3 mb-md-0">
                    <a
                        onClick={(e) => handleClick(e, '/report')}
                        href="#"
                        className="btn btn-menu-lg btn-secondary w-100 h-100 d-flex justify-content-center align-items-center"
                    >
                      <div className="fs-3">
                        <FontAwesomeIcon icon={faFilePdf} />
                        {' '}
                        レポート管理
                      </div>
                    </a>
                  </div>
                  {
                  user.type === config.userType.clerk
                    && (
                      <>
                        <div className="col-sm-4 mb-3 mb-md-0">
                          <a
                            onClick={(e) => handleClick(e, '/farm_status')}
                            href="#"
                            className="btn btn-menu-lg btn-secondary w-100 h-100 d-flex justify-content-center align-items-center"
                          >
                            <div className="fs-3">
                              <FontAwesomeIcon icon={faSignal} />
                              {' '}
                              農場ステータス
                            </div>
                          </a>
                        </div>
                      </>
                    )
                  }
                </div>
                <div className="row pt-4">
                  {
                    (user.type === config.userType.clerk) && (
                      <div className="col-sm-4 mb-3 mb-md-0">
                        <a
                          onClick={(e) => handleClick(e, '/csv_download')}
                          href="#"
                          className="btn btn-menu-lg btn-secondary w-100 h-100 d-flex justify-content-center align-items-center"
                        >
                          <div className="fs-3">
                            <FontAwesomeIcon icon={faFileAlt} />
                            <i
                              className="fa-solid fa-file-alt fa-fw"
                            />
                            {' '}
                            CSVダウンロード
                          </div>
                        </a>
                      </div>
                      )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Main;
