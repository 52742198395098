import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ModalComponent from '../../components/ModalComponent';
import Paginator from '../../components/Paginator';
import comment, { Comment as CommentInterface, CommentRequest, UserResponse } from '../../services/comment';
import submit from '../../utils/submit';
import Input from '../../components/Form/Input';
import Search from '../../components/Search/Search';
import Table from '../../components/Table/Table';
import Card from '../Master/Card';
import { commentSchema } from '../../validation/schemas/commentSchema';
import { useUserContext } from '../../contexts/UserContext';
import Select from '../../components/Form/Select';
import farm from '../../services/farm';
import config from '../../config';
import TextArea from '../../components/Form/TextArea';

function Comment() {
  const formRef = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [comments, setComments] = useState<CommentInterface[]>([] as CommentInterface[]);
  const [selectedComment, setSelectedComment] = useState<CommentInterface>(null);
  const [links, setLinks] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [total, setTotal] = useState(0);
  const [deleting, setDeleting] = useState(false);
  const [creating, setCreating] = useState(false);
  const [backendErrorMessage, setBackendErrorMessage] = useState('');
  const [users, setUsers] = useState({} as UserResponse);
  const { user } = useUserContext();
  const [farms, setFarms] = useState([]);

  const {
    register, handleSubmit, formState: { errors }, reset,
  } = useForm<CommentRequest>({
    mode: 'onBlur',
    resolver: yupResolver(commentSchema),
    criteriaMode: 'all',
    defaultValues: selectedComment,
  });

  useEffect(() => {
    farm.get(1, '', 999999).then((res) => {
      let farms = res.data.data;
      if (user.type === config.userType.veterinarian) {
        farms = farms.filter((farm) => farm.veterinarian.veterinarian_id === user.login_id);
      }
      setFarms(farms);
    });
  }, []);

  const getComments = (page?: number) => {
    if (!page) page = currentPage;
    comment.get(page, searchText).then((res) => {
      if (res.data.data.messages.length === 0 && currentPage > 1) {
        getComments(currentPage - 1);
      } else {
        setComments(res.data.data.messages);
        setUsers(res.data.data.users);
        setLinks(res.data.links);
        setCurrentPage(res.data.current_page);
        setTotal(res.data.total);
      }
    });
  };

  useEffect(() => {
    getComments();
  }, [searchText]);

  const resetForm = () => {
    reset({
      to_user_id: user.type === config.userType.farm ? user.veterinarian[0].user_id : null,
      message: '',
    });
    setBackendErrorMessage('');
  };

  useEffect(() => {
    if (!selectedComment) {
      resetForm();
    } else {
      reset(selectedComment);
    }
  }, [selectedComment]);

  const onDelete = () => {
    setDeleting(true);
    comment.destroy(selectedComment.message_id).then(() => {
      setTimeout(() => {
        getComments();
      }, 50);
    }).finally(() => {
      setShowDeleteModal(false);
      setDeleting(false);
      resetForm();
    });
  };

  const changePage = (page: number) => {
    getComments(page);
  };

  const postOk = () => {
    getComments();
    resetForm();
    setShowCreateModal(false);
    setCreating(false);
  };

  const postNg = (err: any) => {
    setBackendErrorMessage(err?.response?.data?.message || '');
    setCreating(false);
  };

  const onSubmit = handleSubmit(async (data) => {
    setCreating(true);
    comment.post(data).then(postOk).catch(postNg);
  });

  return (
    <main className="content p-3">
      <ModalComponent
        loading={creating}
        errorMessage={backendErrorMessage}
        closeOnConfirm={false}
        size="lg"
        title="コメントを投稿"
        confirmButtonText="OK"
        onConfirm={() => submit(formRef)}
        show={showCreateModal}
        closeModal={() => { setSelectedComment(null); setShowCreateModal(false); }}
      >
        <div className=" m-3">
          <form
            onSubmit={onSubmit}
            ref={formRef}
          >
            {
              user.type === config.userType.farm
            && <Input disabled title="獣医師" defaultValue={user.veterinarian[0].veterinarian_name} />
            }
            {user.type === config.userType.veterinarian && (
              <Select
                title="農場"
                name="to_user_id"
                items={farms?.map((farm) => ({ value: farm.user_id, label: farm.farm_id }))}
                register={register}
                error={errors?.to_user_id?.message}
              />
            )}
            <TextArea title="コメント" name="message" error={errors?.message?.message} register={register} />
          </form>
        </div>
      </ModalComponent>

      <ModalComponent
        loading={deleting}
        closeOnConfirm={false}
        body="コメントを削除してもよろしいですか？"
        title="削除確認"
        confirmButtonText="削除"
        confirmButtonColor="danger"
        onConfirm={onDelete}
        show={showDeleteModal}
        closeModal={() => { setShowDeleteModal(false); setSelectedComment(null); }}
      />

      <Card createButtonText="コメントを投稿" showCreateButton={user.type === config.userType.farm || user.type === config.userType.veterinarian} title="コメント" subTitle="コメント一覧" onClickCreate={() => setShowCreateModal(true)}>
        <div className="row row-cols-auto g-1">
          <Search onSearch={(text) => setSearchText(text)} />
          <Paginator currentPage={currentPage} changePage={changePage} links={links} />
        </div>
        <Table>
          <thead>
            <tr>
              <th>投稿者</th>
              <th>受信者</th>
              <th>コメント</th>
              <th>投稿日時</th>
              <th style={{ width: '9rem' }}>操作</th>
            </tr>
          </thead>
          <tbody>
            {comments.length === 0 && (
              <tr>
                <td colSpan={10} className="text-center">データはありません。</td>
              </tr>
            )}
            {
               comments && comments.map((comment) => (
                 <tr key={comment.message_id}>
                   <td style={{ minWidth: '100px' }}>{users[comment.from_user_id].name || users[comment.from_user_id].farm_id}</td>
                   <td style={{ minWidth: '100px' }}>{users[comment.to_user_id].name || users[comment.to_user_id].farm_id}</td>
                   <td style={{ whiteSpace: 'normal' }}>{comment.message}</td>
                   <td style={{ minWidth: '200px' }}>{dayjs(comment.created_at).format('YYYY/MM/DD HH:mm')}</td>
                   <td className="table-action text-center">
                     {
                        comment.from_user_id === user?.user_id && (
                        <div className="btn-group btn-group-sm">
                          <button
                            onClick={() => {
                              setSelectedComment({ ...comment }); setShowDeleteModal(true);
                            }}
                            type="button"
                            className="btn btn-outline-secondary"
                          >
                            <FontAwesomeIcon icon={faTrash} className="me-1" />
                            削除
                          </button>
                        </div>
                        )
                     }
                   </td>
                 </tr>
               ))
             }
          </tbody>
        </Table>
        <div className="row row-cols-auto g-1 align-items-center">
          <div className="col ">
            <div className="page-item-count text-sm">
              全
              {' '}
              <span>{total}</span>
              {' '}
              件
            </div>
          </div>
          <Paginator currentPage={currentPage} changePage={changePage} links={links} />
        </div>
      </Card>
    </main>
  );
}

export default Comment;
