const isEmpty = (str: string) => str === null || str === undefined || str === '' || typeof str !== 'string' || str.trim() === '';

const format = (str: string, prefix = '', suffix = '') => {
  if (isEmpty(str)) return '';
  return prefix + str + suffix;
};

export default {
  isEmpty,
  format,
};
