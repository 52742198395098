import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { useUserContext } from '../contexts/UserContext';

function UnauthenticatedRoute({ children }: React.PropsWithChildren<unknown>): JSX.Element {
  const location = useLocation();

  const { isAuthenticated } = useUserContext();
  const query = parse(location.search);
  const regex = /^\/\d*/gi;
  const redirect = regex.test(query?.redirect as string) ? query.redirect as string : '/';

  if (!children) return null;
  if (isAuthenticated && redirect) return <Navigate to={redirect} />;

  return <>{children}</>;
}

export default UnauthenticatedRoute;
