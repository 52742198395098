import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons/faAnglesLeft';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons/faAnglesRight';

export interface Link {
  url: string | null
  active: boolean
  label: string
}

interface Props {
  links: Link[]
  changePage: (page: number) => void
  currentPage: number
}

const Paginator: React.FC<Props> = ({ links, changePage, currentPage }) => {
  const render = () => links && links.map((link: Link) => {
    if (link.label.includes('Previous')) {
      return (
        <li className={`page-item ${link.url ? '' : 'disabled'}`} key={Math.random()}>
          <button type="button" className="page-link" onClick={() => changePage(currentPage - 1)}>
            <FontAwesomeIcon icon={faAnglesLeft} />
          </button>
        </li>
      );
    } if (link.label.includes('Next')) {
      return (
        <li className={`page-item ${link.url ? '' : 'disabled'}`} key={Math.random()}>
          <button type="button" className="page-link" onClick={() => changePage(currentPage + 1)}>
            <FontAwesomeIcon icon={faAnglesRight} />
          </button>
        </li>
      );
    }
    return (
      <li className={`page-item ${link.active ? 'active' : ''}`} key={Math.random()}>
        <button type="button" className="page-link" onClick={() => (isNaN(Number(link.label)) ? () => {} : changePage(Number(link.label)))}>
          {link.label}
        </button>
      </li>
    );
  });

  return (
    <div className="col ms-auto">
      <nav aria-label="Page navigation example">
        <ul className="pagination pagination-md mb-2">
          {render()}
        </ul>
      </nav>
    </div>
  );
};

export default Paginator;
