import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import Router from './routes/Router';

const locales: any = {
  ja: () => import('dayjs/locale/ja'),
};

function loadLocale(language: any) {
  locales[language]().then(() => dayjs.locale(language));
}

const App: React.FC = () => {
  useEffect(() => {
    loadLocale('ja');
  }, []);
  return <Router />;
};

export default App;
