import React from 'react';

interface Props {
  children: React.ReactNode;
}

const Table: React.FC<Props> = ({ children }) => (
  <div className="row">
    <div className="col-12">
      <div className="mt-1">
        <table
          className="table align-middle table-striped table-standard table-bordered table-sm mb-2"
          id="tblData"
        >
          {children}
        </table>
      </div>
    </div>
  </div>
);

export default Table;
