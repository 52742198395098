import React, {
  createContext, Dispatch, SetStateAction, useContext, useMemo, useState,
} from 'react';
import items from '../pages/Analysis/MenuItems';

interface AnalysisMenuInterface {
  selectedItem: typeof items[number],
  setSelectedItem: Dispatch<SetStateAction<typeof items[number]>>,
  isShowingSideMenu: boolean,
  setIsShowingSideMenu: Dispatch<SetStateAction<boolean>>,
}

const AnalysisContext = createContext<AnalysisMenuInterface>({} as AnalysisMenuInterface);
AnalysisContext.displayName = 'AnalysisContext';

interface Props {
  children: JSX.Element,
}

export const AnalysisMenuProvider: React.FC<Props> = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState(items[1] as typeof items[number]);
  const [isShowingSideMenu, setIsShowingSideMenu] = useState(true);

  const value = useMemo(
    () => ({
      isShowingSideMenu,
      setIsShowingSideMenu,
      selectedItem,
      setSelectedItem,
    }),
    [selectedItem, isShowingSideMenu],
  );

  return (
    <AnalysisContext.Provider
      value={value}
    >
      {children}
    </AnalysisContext.Provider>
  );
};

export const useAnalysisMenuContext = (): AnalysisMenuInterface => {
  const {
    isShowingSideMenu,
    setIsShowingSideMenu,
    selectedItem,
    setSelectedItem,
  } = useContext(AnalysisContext);

  return {
    isShowingSideMenu,
    setIsShowingSideMenu,
    selectedItem,
    setSelectedItem,
  };
};
