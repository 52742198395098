import React, { useRef, useEffect, useState } from 'react';
import jspreadsheet, { worksheetInstance } from 'jspreadsheet';
import 'jspreadsheet/dist/jspreadsheet.css';
import 'jsuites/dist/jsuites.css';
import numeral from 'numeral';
import config from '../../../config';
import { useJspreadsheetContext } from '../../../contexts/JspreadsheetContext';

export default function FormulasTable() {
  interface JspreadsheetI extends HTMLInputElement {
    jspreadsheet: string;
  }

  const {
    entryData, calcsData, setOutOfRangeCount,
  } = useJspreadsheetContext();
  const [worksheet, setWorksheet] = useState<worksheetInstance>(null);

  const jssRef = useRef<JspreadsheetI>(null);
  const license = config.jspreadsheetLicense || '';

  const style: { [key: string]: string; } = {};

  const [jsData, setJsData] = useState([]);
  useEffect(() => {
    if (calcsData) {
      setOutOfRangeCount(0);
      setJsData(
        Object.keys(calcsData).map((entry : string) => {
          const {
            name, value, unit,
          } = calcsData[entry];
          return [entry.replace('calc', ''), name, value, unit];
        }),
      );
    }
  }, [calcsData]);

  useEffect(() => {
    if (calcsData) {
      Object.keys(calcsData).forEach((entry : string) => {
        const {
          isWithinRange, value,
        } = calcsData[entry];

        if (!isWithinRange && value !== null) {
          setOutOfRangeCount((prev) => prev + 1);
        }
      });
    }
  }, [calcsData]);

  const mountJspreadsheet = () => {
    if (jssRef.current && !jssRef.current.jspreadsheet) {
      const worksheets = jspreadsheet(jssRef.current, {
        contextMenu: () => false,
        updateTable: (instance, cell: any, x, y) => {
          if (x === 2) {
            if (Object.values(calcsData)[y] && !Object.values(calcsData)[y].isWithinRange && Object.values(calcsData)[y].value !== null) {
              cell.style.backgroundColor = '#f7eeee';
            }
          }
        },
        onbeforechange(instance, cell, x, y, value) {
          if (x === 2) {
            if (value === undefined || value === null || value === '') {
              return '';
            }
            return numeral(value.toString()).format('0,0.[0000000000000]');
          }
          return value;
        },
        worksheets: [{
          textOverflow: true,
          freezeColumnControl: false,
          freezeRowControl: false,
          columns: [
            {
              title: 'No', width: 40, align: 'center', readOnly: true,
            },
            {
              title: '項目', width: 210, align: 'left', readOnly: true,
            },
            {
              title: '値', width: 150, align: 'right', readOnly: true,
            },
            {
              title: '単位', width: 50, align: 'center', readOnly: true,
            },
          ],
          data: jsData,
          style: {
            ...style,
          },
        }],
        license,
      });
      worksheets[0].hideIndex();
      setWorksheet(worksheets[0]);
    }
  };

  useEffect(() => {
    if (worksheet && jsData.length) {
      worksheet.setData(jsData);
      worksheet.resetSelection();
    }
  }, [jsData, worksheet]);

  useEffect(() => {
    mountJspreadsheet();
  }, []);

  useEffect(() => {
    if (calcsData) {
      try {
        worksheet.parent.deleteWorksheet(0);
        jssRef.current.jspreadsheet = null;
      } catch (e) {
        console.log('error', e);
      }
      mountJspreadsheet();
    }
  }, [calcsData]);

  useEffect(() => {
    if (!worksheet) {
      mountJspreadsheet();
      return;
    }
    if (entryData) {
      worksheet.setColumnData(0, entryData);
    }
  }, [entryData, worksheet]);

  return (
    <div style={{ display: 'inline-block' }}>
      <div className="col-12 col-md-5">
        <h6 className="card-subtitle mb-2 text-muted">結果</h6>
      </div>
      <div id="formulas-table" ref={jssRef} />
    </div>
  );
}
