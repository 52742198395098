import React, { useEffect, useState} from 'react';

import Table from '../../components/Table/Table';
import Card from '../Master/Card';
import Select from '../../components/Select';
import useGetFarms from "../../hooks/useGetFarms";
import NumberSelect from "./NumberSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import farm from "../../services/farm";
import config from "../../config";
import FarmStatus from "../../utils/farm.utils";
import Paginator from '../../components/Paginator';
import {Spinner} from "react-bootstrap";
import MonthPicker from "../../components/MonthPicker";
import ModalComponent from "../../components/ModalComponent";

function Comment() {
  // queries
  const {farmId, farms, setFarmId} = useGetFarms(true);

  // states
  const [searchFarmsData, setSearchFarmsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [links, setLinks] = useState(null);
  const [total, setTotal] = useState(0);
  const [loadingSearchFarm, setLoadingSearchFarm] = useState(false);
  const [showStatusMissingModal, setShowStatusMissingModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<number>();
  const [selectedYear, setSelectedYear] = useState<number>(2023);
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [selectedFarm, setSelectedFarm] = useState<string>();

  // const
  const statusList = [
    {value: String(config.registrationState.input), label: '未入力'},
    {value: String(config.registrationState.done), label: '完了'},
    {value: String(config.registrationState.committed), label: '確定'},
  ];

  const months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];

// methods
  const SearchFarm = (page: number, form: { month?: number; year: number; registration_state?: string; farm_id: string }) => {
    if (form.month && !form.registration_state) {
      setShowStatusMissingModal(true);
      setLoadingSearchFarm(false)
      return;
    }
    farm.searchFarms(page, form, 20).then((res) => {
        setSearchFarmsData(Object.values(res.data.data));
        setLinks(res.data.links);
        setCurrentPage(res.data.current_page);
        setTotal(res.data.total);
      }
    ).finally(() => setLoadingSearchFarm(false));
  };

  const onSearchFarm = (page?: number) => {
    setLoadingSearchFarm(true)
    if (!page) page = currentPage;
    const form = {
      farm_id: selectedFarm === '全農場' ? null : selectedFarm,
      month: selectedMonth ? selectedMonth : null,
      year: selectedYear,
      registration_state: selectedStatus ? selectedStatus : null,
    };
    SearchFarm(page, form);
  };

  const onChangePage = (page: number) => {
    onSearchFarm(page);
  };

  const onSelectMonth = (month: string) => {
    if (month[0] === '0') month = month[1];
    setSelectedMonth(Number(month));
  };

  // effects
  useEffect(() => {
    setSelectedFarm(farmId);
  }, [farmId]);

  useEffect(() => {
    onSearchFarm();
  }, []);

  return (
    <main className="content p-3">
      <Card showCreateButton={false} title="農場ステータス" subTitle="農場ステータス">
        <div className="row row-cols-auto g-1">
          <div className="col">
            <div className="row row-cols-auto g-1 me-1">
              <label className="col col-form-label">農場：</label>
              <div className="col">
                <Select
                  hasSelectMessage={false}
                  value={farmId}
                  options={farms?.map((farm) => ({value: farm.farm_id, label: farm.farm_id}))}
                  onChange={(farm) => {
                    setSelectedFarm(farm)
                    setFarmId(farm);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col" style={{marginLeft: 20}}>
            <div className="row row-cols-auto g-1 me-1">
              <label
                className="col col-form-label"
              >
                年：
              </label>
              <div className="col">
                <NumberSelect
                  startNumber={2000}
                  endNumber={2030}
                  selectedNumber={selectedYear}
                  setSelectedNumber={(e) => setSelectedYear(e)}
                />
              </div>
            </div>
          </div>
          <div className="col" style={{marginLeft: 20}}>
            <div className="row row-cols-auto g-1 me-1">
              <label
                className="col col-form-label"
              >
                月：
              </label>
              <div className="col">
                <MonthPicker
                  id="fromMonth"
                  key={'datePickerKey'}
                  initialDate={''}
                  onChange={onSelectMonth}
                />
              </div>
            </div>
          </div>
          <div className="col" style={{marginLeft: 20}}>
            <div className="row row-cols-auto g-1 me-1">
              <label
                className="col col-form-label"
              >
                ステータス：
              </label>
              <div className="col">
                <Select
                  value={selectedStatus}
                  options={statusList}
                  onChange={(status) => {
                    setSelectedStatus(status);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col ms-auto">
            <button
              disabled={loadingSearchFarm}
              className="btn btn-primary"
              type="button"
              onClick={() => onSearchFarm(1)}>
              {
                loadingSearchFarm ? (
                  <Spinner size="sm" animation="border"/>
                ) : (
                  <FontAwesomeIcon icon={faMagnifyingGlass}/>
                )
              }
            </button>
          </div>
        </div>
        <Table>
          <thead>
          <tr>
            <th style={{width: 90}}>農場</th>
            {months.map((month, i) => (
              <th style={{width: 80}} key={i}>{month}</th>
            ))}
          </tr>
          </thead>
          <tbody>
          {searchFarmsData.length === 0 && (
            <tr>
              {loadingSearchFarm ? (
                <td colSpan={13} className="text-center"><Spinner animation="border"/></td>
              ) : (
                <td colSpan={13} className="text-center">データはありません。</td>
              )}
            </tr>
          )}
          {searchFarmsData && searchFarmsData.map((farm, index) => (
            <tr key={index}>
              <td>{farm.name}</td>
              {Object.values(farm.months).map((month: any, index: any) => (
                <td key={index}>{FarmStatus.getFarmStatusLabel(month.status)}</td>
              ))}
            </tr>
          ))}
          </tbody>
        </Table>
        <div className="row row-cols-auto g-1 align-items-center">
          <div className="col ">
            <div className="page-item-count text-sm">
              全
              {' '}
              <span>{total}</span>
              {' '}
              件
            </div>
          </div>
          <Paginator currentPage={currentPage} changePage={onChangePage} links={links}/>
        </div>
      </Card>
      <ModalComponent
        title="ステータス確認"
        body="ステータスを選択してください。"
        cancelButtonText="OK"
        show={showStatusMissingModal}
        closeModal={() => setShowStatusMissingModal(false)}
      />
    </main>
  );
}

export default Comment;
