import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { convertArrayToCSV } from 'convert-array-to-csv';
import { Spinner } from 'react-bootstrap';
import csv from '../../../services/csv';
import Card from '../Card';
import Select from '../../../components/Select';
import DatePicker from '../../../components/Form/DatePicker';
import useGetFarms from '../../../hooks/useGetFarms';
import ModalComponent from '../../../components/ModalComponent';

function CsvDownload() {
  const [fromDate, setFromDate] = useState(dayjs().format('YYYY/MM'));
  const [toDate, setToDate] = useState(dayjs().format('YYYY/MM'));
  const [toMonthKey, setToMonthKey] = useState(0);
  const [fromMonthKey] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [downloadingAll, setDownloadingAll] = useState(false);
  const [backendErrorMessage, setBackendErrorMessage] = useState('');
  const [showDownloadAllModal, setShowDownloadAllModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const {
    farmId, farms, setFarmId,
  } = useGetFarms(true);

  const download = async (isAll = false) => {
    setBackendErrorMessage('');
    if (isAll) {
      setDownloadingAll(true);
    } else {
      setDownloading(true);
    }
    const form = isAll ? { all: true } : {
      fromDate: `${fromDate}/01`,
      toDate: `${toDate}/01`,
      farmId: farmId === '全農場' ? null : farmId,
    };
    let csvData = null;
    try {
      csvData = await csv.download(form);
      const data = convertArrayToCSV(csvData?.data || []);
      const blob = new Blob([`\uFEFF${data}`], { type: 'text/csv;charset=utf-8;' });
      const pom = document.createElement('a');
      const csvFileName = `${fromDate}_${toDate}data${dayjs().format('YYYY-MM-DD_HH_mm')}`;
      pom.href = URL.createObjectURL(blob);
      pom.setAttribute('download', `${csvFileName}.csv`);
      document.body.appendChild(pom);
      pom.click();
      pom.parentNode.removeChild(pom);
    } catch (e: any) {
      if (e?.response?.data?.message) {
        setBackendErrorMessage(e.response.data.message);
      }
      setDownloadingAll(false);
      setDownloading(false);
    } finally {
      if (isAll) {
        setDownloadingAll(false);
      } else {
        setDownloading(false);
      }
    }
  };

  useEffect(() => {
    if (fromDate > toDate) {
      setToDate(`${fromDate}`);
    }
    setToMonthKey((prev) => prev + 1);
  }, [fromDate, toDate]);

  return (
    <main className="content p-3">
      <ModalComponent
        title="全CSVダウンロード確認"
        body="全データをダウンロードします。よろしいですか？"
        confirmButtonText="ダウンロードする"
        confirmButtonColor="primary"
        onConfirm={() => download(true)}
        show={showDownloadAllModal}
        closeModal={() => setShowDownloadAllModal(false)}
      />
      <ModalComponent
        title="CSVダウンロード確認"
        body="選択した情報でCSVをダウンロードします。よろしいですか？"
        confirmButtonText="ダウンロードする"
        confirmButtonColor="success"
        onConfirm={() => download()}
        show={showDownloadModal}
        closeModal={() => setShowDownloadModal(false)}
      />
      <Card createButtonText="新規保存" title="CSVダウンロード" noHeader>
        <div className="text-danger mb-3">
          { backendErrorMessage }
        </div>
        <div className="row row-cols-auto g-1 mt-n3 mb-n2">
          <div className="row row-cols-auto g-1" data-dashlane-rid="b5de92df6ab9bc27" data-form-type="other">
            <div className="col">
              <div className="row row-cols-auto g-1 me-1">
                <label className="col col-form-label">農場：</label>
                <div className="col">
                  <Select hasSelectMessage={farms.length === 0} value={farmId} options={farms?.map((farm) => ({ value: farm.farm_id, label: farm.farm_id }))} onChange={(param) => setFarmId(param)} />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="row row-cols-auto g-1 me-2">
                <div className="col"><label className="form-control-plaintext">年月：</label></div>
                <div className="col">
                  <DatePicker
                    key={fromMonthKey}
                    initialDate={dayjs(fromDate).format('YYYY/MM') || dayjs().format('YYYY/MM')}
                    onChange={(e) => {
                      setFromDate(dayjs(e).format('YYYY/MM'));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="row row-cols-auto g-1 me-2">
                <div className="col"><label className="form-control-plaintext">～</label></div>
                <div className="col">
                  <DatePicker
                    key={fromMonthKey + toMonthKey}
                    minDate={dayjs(fromDate).format('YYYY/MM')}
                    id="toMonth"
                    initialDate={dayjs(toDate).format('YYYY/MM') || dayjs().format('YYYY/MM')}
                    onChange={(e) => {
                      setToDate(dayjs(e).format('YYYY/MM'));
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <button
                disabled={downloading}
                onClick={() => setShowDownloadModal(true)}
                className="btn btn-success"
                type="button"
              >
                {
                    downloading
                    && <Spinner size="sm" animation="border" />
                }
                ダウンロード
              </button>
            </div>
          </div>
        </div>
      </Card>
      <button className="btn btn-primary" type="button" onClick={() => setShowDownloadAllModal(true)} disabled={downloadingAll}>
        {
          downloadingAll
              && <Spinner size="sm" animation="border" />
        }
        全データCSVダウンロード
      </button>
    </main>
  );
}

export default CsvDownload;
