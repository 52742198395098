import React from 'react';

interface Props {
  title: string;
  error?: string;
  register?: (name: any) => Record<string, unknown>;
  name?: string;
  type?: string;
  defaultValue?: string;
  disabled?: boolean;
}

const Input: React.FC<Props> = ({
  title, error, register = () => null, name, type = 'text', disabled, defaultValue,
}) => (
  <div className="mb-3 row">
    <label className="col-form-label col-sm-2 text-sm-start">{title}</label>
    <div className="col-sm-10">
      {
        type === 'text-area'
          ? <textarea defaultValue={defaultValue} readOnly={disabled} disabled={disabled} {...register(name)} className={`form-control ${error ? 'is-invalid' : ''}`} autoComplete="new-password" />
          : <input defaultValue={defaultValue} readOnly={disabled} disabled={disabled} type={type} {...register(name)} className={`form-control ${error ? 'is-invalid' : ''}`} autoComplete="new-password" />
        }
      <div className="invalid-feedback">{error}</div>
    </div>
  </div>
);

export default Input;
