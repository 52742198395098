import { AxiosResponse } from 'axios';
import api from '../contexts/GlobalContext';
import { Link } from '../components/Paginator';
import { defaultLimit } from './common';

export interface CommentRequest {
  to_user_id: number;
  message: string;
}

export interface Comment {
  message_id: number;
  from_user_id: number;
  to_user_id: number;
  message: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export type UserResponse = Record<number, {
  farm_id: number;
  pref_code: string;
  veterinarian: {
    veterinarian_id: number;
    user_id: number;
    veterinarian_name: string;
  }
  user_id: number;
  login_id: string;
  name: string;
  type: number;
  pref_name: string;
}>;

interface CommentResponse {
  data: {
    messages: Comment[]
    users:UserResponse
  },
  links: Link[]
  current_page: number
  total: number
}

type CommentDelete = {
  message_id_list: number[]
};

export const get = async (page: number, searchText?: string, limit?: number): Promise<AxiosResponse<CommentResponse>> => api.get<CommentResponse>(`/message/list?page=${page}&search=${searchText || ''}&limit=${limit || defaultLimit}`);

export const post = async (data: CommentRequest): Promise<AxiosResponse<CommentRequest>> => api.post<CommentRequest>('/message/post', data);

export const destroy = async (data: number) => {
  api.post<CommentDelete>('/message/delete', { message_id_list: [data] });
};

export default {
  get,
  post,
  destroy,
};
