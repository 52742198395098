import React from 'react';

interface Props {
  onClick?: () => void;
  createButtonText?: string;
}

const CreateButton: React.FC<Props> = ({ onClick, createButtonText = '新規作成' }) => (
  <div className="col ms-auto">
    <button
      onClick={onClick}
      className="btn btn-primary"
      type="button"
    >
      {createButtonText}
    </button>
  </div>
);

export default CreateButton;
