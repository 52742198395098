import React from 'react';

interface Options {
  value: string;
  label: string;
}

interface Props {
  options: Options[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  hasSelectMessage?: boolean;
}

const Select: React.FC<Props> = ({
  options, value, onChange, disabled, hasSelectMessage = true,
}) => (
  <div>
    <select disabled={disabled} className="form-select w-auto" value={value} onChange={(e) => onChange(e.target.value)}>
      { hasSelectMessage && <option value="">選択してください</option> }
      { options.map((item) => <option value={item.value} key={item.value}>{item.label}</option>) }
    </select>
  </div>
);

export default Select;
