import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { BoxPlotController, BoxAndWiskers } from '@sgratzl/chartjs-chart-boxplot';
import { Line, Chart } from 'react-chartjs-2';
import { Spinner } from 'react-bootstrap';
import {
  LinearScale,
  Chart as ChartJS,
  CategoryScale,
  Tooltip, PointElement, LineElement,
} from 'chart.js';
import {
  getBoxPlotData, getRegionLineData,
} from '../../services/analysis';
import { useAnalysisContext } from '../../contexts/AnalysisContext';
import If from '../../components/If/If';
import formatNumber from '../../utils/formatNumber';
import string from '../../utils/string';
import formatDate from '../../utils/formatDate';

ChartJS.register(
  BoxPlotController,
  BoxAndWiskers,
  // annotationPlugin,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  Tooltip,
);

interface Props {
  calc: string
  breakBefore?: boolean
  onLoading?: (isLoading: boolean) => void
  chartName?: string
  largePageName?: string
  isPrintAll?: boolean
  printYear?: string
  printToYear?: string
  printFromMonth?: string
  printToMonth?: string
}

const RegionChart: React.FC<Props> = ({
  calc, breakBefore, onLoading, chartName, largePageName, isPrintAll, printYear, printToYear, printFromMonth, printToMonth,
}) => {
  // name: 地域別集計

  const {
    searchCount, toMonth, fromMonth, year, toYear, setCurrentDataDate, farm, currentDataYear, currentDataToYear, currentDataFromMonth, currentDataToMonth,
  } = useAnalysisContext();

  const [currentFarmAverage, setCurrentFarmAverage] = useState('0');
  const [pageName, setPageName] = useState('');
  const [dataLoaded, setDataLoaded] = useState(true);
  const [lineDataOk, setLineDataOk] = useState(false);
  const [barDataOK, setBarDataOk] = useState(false);
  const [lineOptions, setLineOptions] = useState({});
  const [plotOptions, setPlotOptions] = useState({});
  const [boxplotData, setBoxplotData] = useState<any>({ datasets: [] });
  const { search } = useLocation();
  const [query] = useState(queryString.parse(search));
  const [lineData] = useState({
    labels: [''],
    datasets: [
      // {
      //   label: '0 不明',
      //   backgroundColor: 'rgba(0,0,0, 0.9)',
      //   borderColor: 'rgba(0,0,0, 0.8)',
      //   pointColor: 'rgba(0,0,0, 1.0)',
      //   borderWidth: 2,
      //   pointRadius: 4,
      //   hitRadius: 5,
      //   pointStyle: 'rectRot',
      //   pointHoverBackgroundColor: 'rgba(0,0,0, 1.0)',
      //   pointHoverBorderColor: 'rgba(0,0,0, 1.0)',
      //   pointHoverBorderWidth: 2,
      //   pointHoverRadius: 5,
      //   data: [0],
      // },
      {
        label: '1 北海道',
        backgroundColor: 'rgba(11, 11, 117, 0.9)',
        borderColor: 'rgba(11, 11, 117, 0.8)',
        pointColor: 'rgba(11, 11, 117, 1.0)',
        borderWidth: 2,
        pointRadius: 4,
        hitRadius: 5,
        pointStyle: 'rectRot',
        pointHoverBackgroundColor: 'rgba(11, 11, 117, 1.0)',
        pointHoverBorderColor: 'rgba(11, 11, 117, 1.0)',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 5,
        data: [0],
      },
      {
        label: '2 東北',
        backgroundColor: 'rgba(234, 21, 234, 0.9)',
        borderColor: 'rgba(234, 21, 234, 0.8)',
        pointColor: 'rgba(234, 21, 234, 1.0)',
        borderWidth: 2,
        pointRadius: 4,
        hitRadius: 5,
        pointStyle: 'rect',
        pointHoverBackgroundColor: 'rgba(234, 21, 234, 1.0)',
        pointHoverBorderColor: 'rgba(234, 21, 234, 1.0)',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 5,
        data: [0],
      },
      {
        label: '3 北関東',
        backgroundColor: 'rgba(234, 234, 21, 0.9)',
        borderColor: 'rgba(234, 234, 21, 0.8)',
        pointColor: 'rgba(234, 234, 21, 1.0)',
        borderWidth: 2,
        pointRadius: 4,
        hitRadius: 5,
        pointStyle: 'triangle',
        pointHoverBackgroundColor: 'rgba(234, 234, 21, 1.0)',
        pointHoverBorderColor: 'rgba(234, 234, 21, 1.0)',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 5,
        data: [0],
      },
      {
        label: '4 南関東',
        backgroundColor: 'rgba(21, 234, 234, 0.9)',
        borderColor: 'rgba(21, 234, 234, 0.8)',
        pointColor: 'rgba(21, 234, 234, 1.0)',
        borderWidth: 2,
        pointRadius: 4,
        hitRadius: 5,
        pointStyle: 'crossRot',
        pointHoverBackgroundColor: 'rgba(21, 234, 234, 1.0)',
        pointHoverBorderColor: 'rgba(21, 234, 234, 1.0)',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 5,
        data: [0],
      },
      {
        label: '5 北陸',
        backgroundColor: 'rgba(204, 153, 255, 0.9)',
        borderColor: 'rgba(204, 153, 255, 0.8)',
        pointColor: 'rgba(204, 153, 255, 1.0)',
        borderWidth: 2,
        pointRadius: 4,
        hitRadius: 5,
        pointStyle: 'rectRounded',
        pointHoverBackgroundColor: 'rgba(204, 153, 255, 1.0)',
        pointHoverBorderColor: 'rgba(204, 153, 255, 1.0)',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 5,
        data: [0],
      },
      {
        label: '6 東海・近畿',
        backgroundColor: 'rgba(21, 234, 21, 0.9)',
        borderColor: 'rgba(21, 234, 21, 0.8)',
        pointColor: 'rgba(21, 234, 21, 1.0)',
        borderWidth: 2,
        pointRadius: 4,
        hitRadius: 5,
        pointStyle: 'circle',
        pointHoverBackgroundColor: 'rgba(21, 234, 21, 1.0)',
        pointHoverBorderColor: 'rgba(21, 234, 21, 1.0)',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 5,
        data: [0],
      },
      {
        label: '7 九州・四国',
        backgroundColor: 'rgba(11, 117, 117, 0.9)',
        borderColor: 'rgba(11, 117, 117, 0.8)',
        pointColor: 'rgba(11, 117, 117, 1.0)',
        borderWidth: 2,
        pointRadius: 4,
        hitRadius: 5,
        pointStyle: 'cross',
        pointHoverBackgroundColor: 'rgba(11, 117, 117, 1.0)',
        pointHoverBorderColor: 'rgba(11, 117, 117, 1.0)',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 5,
        data: [0],
      },
    ],
  });

  useEffect(() => {
    if (barDataOK && lineDataOk) {
      setDataLoaded(true);
      if (onLoading) onLoading(false);
      setLineDataOk(false);
      setBarDataOk(false);
    }
  }, [barDataOK, lineDataOk]);

  let yearLocal;
  let toYearLocal;
  let fromMonthLocal;
  let toMonthLocal;
  let farmLocal: string;

  useEffect(() => () => (onLoading ? onLoading(false) : null), []);
  const [farmName, setFarmName] = useState('');
  const getData = () => {
    if (isPrintAll) {
      yearLocal = query.year as string;
      toYearLocal = query.toYear as string;
      fromMonthLocal = query.fromMonth as string;
      toMonthLocal = query.toMonth as string;
      farmLocal = query.farm as string;
    } else {
      yearLocal = year;
      toYearLocal = toYear;
      fromMonthLocal = fromMonth;
      toMonthLocal = toMonth;
      farmLocal = farm;
    }

    if (!farmLocal) {
      return;
    }
    if (onLoading) onLoading(true);
    setDataLoaded(false);
    setFarmName(farmLocal);
    getBoxPlotData(calc, yearLocal, toYearLocal, fromMonthLocal, toMonthLocal, farmLocal).then(({ data }) => {
      setCurrentDataDate();
      setPageName(data.name);

      setBoxplotData({
        labels: ['1', '2', '3', '4', '5', '6', '7'],
        datasets: [{
          label: 'Data',
          data: [
            // {
            //   min: data?.data?.[0]?.min,
            //   q1: data?.data?.[0]?.bottom25,
            //   median: data?.data?.[0]?.center,
            //   q3: data?.data?.[0]?.top25,
            //   max: data?.data?.[0]?.max,
            //   outliers: data?.data?.[0]?.data,
            // },
            {
              min: data?.data?.[1]?.min,
              q1: data?.data?.[1]?.bottom25,
              median: data?.data?.[1]?.center,
              q3: data?.data?.[1]?.top25,
              max: data?.data?.[1]?.max,
              outliers: data?.data?.[1]?.data,
            }, {
              min: data?.data?.[2]?.min,
              q1: data?.data?.[2]?.bottom25,
              median: data?.data?.[2]?.center,
              q3: data?.data?.[2]?.top25,
              max: data?.data?.[2]?.max,
              outliers: data?.data?.[2]?.data,
            }, {
              min: data?.data?.[3]?.min,
              q1: data?.data?.[3]?.bottom25,
              median: data?.data?.[3]?.center,
              q3: data?.data?.[3]?.top25,
              max: data?.data?.[3]?.max,
              outliers: data?.data?.[3]?.data,
            }, {
              min: data?.data?.[4]?.min,
              q1: data?.data?.[4]?.bottom25,
              median: data?.data?.[4]?.center,
              q3: data?.data?.[4]?.top25,
              max: data?.data?.[4]?.max,
              outliers: data?.data?.[4]?.data,
            }, {
              min: data?.data?.[5]?.min,
              q1: data?.data?.[5]?.bottom25,
              median: data?.data?.[5]?.center,
              q3: data?.data?.[5]?.top25,
              max: data?.data?.[5]?.max,
              outliers: data?.data?.[5]?.data,
            }, {
              min: data?.data?.[6]?.min,
              q1: data?.data?.[6]?.bottom25,
              median: data?.data?.[6]?.center,
              q3: data?.data?.[6]?.top25,
              max: data?.data?.[6]?.max,
              outliers: data?.data?.[6]?.data,
            }, {
              min: data?.data?.[7]?.min,
              q1: data?.data?.[7]?.bottom25,
              median: data?.data?.[7]?.center,
              q3: data?.data?.[7]?.top25,
              max: data?.data?.[7]?.max,
              outliers: data?.data?.[7]?.data,
            },
          ],
          backgroundColor: 'rgba(90,171,218,0.3)',
          borderColor: 'rgba(90,171,218,0.9)',
          borderWidth: 1,
          outlierRadius: 5,
          outlierBackgroundColor: 'rgba(157,157,251,0.3)',
          outlierBorderColor: 'rgba(157,157,251,0.8)',
          outlierBorderWidth: 1,
        }],
      });

      setCurrentFarmAverage(formatNumber(data?.currentFarmValue || null, data.unit === '円' ? '¥' : '', data.unit === '円' ? '' : data.unit));

      setPlotOptions({
        animation: false,
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          position: 'top',
        },
        scales: {
          x: {
            display: true,
            ticks: {
              stepSize: 1,
            },
            title: {
              display: true,
              text: '地域コード',
            },
          },
          y: {
            display: true,
            min: data ? Math.min(...data.axisY) : null,
            max: data ? Math.max(...data.axisY) : null,
            afterBuildTicks: (axis: any) => {
              axis.ticks = data.axisY?.map((item: any) => ({
                value: item || 0,
              }));
            },
            ticks: {
              callback(val: any) {
                return formatNumber(Number(val || 0));
              },
            },
            title: {
              display: true,
              text: string.format(data.unit, '(', ')'),
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem: any) => {
                const oulLierValue = (tooltipItem.formattedValue.hoveredOutlierIndex !== undefined && tooltipItem.formattedValue.hoveredOutlierIndex !== -1) ? tooltipItem.formattedValue.outliers[tooltipItem.formattedValue.hoveredOutlierIndex] : '';
                const value = data.data?.[tooltipItem?.dataIndex];
                const max = `最大値: ${formatNumber(value?.max)}`;
                const min = `最小値: ${formatNumber(value?.min)}`;
                const median = `中央値: ${formatNumber(value?.center)}`;
                // eslint-disable-next-line no-irregular-whitespace
                const q1 = `25%　: ${formatNumber(value?.bottom25)}`;
                // eslint-disable-next-line no-irregular-whitespace
                const q3 = `75%　: ${formatNumber(value?.top25)}`;
                return [oulLierValue, max, q3, median, q1, min];
              },
            },
          },
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: '地域との関係',
          },
          annotation: {
            annotations: {
              userPoint: {
                display: data.currentFarm !== null && data.currentFarm?.value !== null,
                type: 'label',
                backgroundColor: 'rgba(0,0,0,0)',
                color: '#dd0000',
                content: '★',
                font: {
                  size: 24,
                },
                position: {
                  x: 'center',
                  y: 'center',
                },
                yAdjust: undefined,
                xAdjust: undefined,
                xMin: data.currentFarm?.region,
                xMax: data.currentFarm?.region,
                yMax: data.currentFarm?.value,
                yMin: data.currentFarm?.value,
              },
            },
          },
        },
      });

      setCurrentDataDate();
    }).catch((e) => {
      console.error('e', e);
    }).finally(() => {
      setBarDataOk(true);
    });

    getRegionLineData(calc, yearLocal, toYearLocal, fromMonthLocal, toMonthLocal, farmLocal).then(({ data }) => {
      setLineOptions({
        animation: false as const,
        responsive: true as const,
        maintainAspectRatio: false as const,
        legend: {
          display: true,
        },
        scales: {
          x: {
            ticks: {
              maxRotation: 40,
              minRotation: 40,
              font: {
                size: 13,
              },
            },
            title: {
              display: true,
              text: '(年)',
            },
          },
          y: {
            min: data?.axisY?.[0],
            max: data?.axisY?.[(data.axisY?.length || 0) - 1],
            afterBuildTicks: (axis: any) => {
              axis.ticks = data.axisY?.map((item: any) => ({
                value: item ? String(item) : 0,
              }));
            },
            ticks: {
              callback(val: any) {
                return formatNumber(Number(val || 0));
              },
            },
            title: {
              display: true,
              text: string.format(data.unit, '(', ')'),
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'x',
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: 10,
                lineHeight: 1.0,
              },
              boxWidth: 12,
              boxHeight: 12,
              usePointStyle: true,
              pointStyle: 'line',
            },
          },
        },
        layout: {
          padding: {
            top: 0,
            right: 0,
          },
        },
      });
      // lineData.datasets[0].data = Object.values(data.data).map((item) => item.region_0);
      lineData.datasets[0].data = Object.values(data.data).map((item) => item.region_1);
      lineData.datasets[1].data = Object.values(data.data).map((item) => item.region_2);
      lineData.datasets[2].data = Object.values(data.data).map((item) => item.region_3);
      lineData.datasets[3].data = Object.values(data.data).map((item) => item.region_4);
      lineData.datasets[4].data = Object.values(data.data).map((item) => item.region_5);
      lineData.datasets[5].data = Object.values(data.data).map((item) => item.region_6);
      lineData.datasets[6].data = Object.values(data.data).map((item) => item.region_7);
      lineData.labels = data.axisX.map((item) => String(item));
    }).catch(() => {
      lineData.datasets[0].data = [0];
      lineData.datasets[1].data = [0];
      lineData.datasets[2].data = [0];
      lineData.datasets[3].data = [0];
      lineData.datasets[4].data = [0];
      lineData.datasets[5].data = [0];
      // lineData.datasets[6].data = [0];
      lineData.labels = [''];
    }).finally(() => {
      setLineDataOk(true);
    });
  };

  useEffect(() => {
    if (isPrintAll) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (searchCount > 0) {
      getData();
    }
  }, [searchCount]);

  return (
    <>
      <If test={!dataLoaded}>
        <div className="text-center p-5">
          <Spinner animation="border" />
        </div>
      </If>
      <section className="" id="reportRankingChart" style={{ display: !dataLoaded ? 'none' : 'block' }}>
        <div className={`${breakBefore ? 'page-break-before' : ''}`} />
        <div className="m-2 row">
          <div className="flex-fill text-sm-start col-4">
            農場ID:
            {farmName}
            <label className="text-muted fs-sm" style={{ fontSize: '0.8rem' }}>
              （期間:
              <span>
                {printYear ?? currentDataYear}
                /
                { formatDate(printFromMonth ?? currentDataFromMonth, 'MM')}
                ～
                {printToYear ?? currentDataToYear}
                /
                {formatDate(printToMonth ?? currentDataToMonth, 'MM')}
                )
              </span>
            </label>
          </div>
          <div className="flex-fill text-sm-center fw-bold col-4">
            地域別集計
            {' '}
            {pageName}
          </div>
          <div className="flex-fill text-sm-end text-sm col-4">
            {largePageName}
          </div>
        </div>
        <div className="border-bottom my-1" />
        <div className="text-end">
          <span className="border-bottom border-2 border-danger py-1 px-2">
            <span className="text-sm me-4">
              {chartName}
            </span>
            <span className="fw-bold">
              {currentFarmAverage}
            </span>
          </span>
        </div>
        <div className="p-2 mt-2">
          <div className="row">
            <div className="col col-6 align-self-center ">
              <div className=" chart chartReportLowBox">
                {
                    dataLoaded && (
                    <Line style={{ maxHeight: '250px', height: '20px !important' }} id="chartLine" redraw data={lineData} options={lineOptions} />
                    )
                }
              </div>
            </div>
            <div className="col col-6 align-self-center ">
              {
                dataLoaded && (
                  <div className="chart chartReportLowBox">
                    <Chart type="boxplot" data={boxplotData} options={plotOptions} />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default RegionChart;
