/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: 'the field ${label} is required.',
  },
  string: {
    email: 'the field ${label} must be a valid email.',
  },
  number: {},
  boolean: {},
});

export default yup;
