import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouse,
  faPenToSquare,
  faChartColumn,
  faDoorOpen,
  faDatabase,
  faBars,
  faAngleUp,
  faCommentAlt,
  faFilePdf,
  faFileAlt,
  faSignal, faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  NavLink, useLocation, useNavigate,
} from 'react-router-dom';
import Cookies from 'js-cookie';
import config from '../config';
import ModalComponent from '../components/ModalComponent';
import { useGlobalContext } from '../contexts/GlobalContext';
import { useUserContext } from '../contexts/UserContext';

type Props = {
  children?: React.ReactNode
};

const PrivateLayout: React.FC<Props> = ({ children }) => {
  const { jspreadsheetDataHasChanged } = useGlobalContext();
  const [showConfirmExitModal, setShowConfirmExitModal] = useState(false);
  const [targetPath, setTargetPath] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = (path: string) => (location.pathname === path ? 'active' : '');

  const { user } = useUserContext();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, path: string) => {
    setTargetPath(path);
    e.preventDefault();
    if (jspreadsheetDataHasChanged) {
      setShowConfirmExitModal(true);
    } else {
      navigate(path);
    }
  };

  const logout = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    Cookies.remove(config.auth.cookieName);
    window.location.href = '/login';
  };
  const { userCan } = useUserContext();

  return (

    <div data-theme="jasv">
      <div className="wrapper">
        <nav id="sidebar" className="sidebar js-sidebar d-print-none">
          <div className="sidebar-content js-simplebar">
            <NavLink className="sidebar-brand text-center px-2" to="/" replace>
              <span className="align-middle fs-6 fw-bold">ベンチマーキングWEBシステム</span>
            </NavLink>
            <ul className="sidebar-nav">
              <li className="sidebar-header">
                メニュー
              </li>
              {
                  userCan('home:view') && (
                  <li className={`sidebar-item  ${isActive('/')}`}>
                    <a href="#" onClick={(e) => handleClick(e, '/')} className="sidebar-link">
                      <FontAwesomeIcon icon={faHouse} />
                      <span className="align-middle">ホーム</span>
                    </a>
                  </li>
                  )
              }
              {
                userCan('input:view') && (
                <li className={`sidebar-item ${isActive('/data-entry')}`}>
                  <NavLink className="sidebar-link" to="/data-entry" replace>
                    <FontAwesomeIcon icon={faPenToSquare} />
                    <span className="align-middle">データ入力</span>
                  </NavLink>
                </li>
                )
              }
              {
                  userCan('analysis:view') && (
                  <li className={`sidebar-item ${isActive('/analysis')}`}>
                    <a href="#" onClick={(e) => handleClick(e, '/analysis')} className="sidebar-link">
                      <FontAwesomeIcon icon={faChartColumn} />
                      <span className="align-middle">データ解析</span>
                    </a>
                  </li>
                  )
              }
              <li className={`sidebar-item ${isActive('/comment')}`}>
                <a href="#" onClick={(e) => handleClick(e, '/comment')} className="sidebar-link">
                  <FontAwesomeIcon icon={faCommentAlt} />
                  <span className="align-middle">コメント</span>
                </a>
              </li>
              <li className={`sidebar-item ${isActive('/notice')}`}>
                <a href="#" onClick={(e) => handleClick(e, '/notice')} className="sidebar-link">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <span className="align-middle">お知らせ</span>
                </a>
              </li>
              {
                  user.type === config.userType.farm && (
                  <li className={`sidebar-item ${isActive('/manual')}`}>
                    <a className="sidebar-link" href="/assets/file/manual/farm/JASV%E3%83%99%E3%83%B3%E3%83%81%E3%83%9E%E3%83%BC%E3%82%AD%E3%83%B3%E3%82%B0WEB%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E5%88%A9%E7%94%A8%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB.pdf?var=1.0.2" target="_blank">
                      <FontAwesomeIcon icon={faFilePdf} />
                      <span className="align-middle">マニュアル</span>
                    </a>
                  </li>
                  )
              }
              {
                  user.type === config.userType.veterinarian && (
                  <li className={`sidebar-item ${isActive('/manual')}`}>
                    <a className="sidebar-link" href="/assets/file/manual/veterinarian/JASV%E3%83%99%E3%83%B3%E3%83%81%E3%83%9E%E3%83%BC%E3%82%AD%E3%83%B3%E3%82%B0WEB%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E5%88%A9%E7%94%A8%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB.pdf?var=1.0.2" target="_blank">
                      <FontAwesomeIcon icon={faFilePdf} />
                      <span className="align-middle">マニュアル</span>
                    </a>
                  </li>
                  )
              }
              {userCan('user_management:view') &&
              <li className={`sidebar-item ${isActive('/farm_status')}`}>
                <a href="#" onClick={(e) => handleClick(e, '/farm_status')} className="sidebar-link">
                  <FontAwesomeIcon icon={faSignal} />
                  <span className="align-middle">農場ステータス</span>
                </a>
              </li>
              }

              {
                userCan('user_management:view')
                  && (
                  <>
                    <li className="sidebar-header">
                      マスタ管理
                    </li>
                    <li className={`sidebar-item ${isActive('/farm_master')}`}>
                      <a className="sidebar-link" href="#" onClick={(e) => handleClick(e, '/farm_master')}>
                        <FontAwesomeIcon icon={faDatabase} />
                        <span className="align-middle">農場</span>
                      </a>
                    </li>
                    <li className={`sidebar-item ${isActive('/veterinarian_master')}`}>
                      <a className="sidebar-link" href="#" onClick={(e) => handleClick(e, '/veterinarian_master')}>
                        <FontAwesomeIcon icon={faDatabase} />
                        <span className="align-middle">獣医師</span>
                      </a>
                    </li>
                    <li className={`sidebar-item ${isActive('/clerk_master')}`}>
                      <a className="sidebar-link" href="#" onClick={(e) => handleClick(e, '/clerk_master')}>
                        <FontAwesomeIcon icon={faDatabase} />
                        <span className="align-middle">事務局</span>
                      </a>
                    </li>
                  </>
                  )
              }
                  <li className="sidebar-header">
                    管理機能
                  </li>
                  <li className={`sidebar-item ${isActive('/report')}`}>
                    <a className="sidebar-link" href="#" onClick={(e) => handleClick(e, '/report')}>
                      <FontAwesomeIcon icon={faFilePdf} />
                      <span className="align-middle">レポート管理</span>
                    </a>
                  </li>
                  {
                    user.type === config.userType.clerk && (
                      <li className={`sidebar-item ${isActive('/csv_download')}`}>
                        <a className="sidebar-link" href="#" onClick={(e) => handleClick(e, '/csv_download')}>
                          <FontAwesomeIcon icon={faFileAlt} />
                          <span className="align-middle">CSVダウンロード</span>
                        </a>
                      </li>
                    )
                  }
              <li className="sidebar-header py-0">
                <hr />
              </li>
              <li className="sidebar-item">
                <a onClick={logout} className="sidebar-link" href="#">
                  <FontAwesomeIcon icon={faDoorOpen} />
                  <span className="align-middle">ログアウト</span>
                </a>
              </li>

              <li className="sidebar-header py-0">
                <hr />
              </li>
              <li className="sidebar-item sidebar-contact-tmp">
              【問い合わせ】<br/>
              （一社）日本養豚開業獣医師協会<br/>
              　事務局<br/>
              TEL：029-875-9090<br/>
              メール：pig.jasv@r7.dion.ne.jp<br/>
              </li>

            </ul>
          </div>
        </nav>

        <div className="main">
          <nav className="navbar navbar-expand navbar-light navbar-bg py-1 d-print-none">
            <a className="sidebar-toggle js-sidebar-toggle">
              <FontAwesomeIcon icon={faBars} />
            </a>

            <div className="navbar-collapse collapse">
              <ul className="navbar-nav navbar-align">
                <li className="nav-item dropdown">
                  <a
                    className="nav-icon dropdown-toggle d-inline-block d-sm-none"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    <span className="align-middle" data-feather="settings">
                      <i className="fa-solid fa-user" />
                    </span>
                  </a>
                  <a
                    className="nav-link text-lg dropdown-toggle d-none d-sm-inline-block"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    <span>{user.type === config.userType.farm ? user.farm_id : user.login_id}</span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={logout}
                    >
                      <i
                        className="fa-solid fa-door-open fa-fw"
                      />
                      {' '}
                      ログアウト
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>

          <footer className="footer d-none">
            <div className="container-fluid">
              <div className="row text-muted">
                <div className="col-6 text-start">
                  &copy; 2022 JASV
                </div>
                <div className="col-6 text-end">
                  <div className="d-none d-sm-block">Version 1.0.0</div>
                </div>
              </div>
            </div>
          </footer>
          <a className="scroll-to-top rounded"><FontAwesomeIcon icon={faAngleUp} /></a>
          {children}
        </div>
        <ModalComponent
          title="警告"
          body={(
            <div>
              <div>入力内容が保存されていません。入力内容が破棄されます。</div>
              <div>よろしいですか？</div>
            </div>
          )}
          confirmButtonText="はい"
          confirmButtonColor="success"
          onConfirm={() => navigate(targetPath)}
          show={showConfirmExitModal}
          closeModal={() => setShowConfirmExitModal(false)}
        />
      </div>
    </div>
  );
};

export default PrivateLayout;
