import { verifyObject } from '../utils';

const config = {
  env: process.env.REACT_APP_STAGE || 'production',
  encryption: {
    secret: process.env.REACT_APP_ENCRYPTION_SECRET as string,
  },
  api: {
    url: process.env.REACT_APP_API_URL ?? '',
  },
  jspreadsheetLicense: process.env.REACT_APP_JSPREADSHEET_LICENSE ?? '',
  auth: {
    cookieName: 'ksPw4FSNZW',
  },
  userType: {
    clerk: 1,
    veterinarian: 2,
    farm: 3,
  },
  registrationState: {
    input: 0,
    done: 1,
    committed: 2,
  },
};

// Check if config object is valid and not missing a key
const validate = verifyObject(config);

if (!validate) {
  throw new Error('No valid environment found');
}
if (validate.length !== 0) {
  throw new Error(`Environment variable missing: ${validate}`);
}

export default config;
