import React from 'react';
import CreateButton from '../../components/Buttons/Create';

interface Props {
  children: React.ReactNode;
  onClickCreate?: () => void;
  title?: string;
  subTitle?: string;
  showCreateButton?: boolean;
  createButtonText?: string;
  noHeader?: boolean;
}

const Card: React.FC<Props> = ({
  children, onClickCreate, title, subTitle, showCreateButton = true, createButtonText = '新規作成', noHeader = false,
}) => (
  <div className="container-fluid p-0">

    <div className="mb-1 d-flex justify-content-between">
      <h4 className="h4 d-inline align-middle">{title}</h4>
    </div>

    <div className="row">
      <div className="col-12">

        <div className="card">
          {
            !noHeader
              && (
              <div className="card-header">
                <div className="row row-cols-auto g-1">
                  <div className="col">
                    {subTitle}
                  </div>
                  {
                    showCreateButton && <CreateButton createButtonText={createButtonText} onClick={() => onClickCreate()} />
                  }
                </div>
              </div>
              )
          }

          <div className="card-body">
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Card;
