import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useAnalysisContext } from '../../contexts/AnalysisContext';
import { reportTransitionData, ReportTransitionI } from '../../services/analysis';
import formatNumber from '../../utils/formatNumber';
import If from '../../components/If/If';
import useFetch from './analisysHook';
import formatDate from '../../utils/formatDate';

interface Props {
  pageNumber: number,
  onLoading?: (isLoading: boolean) => void
  largePageName?: string
  isPrintAll?: boolean
  printYear?: string
  printToYear?: string
  printFromMonth?: string
  printToMonth?: string
}

const ReportTransition: React.FC<Props> = ({
  pageNumber, onLoading, largePageName, isPrintAll, printYear, printToYear, printFromMonth, printToMonth,
}) => {
  // name:成績の推移【No.】

  const {
    textSize, currentDataYear, currentDataToYear, currentDataFromMonth, currentDataToMonth,
  } = useAnalysisContext();
  const { data, gettingData, farmName } = useFetch<ReportTransitionI>(reportTransitionData, isPrintAll, onLoading);

  const calcs = {
    1: [5, 8, 19, 20, 21, 6, 26, 16, 17, 18, 12],
    2: [59, 57, 56, 54, 55, 71, 72],
    3: [52, 51, 47, 73, 43, 45, 46],
    4: [53, 44, 67, 61, 50, 48, 76],
    5: [74, 60, 75, 66, 63, 69, 65],
    6: [62, 64, 68, 58, 70],
  };

  const monthSentence = () => {
    const fromMonth = printFromMonth ?? currentDataFromMonth;
    const toMonth = printToMonth ?? currentDataToMonth;

    if (fromMonth === toMonth) {
      return `各年、${fromMonth}月分の集計となります。`;
    }
    return `各年、
            ${printFromMonth ?? currentDataFromMonth}
          月～
            ${printToMonth ?? currentDataToMonth}
    月の集計となります。`;
  };

  const shouldRender = (data: ReportTransitionI) => data && data.data;

  return (
    <>
      <If test={gettingData}>
        <div className="text-center p-5">
          <Spinner animation="border" />
        </div>
      </If>
      <section id="reportTransition1" style={{ display: gettingData ? 'none' : 'block' }}>
        <div className="page-break-before" />

        <div className="mt-2 row">
          <div className="flex-fill text-sm-start col-4">
            農場ID:
            {farmName}
            <label className="text-muted fs-sm" style={{ fontSize: '0.8rem' }}>
              （期間:
              <span>
                {printYear ?? currentDataYear}
                /
                { formatDate(printFromMonth ?? currentDataFromMonth, 'MM')}
                ～
                {printToYear ?? currentDataToYear}
                /
                {formatDate(printToMonth ?? currentDataToMonth, 'MM')}
                )
              </span>
            </label>
          </div>
          <div className="flex-fill text-sm-center fw-bold col-4">
            成績の推移【No.
            {pageNumber}
            】
          </div>
          <div className="flex-fill text-sm-end text-sm col-4">
            {largePageName}
          </div>
        </div>
        <div className="border-bottom my-1" />
        <div className="table-responsive">

          <div style={{ fontSize: '0.8rem', color: '#FF0000' }}>
            {monthSentence()}
          </div>
          <table
            className={`table align-middle table-striped table-standard table-bordered table-tbody-nowrap table-sm text-sm-print text-${textSize}`}
            id="tblTransition1"
          >
            <thead>
              <tr>
                <th>年</th>

                {
                data && data.labels
                  && calcs[pageNumber as keyof typeof calcs].map((calc: number) => <th key={calc}>{data.labels[`calc${calc}`].name}</th>)

              }
              </tr>
            </thead>
            <tbody>
              {
              !data
              && (
              <tr>
                <td colSpan={11} className="text-center">データはありません。</td>
              </tr>
              )
          }
              {
                shouldRender(data) && Object.entries(data.data).map((value) => (
                  <tr key={value[0]}>
                    <td>{value[0]}</td>
                    {
                        data && data.labels
                        && calcs[pageNumber as keyof typeof calcs].map((calc: number) => <td style={{ textAlign: 'right' }} key={calc}>{formatNumber(value[1][`calc${calc}`], data.labels[`calc${calc}`].unit === '円' ? '¥' : '', data.labels[`calc${calc}`].unit === '円' ? '' : data.labels[`calc${calc}`].unit)}</td>)
                    }
                  </tr>
                ))
          }
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default ReportTransition;
