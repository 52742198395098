import yup from '../yup';

export const loginSchema = yup.object().shape({
  id: yup.string()
    .nullable()
    .required('ログインIDを入力してください')
    .label('Login ID'),
  password: yup.string()
    .nullable()
    .required('パスワードを入力してください')
    .label('Password'),
  remember: yup.boolean()
    .nullable()
    .label('保持フラグ'),
});
