import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons/faPenToSquare';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';

interface Props {
  onClickEdit: () => void;
  onClickDelete: () => void;
}

const ActionButton: React.FC<Props> = ({ onClickDelete, onClickEdit }) => (
  <div className="btn-group btn-group-sm">
    <button
      type="button"
      className="btn btn-outline-secondary dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      操作を選択
    </button>
    <div className="dropdown-menu">
      <button
        type="button"
        onClick={() => onClickEdit()}
        className="dropdown-item"
      >
        <FontAwesomeIcon icon={faPenToSquare} />
        {' '}
        編集
      </button>
      <button
        type="button"
        onClick={() => onClickDelete()}
        className="dropdown-item"
      >
        <FontAwesomeIcon icon={faTrash} />
        {' '}
        削除
      </button>
    </div>
  </div>
);

export default ActionButton;
