import React, {
  useEffect, useRef, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ModalComponent from '../../../components/ModalComponent';
import Paginator from '../../../components/Paginator';
import report, { Report as ReportInterface, ReportPost } from '../../../services/report';
import submit from '../../../utils/submit';
import Input from '../../../components/Form/Input';
import Search from '../../../components/Search/Search';
import Table from '../../../components/Table/Table';
import Card from '../Card';
import { reportSchema } from '../../../validation/schemas/reportSchema';
import formatDate from '../../../utils/formatDate';
import ActionButtonDownload from '../../../components/Table/ActionButtonDownload';
import { useDownloadFile } from '../../../hooks/file.hook';
import {useUserContext} from "../../../contexts/UserContext";

function Report() {
  const formRef = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reports, setReports] = useState<ReportInterface[]>([] as ReportInterface[]);
  const [selectedReport, setSelectedReport] = useState<ReportInterface>(null);
  const [links, setLinks] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [total, setTotal] = useState(0);
  const [deleting, setDeleting] = useState(false);
  const [creating, setCreating] = useState(false);
  const [backendErrorMessage, setBackendErrorMessage] = useState('');

  const {
    register, handleSubmit, formState: { errors }, reset,
  } = useForm<ReportPost>({
    mode: 'onChange',
    resolver: yupResolver(reportSchema),
    criteriaMode: 'all',
  });

  const { userCan } = useUserContext();

  const getReports = (page?: number) => {
    if (!page) page = currentPage;
    report.get(page, searchText).then((res) => {
      if (res?.data?.data?.length === 0 && currentPage > 1) {
        getReports(currentPage - 1);
      } else {
        setReports(res.data.data);
        setLinks(res.data.links);
        setCurrentPage(res.data.current_page);
        setTotal(res.data.total);
      }
    });
  };

  useEffect(() => {
    getReports();
  }, [searchText]);

  const resetForm = () => {
    reset({
      files: null,
      comment: '',
    });
    setBackendErrorMessage('');
  };

  const onDelete = () => {
    setDeleting(true);
    report.destroy(selectedReport.id).then(() => {
      getReports();
    }).finally(() => {
      setShowDeleteModal(false);
      setDeleting(false);
      resetForm();
    });
  };

  const changePage = (page: number) => {
    getReports(page);
  };

  const postOk = () => {
    getReports();
    resetForm();
    setShowCreateModal(false);
    setCreating(false);
  };

  const postNg = (err: any) => {
    setBackendErrorMessage(err?.response?.data?.message || '');
    setCreating(false);
  };

  const downloadFile = useDownloadFile();
  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append('file', data.files[0]);
    formData.append('comment', data.comment);
    setCreating(true);
    report.post(formData).then(postOk).catch(postNg);
  });

  const download = async (file: any) => {
    const reportFile = await report.download(file);
    downloadFile(reportFile);
  };

  return (
    <main className="content p-3">
      <ModalComponent
        loading={creating}
        errorMessage={backendErrorMessage}
        closeOnConfirm={false}
        size="lg"
        title="レポート"
        confirmButtonText="OK"
        onConfirm={() => submit(formRef)}
        show={showCreateModal}
        closeModal={() => { reset({ comment: '', files: null }); setSelectedReport(null); setShowCreateModal(false); }}
      >
        <div className=" m-3">
          <form
            onSubmit={onSubmit}
            ref={formRef}
          >
            <Input type="file" title="ファイル" name="files" error={errors?.files?.message} register={register} />
            <Input type="text-area" title="コメント" name="comment" error={errors?.comment?.message} register={register} />
          </form>
        </div>
      </ModalComponent>

      <ModalComponent
        loading={deleting}
        closeOnConfirm={false}
        body={`${selectedReport?.file_name || ''}を削除してもよろしいですか？`}
        title="削除確認"
        confirmButtonText="削除"
        confirmButtonColor="danger"
        onConfirm={onDelete}
        show={showDeleteModal}
        closeModal={() => { setShowDeleteModal(false); setSelectedReport(null); }}
      />

      <Card showCreateButton={userCan('report_management:create')} createButtonText="新規保存" title="レポート管理" subTitle="レポート一覧" onClickCreate={() => setShowCreateModal(true)}>
        <div className="row row-cols-auto g-1">
          <Search onSearch={(text) => setSearchText(text)} />
          <Paginator currentPage={currentPage} changePage={changePage} links={links} />
        </div>
        <Table>
          <thead>
            <tr>
              <th style={{ whiteSpace: 'nowrap' }}>ファイル名</th>
              <th style={{ whiteSpace: 'nowrap' }}>コメント</th>
              <th style={{ whiteSpace: 'nowrap' }}>保存ユーザー</th>
              <th style={{ whiteSpace: 'nowrap' }}>保存日</th>
              <th style={{ width: '9rem' }}>操作</th>
            </tr>
          </thead>
          <tbody>
            {reports?.length === 0 && (
              <tr>
                <td colSpan={10} className="text-center">データはありません。</td>
              </tr>
            )}
            {
                reports && reports.map((report) => (
                  <tr key={report.id}>
                    <td>{report.file_name}</td>
                    <td style={{ fontSize: '0.9rem' }}>{report.comment}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>{report.uploader_name}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>{formatDate(report.created_at)}</td>
                    <td className="table-action text-center" style={{ whiteSpace: 'nowrap' }}>
                      <ActionButtonDownload
                        showDeleteButton={userCan('report_management:delete')}
                        onClickDelete={() => {
                          setSelectedReport({ ...report }); setShowDeleteModal(true);
                        }}
                        onClickDownload={() => download(report)}
                      />
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </Table>
        <div className="row row-cols-auto g-1 align-items-center">
          <div className="col ">
            <div className="page-item-count text-sm">
              全
              {' '}
              <span>{total}</span>
              {' '}
              件
            </div>
          </div>
          <Paginator currentPage={currentPage} changePage={changePage} links={links} />
        </div>
      </Card>
    </main>
  );
}

export default Report;
