import { AxiosResponse } from 'axios';
import api from '../contexts/GlobalContext';

interface FarmMonthlyData {
  farm_id : string
  registration_year : string
  registration_month : string
  registration_state : string
  create_date : string
  update_date : string
  variety_male : string
  variety_female : string
  mother_pig : string
  units_shipped : string
  self_produced_candidates : string
  shipping_carcass_weight : string
  total_carcass_sales_amount : string
  place_expenses : string
  fare : string
  net_income_of_total_carcass_sales : string
  total_feed_purchase_amount_overall : string
  total_feed_purchase_amount_sire_pig : string
  total_feed_purchase_price_overall : string
  fund_reserve_fund : string
  fund_compensation_money : string
  total_feed_purchase_price_sire_pig : string
  vaccine_antibacterial_agent_costs : string
  pigs_in_stock_after_weaning : string
  suckling_pigs_in_stock : string
  management_form : string
  accidents_after_weaning : string
  copulation : string
  confinement : string
  total_offspring : string
  surviving_offspring : string
  wean_piglets : string
  pig_farm_workers : string
  delivery_crates : string
  fattening_area : string
  presence_or_absence_of_prrs : string
  piglets_shipped : string
  net_income_of_piglet_sales : string
  day_old_of_shipping_piglets : string
  avg_wean_piglets : string
  avg_confinement : string
  avg_copulation : string
  avg_surviving_offspring : string
  avg_mother_pig : string
}

const download = async (data: any): Promise<AxiosResponse<FarmMonthlyData[]>> => api.post<FarmMonthlyData[]>('/csv/download', data);

export default {
  download,
};
