import React, {
  useRef, useEffect, useState,
} from 'react';
import jspreadsheet from 'jspreadsheet';
import 'jspreadsheet/dist/jspreadsheet.css';
import 'jsuites/dist/jsuites.css';
import dayjs from 'dayjs';
import numeral from 'numeral';
import config from '../../../config';
import { useJspreadsheetContext } from '../../../contexts/JspreadsheetContext';
import { DataEntry, Farm } from '../../../services';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { useUserContext } from '../../../contexts/UserContext';

interface Props {
  onChange: () => void,
}

const DataTable = ({ onChange }: Props) => {
  interface JspreadsheetI extends HTMLInputElement {
    jspreadsheet: string;
  }

  const { user } = useUserContext();

  const { setJspreadsheetDataHasChanged } = useGlobalContext();

  const {
    backendData, farmName, setEntryData, setGettingData, date, calcsData, sendingData,
  } = useJspreadsheetContext();
  const [worksheet, setWorksheet] = useState(null);

  const jssRef = useRef<JspreadsheetI>(null);
  const license = config.jspreadsheetLicense || '';

  const style: { [key: string]: string; } = {};

  for (let i = 1; i <= 36; i++) {
    style[`E${i}`] = `background-color: #eef7ef;${(![1, 2, 3, 4, 21, 31].includes(i)) ? 'text-align: right;' : ''}`;
  }
  const [isChanged, setIsChanged] = useState(false);

  const canEdit = () => {
    if (!backendData) {
      return false;
    }
    if (backendData.currentMonth.registration_state === null) {
      return true;
    }
    if (!backendData.currentMonth) {
      return false;
    }
    if (![undefined, 0, 1].includes(backendData.currentMonth.registration_state)) {
      return false;
    }
    if (backendData.currentMonth.registration_state === 2) {
      return false;
    }

    if (backendData.currentMonth.registration_state === 1 && (user.type === config.userType.veterinarian || user.type === config.userType.clerk)) {
      return true;
    }

    if (backendData.currentMonth.registration_state === undefined) {
      return true;
    }

    return backendData.currentMonth.registration_state === 0;
  };

  const formulasMap = [
    {
      analysis_no: '43', threshold_min: 380, threshold_max: 660, alert_input_no: ['8', '9d'],
    },
    {
      analysis_no: '44', threshold_min: 25, threshold_max: 70, alert_input_no: ['10a', '11a', '11b', '11c'],
    },
    {
      analysis_no: '45', threshold_min: 14, threshold_max: 32, alert_input_no: ['5', '6', '7'],
    },
    {
      analysis_no: '46', threshold_min: 66, threshold_max: 84, alert_input_no: ['6', '8'],
    },
    {
      analysis_no: '47', threshold_min: 840, threshold_max: 2730, alert_input_no: ['5', '6', '7', '8'],
    },
    {
      analysis_no: '48', threshold_min: 4, threshold_max: 7.2, alert_input_no: ['6', '7', '8', '10a'],
    },
    {
      analysis_no: '49', threshold_min: 100, threshold_max: 300, alert_input_no: ['6', '7', '13'],
    },
    {
      analysis_no: '50', threshold_min: 120, threshold_max: 255, alert_input_no: ['6', '7', '13', '14'],
    },
    {
      analysis_no: '52', threshold_min: 21, threshold_max: 84, alert_input_no: ['6', '7', '9d', '11a', '11b', '11c'],
    },
    {
      analysis_no: '53', threshold_min: 1, threshold_max: 19, alert_input_no: ['16', '21'],
    },
    {
      analysis_no: '54', threshold_min: 27000, threshold_max: 54000, alert_input_no: ['6', '9d'],
    },
    {
      analysis_no: '55', threshold_min: 10000, threshold_max: 28000, alert_input_no: ['6', '7', '11a', '11b', '11c'],
    },
    {
      analysis_no: '56', threshold_min: 30000, threshold_max: 300000, alert_input_no: ['6', '7', '9d', '11a', '11b', '11c'],
    },
    {
      analysis_no: '57', threshold_min: 450000, threshold_max: 1260000, alert_input_no: ['5', '6', '7', '9d'],
    },
    {
      analysis_no: '58', threshold_min: 200000, threshold_max: 650000, alert_input_no: ['5', '11a', '11b', '11c'],
    },
    {
      analysis_no: '59', threshold_min: 80000, threshold_max: 800000, alert_input_no: ['5', '6', '7', '9d', '11a', '11b', '11c'],
    },
    {
      analysis_no: '60', threshold_min: 12.5, threshold_max: 35, alert_input_no: ['5', '21'],
    },
    {
      analysis_no: '61', threshold_min: 360, threshold_max: 900, alert_input_no: ['6', '7', '8', '13', '14'],
    },
    {
      analysis_no: '62', threshold_min: 55, threshold_max: 100, alert_input_no: ['17', '18'],
    },
    {
      analysis_no: '63', threshold_min: 1.5, threshold_max: 2.85, alert_input_no: ['5', '18'],
    },
    {
      analysis_no: '64', threshold_min: 8, threshold_max: 17, alert_input_no: ['18', '19'],
    },
    {
      analysis_no: '65', threshold_min: 7, threshold_max: 16, alert_input_no: ['18', '20'],
    },
    {
      analysis_no: '66', threshold_min: 6, threshold_max: 15, alert_input_no: ['18', '21'],
    },
    {
      analysis_no: '67', threshold_min: 130, threshold_max: 355, alert_input_no: ['6', '7', '8', '11a', '11b', '11c'],
    },
    {
      analysis_no: '68', threshold_min: 0.4, threshold_max: 4, alert_input_no: ['18', '19', '20'],
    },
    {
      analysis_no: '69', threshold_min: 1, threshold_max: 23.5, alert_input_no: ['18', '20', '21'],
    },
    {
      analysis_no: '51', threshold_min: 500, threshold_max: 5000, alert_input_no: ['6', '7', '12'],
    },
    {
      analysis_no: '70', threshold_min: 10000, threshold_max: 100000, alert_input_no: ['5', '12'],
    },
    {
      analysis_no: '71', threshold_min: 1000000, threshold_max: 72000000, alert_input_no: ['5', '6', '7', '9d', '11a', '11b', '11c', '22'],
    },
    {
      analysis_no: '72', threshold_min: 9000, threshold_max: 90000, alert_input_no: ['5', '6', '7', '9d', '11a', '11b', '11c', '24'],
    },
    {
      analysis_no: '73', threshold_min: 60, threshold_max: 330, alert_input_no: ['6', '7', '8', '24'],
    },
    {
      analysis_no: '74', threshold_min: 0.3, threshold_max: 3, alert_input_no: ['13', '24'],
    },
    {
      analysis_no: '75', threshold_min: 20, threshold_max: 200, alert_input_no: ['21', '23'],
    },
    {
      analysis_no: '76', threshold_min: 3, threshold_max: 6.8, alert_input_no: ['6', '7', '8', '10a', '10b'],
    },
  ];

  const cellsMap: { [key: string]: { cellNumber: string, index: number } } = {
    2: { cellNumber: 'A1', index: 0 },
    3: { cellNumber: 'A2', index: 1 },
    4: { cellNumber: 'A3', index: 2 },
    5: { cellNumber: 'A4', index: 3 },
    6: { cellNumber: 'A5', index: 4 },
    7: { cellNumber: 'A6', index: 5 },
    8: { cellNumber: 'A7', index: 6 },
    '9a': { cellNumber: 'A8', index: 7 },
    '9b': { cellNumber: 'A9', index: 8 },
    '9c': { cellNumber: 'A10', index: 9 },
    '9d': { cellNumber: 'A11', index: 10 },
    '10a': { cellNumber: 'A12', index: 11 },
    '10b': { cellNumber: 'A13', index: 12 },
    '11a': { cellNumber: 'A14', index: 13 },
    '11b': { cellNumber: 'A15', index: 14 },
    '11c': { cellNumber: 'A16', index: 15 },
    '11d': { cellNumber: 'A17', index: 16 },
    12: { cellNumber: 'A18', index: 17 },
    13: { cellNumber: 'A19', index: 18 },
    14: { cellNumber: 'A20', index: 19 },
    15: { cellNumber: 'A21', index: 20 },
    16: { cellNumber: 'A22', index: 21 },
    17: { cellNumber: 'A23', index: 22 },
    18: { cellNumber: 'A24', index: 23 },
    19: { cellNumber: 'A25', index: 24 },
    20: { cellNumber: 'A26', index: 25 },
    21: { cellNumber: 'A27', index: 26 },
    22: { cellNumber: 'A28', index: 27 },
    23: { cellNumber: 'A29', index: 28 },
    24: { cellNumber: 'A30', index: 29 },
    25: { cellNumber: 'A31', index: 30 },
    26: { cellNumber: 'A32', index: 31 },
    27: { cellNumber: 'A33', index: 32 },
    28: { cellNumber: 'A34', index: 33 },
    29: { cellNumber: 'A35', index: 34 },
  };

  useEffect(() => {
    if (canEdit() && isChanged) {
      onChange();
    }
    setIsChanged(false);
  }, [isChanged]);

  useEffect(() => {
    if (sendingData) {
      document.querySelectorAll('#data-table [data-x="4"]').forEach((cell, index) => {
        if (index !== 2) {
          cell.classList.add('readonly');
          cell.classList.add('text-muted');
        }
      }, []);
    } else {
      document.querySelectorAll('#data-table [data-x="4"]').forEach((cell, index) => {
        if (index !== 2) {
          cell.classList.remove('readonly');
          cell.classList.remove('text-muted');
        }
      }, []);
    }
  }, [sendingData]);

  const jsData = [
    ['2', '品種  おす', '', '', '', 'ー'],
    ['3', '品種  めす', '', '', '', 'ー'],
    ['4', '都道府県', '', '', ''],
    ['5', '常時母豚数', '', '', '', '頭'],
    ['6', '出荷頭数', '', '', '', '頭'],
    ['7', '自家産の候補繰入頭数', '', '', '', '頭'],
    ['8', '出荷枝肉重量', '', '', '', 'kg'],
    ['9a', 'a 総枝肉販売金額', '', '', '', '円'],
    ['9b', 'b （内　と場経費）', '', '', '', '円'],
    ['9c', 'c （内　運賃）', '', '', '', '円'],
    ['9d', 'd 総枝肉販売手取り金額', '', '', '', '円'],
    ['10a', '総飼料購入量 (全体)', '', '', '', 'kg'],
    ['10b', '総飼料購入量 (種豚)', '', '', '', 'kg'],
    ['11a', '総飼料購入金額 (全体)', '', '', '', '円'],
    ['11b', '飼料安定基金積立金', '', '', '', '円'],
    ['11c', '飼料安定基金補填金', '', '', '', '円'],
    ['11d', '総飼料購入金額 (種豚)', '', '', '', '円'],
    ['12', 'ワクチン・抗菌剤費', '', '', '', '円'],
    ['13', '離乳後の肉豚在庫頭数', '', '', '', '頭'],
    ['14', '哺乳豚在庫頭数', '', '', '', '頭'],
    ['15', '経営形態', '', '', '', 'ー'],
    ['16', '離乳後事故頭数', '', '', '', '頭'],
    ['17', '種付頭数', '', '', '', '頭'],
    ['18', '分娩腹数', '', '', '', '頭'],
    ['19', '総産子数', '', '', '', '頭'],
    ['20', '生存産子数', '', '', '', '頭'],
    ['21', '離乳子豚頭数', '', '', '', '頭'],
    ['22', '養豚場従事者数', '', '', '', '人'],
    ['23', '分娩クレート数', '', '', '', '個'],
    ['24', '肥育面積', '', '', '', '㎡'],
    ['25', 'PRRSの有無', '', '', '', 'ー'],
    ['26', '子豚出荷頭数', '', '', '', '頭'],
    ['27', '子豚販売手取り金額', '', '', '', '円'],
    ['28', '子豚出荷日齢', '', '', '', '日'],
    ['29', '平均子豚出荷体重', '', '', '', 'kg'],
  ];

  const setData = (obj: DataEntry, key: number, farm: Farm) => {
    jsData[0][key] = obj?.variety_male;
    jsData[1][key] = obj?.variety_female;
    jsData[2][key] = farm?.pref_name;
    jsData[3][key] = obj?.mother_pig;
    jsData[4][key] = obj?.units_shipped;
    jsData[5][key] = obj?.self_produced_candidates;
    jsData[6][key] = obj?.shipping_carcass_weight;
    jsData[7][key] = obj?.total_carcass_sales_amount;
    jsData[8][key] = obj?.place_expenses;
    jsData[9][key] = obj?.fare;
    jsData[10][key] = obj?.net_income_of_total_carcass_sales;
    jsData[11][key] = obj?.total_feed_purchase_amount_overall;
    jsData[12][key] = obj?.total_feed_purchase_amount_sire_pig;
    jsData[13][key] = obj?.total_feed_purchase_price_overall;
    jsData[14][key] = obj?.fund_reserve_fund;
    jsData[15][key] = obj?.fund_compensation_money;
    jsData[16][key] = obj?.total_feed_purchase_price_sire_pig;
    jsData[17][key] = obj?.vaccine_antibacterial_agent_costs;
    jsData[18][key] = obj?.pigs_in_stock_after_weaning;
    jsData[19][key] = obj?.suckling_pigs_in_stock;
    jsData[20][key] = obj?.management_form;
    jsData[21][key] = obj?.accidents_after_weaning;
    jsData[22][key] = obj?.copulation;
    jsData[23][key] = obj?.confinement;
    jsData[24][key] = obj?.total_offspring;
    jsData[25][key] = obj?.surviving_offspring;
    jsData[26][key] = obj?.wean_piglets;
    jsData[27][key] = obj?.pig_farm_workers;
    jsData[28][key] = obj?.delivery_crates;
    jsData[29][key] = obj?.fattening_area;
    jsData[30][key] = obj?.presence_or_absence_of_prrs;
    jsData[31][key] = obj?.piglets_shipped;
    jsData[32][key] = obj?.net_income_of_piglet_sales;
    jsData[33][key] = obj?.day_old_of_shipping_piglets;
    jsData[34][key] = obj?.average_shipping_weight_of_piglets;
  };

  const mountJspreadsheet = () => {
    if (jssRef.current && !jssRef.current.jspreadsheet) {
      const worksheets = jspreadsheet(jssRef.current, {
        contextMenu: () => false,
        editorFormulas: false,
        parseFormulas: false,
        worksheets: [{
          allowInsertRow: false,
          allowInsertColumn: false,
          textOverflow: true,
          freezeColumnControl: false,
          freezeRowControl: false,
          columns: [
            {
              title: 'No', width: 45, align: 'center', readOnly: true,
            },
            {
              title: '項目', width: 200, align: 'left', readOnly: true,
            },
            {
              title: '前々月', width: 120, align: 'right', readOnly: true,
            },
            {
              title: '前月', width: 120, align: 'right', readOnly: true,
            },
            {
              title: '値', width: 150, align: 'right', type: 'text',
            },
            {
              title: '単位', width: 50, align: 'center', readOnly: true,
            },
          ],
          data: jsData,
          style: {
            ...style,
          },
          cells: {
            C1: { mask: '', readOnly: true },
            C2: { mask: '', readOnly: true },
            C3: { mask: '', readOnly: true },
            C21: { mask: '', readOnly: true },
            D1: { mask: '', readOnly: true },
            D2: { mask: '', readOnly: true },
            D3: { mask: '', readOnly: true },
            D21: { mask: '', readOnly: true },
            E1: { mask: '', type: 'text' },
            E2: { mask: '', type: 'text' },
            E31: {
              type: 'dropdown', source: [{ name: '陽性', id: '陽性' }, { name: '陰性', id: '陰性' }], align: 'right',
            },
            D31: {
              type: 'dropdown', source: [{ name: '陽性', id: '陽性' }, { name: '陰性', id: '陰性' }], align: 'right',
            },
            C31: {
              type: 'dropdown', source: [{ name: '陽性', id: '陽性' }, { name: '陰性', id: '陰性' }], align: 'right',
            },
            E3: { mask: '', readOnly: true },
            E21: { mask: '', type: 'text' },
          },
        }],
        onbeforechange(instance, cell, x, y, value) {
          if (value === null || value === undefined) {
            return '';
          }

          if ((Number(x) !== 4 && Number(x) !== 3 && Number(x) !== 2) || Number(y) === 30 || Number(y) === 0 || Number(y) === 1 || Number(y) === 2 || Number(y) === 20) {
            return value;
          }

          value = String(value).replaceAll(',', '');

          if (!/^[0-9 ().]+$/.test(value)) {
            return '';
          }

          // let integerValue = (String(value).split('.')[0]);
          // if (Number(integerValue) > 12 && String(value).includes('.')) {
          //   const decimalValue = String(value).split('.')[1];
          //   integerValue = integerValue.length > 12 ? integerValue.slice(-12) : integerValue;
          //   value = `${integerValue}.${decimalValue}`;
          // } else if (value.length > 12) {
          //   value = value.slice(-12);
          // }
          return numeral(value.toString()).format('0,0.[0000000000000]');
        },
        onchange: (worksheet: any, cell, x, y, newValue, oldValue) => {
          if (newValue !== oldValue) {
            setJspreadsheetDataHasChanged(true);
          }
          setEntryData(worksheet.getColumnData(4));
          if (!worksheet.pageChanging) {
            setIsChanged(true);
          }
        },
        license,
      });
      worksheets[0].hideIndex();
      setWorksheet(worksheets[0]);
    }
  };

  useEffect(() => {
    if (calcsData) {
      const elements = document.querySelectorAll('#data-table [data-x="4"]:not(.jss_header)');
      elements.forEach((cell: any) => {
        cell.style.backgroundColor = '#eef7ef';
      }, []);
      const indexes: number[] = [];

      Object.keys(calcsData).forEach((calc : string) => {
        const {
          isWithinRange, value,
        } = calcsData[calc];

        if (!isWithinRange && value !== null) {
          const calcNumber = calc.replace('calc', '');
          const formulaErrorMap = formulasMap.find((formula) => formula.analysis_no === calcNumber);
          try {
            formulaErrorMap.alert_input_no.forEach((analysisNo) => {
              if (indexes.indexOf(cellsMap[analysisNo].index) === -1) {
                indexes.push(cellsMap[analysisNo].index);
              }
            });
          } catch (e) {
            console.log('e', e);
          }
        }

        setTimeout(() => {
          indexes.forEach((index) => {
            const elements = Array.from<HTMLElement>(document.querySelectorAll(`#data-table [data-x="4"][data-y="${index}"]`));
            elements.forEach((cell) => {
              cell.style.backgroundColor = '#f7eeee';
            }, []);
          });
        });
      }, 100);
    }
  }, [calcsData]);

  useEffect(() => {
    mountJspreadsheet();
    return () => setGettingData(true);
  }, []);

  useEffect(() => {
    if (!worksheet) {
      mountJspreadsheet();
      return;
    }
    if (backendData) {
      worksheet.pageChanging = true;
      setData(backendData.currentMonth, 4, backendData.farm);

      if (backendData.lastMonth) {
        setData(backendData.lastMonth, 3, backendData.farm);
      }

      if (backendData.twoMonthsAgo) {
        setData(backendData.twoMonthsAgo, 2, backendData.farm);
      }

      worksheet.setData(jsData);

      if (canEdit()) {
        worksheet.getCell('E1').readOnly = false;
        document.querySelectorAll('#data-table [data-x="4"].readonly').forEach((cell, index) => {
          if (index !== 2) {
            cell.classList.remove('readonly');
          }
        }, []);
      } else {
        document.querySelectorAll('#data-table [data-x="4"]').forEach((cell, index) => {
          if (index !== 2) {
            cell.classList.add('readonly');
          }
        }, []);
      }
      worksheet.pageChanging = false;
    }
    setJspreadsheetDataHasChanged(false);
  }, [backendData, worksheet]);

  return (
    <div style={{ display: 'inline-block', verticalAlign: 'top', marginBottom: '10px' }}>
      <h6 className="card-subtitle mb-2 text-muted">
        入力
        <span
          className="text-sm"
        >
          （
          {`${(backendData && backendData.currentMonth.registration_year) || dayjs(date).format('YYYY')}/${((backendData && backendData.currentMonth.registration_month) || dayjs(date).format('MM'))}${farmName ? `/${farmName}` : ''}` }
          ）
        </span>
      </h6>
      <div id="data-table" ref={jssRef} />
    </div>
  );
};
export default DataTable;
