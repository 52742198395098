import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import PrivateLayout from '../layout/PrivateLayout';
import { useUserContext } from '../contexts/UserContext';

function PrivateOutlet(): React.ReactElement {
  const location = useLocation();
  const { isAuthenticated } = useUserContext();
  return isAuthenticated
    ? <PrivateLayout><Outlet /></PrivateLayout>
    : <Navigate to={`/login?redirect=${location.pathname}`} />;
}

export default PrivateOutlet;
