import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';

function PrivateOutletSimple(): React.ReactElement {
  const location = useLocation();
  const { isAuthenticated } = useUserContext();
  return isAuthenticated
    ? <Outlet />
    : <Navigate to={`/login?redirect=${location.pathname}`} />;
}

export default PrivateOutletSimple;
