import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import { LoginRequest } from '../../services/authentication.types';
import { loginSchema } from '../../validation';
import { useUserContext } from '../../contexts/UserContext';

function Login() {
  const { loginUser, errorMessage } = useUserContext();

  const { register, handleSubmit, formState: { errors } } = useForm<LoginRequest>({
    mode: 'onSubmit',
    resolver: yupResolver(loginSchema),
    criteriaMode: 'all',
    defaultValues: {
      id: '',
      password: '',
      remember: true,
    },
  });

  const onSubmitHandler = handleSubmit(async (data) => {
    loginUser(data);
  });

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <main className="d-flex w-100 login-background">
        <div className="container d-flex flex-column">
          <div className="vh-100">
            <div className="login-box mx-auto d-table h-100">
              <div className="d-table-cell align-middle">

                <div className="text-center my-2">
                  <img
                    src={require('../../assets/img/logo2.png')}
                    alt="ベンチマーキング<br>WEBシステム"
                    className="img-fluid rounded"
                    width="64"
                    height="64"
                  />
                  <div>
                    <img
                      src={require('../../assets/img/logo3.png')}
                      alt="ベンチマーキング<br>WEBシステム"
                      className="img-fluid rounded"
                      // width="64"
                      // height="64"
                    />
                  </div>
                  {/* <h1 */}
                  {/*  className="h1" */}
                  {/*  style={{ fontSize: '1.5rem' }} */}
                  {/* > */}
                  {/*  ベンチマーキング */}
                  {/*  <div>WEBシステム</div> */}
                  {/* </h1> */}
                </div>

                <div className="card mb-0" style={{ fontSize: '0.8rem' }}>
                  <div className="card-body">
                    <div className="mx-sm-3">
                      <div className="text-center">
                        <p className="login-box-msg text-sm">ユーザーIDとパスワードを入力ください</p>
                      </div>
                      <div className="text-danger">
                        {errorMessage}
                      </div>
                      <Form onSubmit={onSubmitHandler}>
                        <div className="mb-3">
                          <label className="form-label">Login ID</label>
                          <div className="input-group input-group-icon">
                            <input
                              style={{ fontSize: '0.8rem' }}
                              className="form-control form-control-lg"
                              type="text"
                              name="id"
                              placeholder="ログインIDを入力してください"
                              {...register('id')}
                            />
                            <span className="input-group-text-icon">
                              <FontAwesomeIcon icon={faUser} />
                            </span>
                          </div>
                          {
                            errors.id && <span className="error invalid-feedback d-block">{errors.id.message}</span>
                          }
                        </div>

                        <div className="mb-3">
                          <label className="form-label">Password</label>
                          <div className="input-group input-group-icon">
                            <input
                              style={{ fontSize: '0.8rem' }}
                              {...register('password')}
                              className="form-control form-control-lg"
                              type="password"
                              name="password"
                              placeholder="パスワードを入力してください"
                            />
                            <span className="input-group-text-icon">
                              <FontAwesomeIcon icon={faLock} />
                            </span>
                          </div>
                          {
                            errors.password && <span className="error invalid-feedback d-block">{errors.password.message}</span>
                          }
                        </div>

                        <div>
                          <Form.Group className="mb-3">
                            <Form.Check {...register('remember')} id="remember" style={{ fontSize: '0.7rem' }} type="checkbox" label="ログイン状態を保存する" />
                          </Form.Group>
                        </div>
                        <div className="text-center mt-0">
                          <button
                            style={{ fontSize: '0.8rem' }}
                            type="submit"
                            className="btn btn-primary btn-block btn-lg"
                          >
                            ログイン
                          </button>
                        </div>
                      </Form>

                    </div>
                  </div>
                </div>
                <div className="version mt-1 mx-1">version 1.0.0</div>

              </div>
            </div>
          </div>
        </div>
      </main>
    </>

  );
}

export default Login;
