import React from 'react';
import NotFound from '../pages/NotFound';
import { useUserContext } from '../contexts/UserContext';

type Props = {
  children?: React.ReactNode
  feature?: string
  userType?: number[]
};

const PrivateRoute: React.FC<Props> = ({ children, feature, userType = [] }) => {
  const { userCan, user } = useUserContext();

  const userTypeCan = () => {
    if (userType.length === 0) {
      return false;
    }
    return userType.includes(user.type);
  };

  if (!children) return null;
  if (!feature) return <>{children}</>;

  return <>{(userCan(feature) || userTypeCan()) ? children : <NotFound />}</>;
};

export default PrivateRoute;
