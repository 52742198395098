import React, { useEffect, useState } from 'react';
import items from './MenuItems';
import { useAnalysisMenuContext } from '../../contexts/AnalysisMenuContext';
import { useAnalysisContext } from '../../contexts/AnalysisContext';
import { useUserContext } from '../../contexts/UserContext';

function SideMenu() {
  const { selectedItem, setSelectedItem } = useAnalysisMenuContext();
  const {
    setIsPrintAll, fromMonth, year, toYear, toMonth, farm, currentDataFarm,
  } = useAnalysisContext();
  const { userCan } = useUserContext();

  const { setCheckedItems } = useAnalysisContext();
  const [checkedItemsLocal, setCheckedItemsLocal] = useState<string[]>([]);
  const [checkedDividers, setCheckedDividers] = useState<string[]>([]);
  const [menuItemsNames] = React.useState<string[]>(items.map((item) => item.name));

  const checkDividerSelection = (dividerName: string, newCheck: string[]) => {
    const divider = items.find(item => item.name === dividerName);
    const dividerItems = divider.dividerItems;
    const isChecked = dividerItems.every((itemName: any) => newCheck.includes(itemName));

    setCheckedDividers((prev) => {
      if (isChecked) {
        return [...prev, dividerName];
      }
      return prev.filter((item) => item !== dividerName);
    });
  };

  const handleOnChange = (checked: boolean, itemName: string) => {
    setCheckedItemsLocal((prev) => {
      if (checked) {
        return [...prev, itemName];
      }
      return prev.filter((item) => item !== itemName);
    });
  };

  const handleOnChangeDivider = (checked: boolean,dividerName: string, dividerItems: string[] = []) => {
    setCheckedDividers((prev) => {
        if (checked) {
            return [...prev, dividerName];
        }
        return prev.filter((item) => item !== dividerName);
    });

    setCheckedItemsLocal((prev) => {
      if (checked) {
        return [...prev, ...dividerItems];
      }
      return prev.filter((item) => !dividerItems.includes(item));
    });
  };

  const printAll = () => {
    window.open(`${window.location.protocol}//${window.location.host}/print_all?year=${year}&toYear=${toYear}&fromMonth=${fromMonth}&toMonth=${toMonth}&farm=${farm}&items=${checkedItemsLocal}`, '_blank');
  };

  const handleDividerSelection = (newChecks: string[]) => {
    const dividers = items.filter(item => item.name.includes('divider'));
   dividers.forEach((divider) => {
     checkDividerSelection(divider.name, newChecks);
   });
  }

  useEffect(() => {
    const checkedItems = menuItemsNames.filter((menuItem) => checkedItemsLocal.includes(menuItem));
    setCheckedItems(checkedItems);
    handleDividerSelection(checkedItems);
  }, [checkedItemsLocal]);

  const handleCheckAll = (checked: boolean) => {
    if (checked) {
      setCheckedItemsLocal(items.filter((item) => !item.name.includes('divider')).map((item) => item.name));
      setCheckedDividers(items.filter((item) => item.name.includes('divider')).map((item) => item.name))
    } else {
      setCheckedItemsLocal([]);
      setCheckedDividers([])
    }
  };

  return (
    <div className="col-12 col-md-3 collapse d-print-none collapse-horizontal show" id="areaMenu">
      <div className="card">
        <div className="card-header p-2">
          <div className="row row-cols-auto">
            <div className="col">
              <h5 className="card-title">解析項目</h5>
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive table-analysisMenu" id="tblAnalysisMenu">
            <table
              className="table table-standard table-hover table-scroll table-sm text-sm table-select mb-0"
              id="tblMenu"
            >
              <thead>
                <tr className="cursor-pointer">
                  <th><input id="check-all" onChange={(e) => handleCheckAll(e.target.checked)} type="checkbox" name="all" /></th>
                  <th>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}
                    >
                      <label htmlFor="check-all">項目</label>
                      {
                        userCan('analysis:print:report') && (
                        <button disabled={checkedItemsLocal.length === 0 || !currentDataFarm} type="button" className="btn btn-primary" id="btnPrint" style={{ float: 'right' }} onClick={printAll}>レポート印刷</button>
                        )
                      }
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* TODO: when to use below line? */}
                <tr style={{ display: 'none' }}>
                  <td colSpan={7} className="text-center">選択項目はありません。</td>
                </tr>
                {
                  items.map((item) => (
                    <tr
                      style={{ background: item.bgStyle, cursor: item.name.includes('divider') ? 'default' : 'pointer' }}
                      className={`cursor-pointer ${selectedItem.name === item.name ? 'selected-item' : ''} `}
                      key={item.name}
                      onClick={() => {
                        if (!item.name.includes('divider')) {
                          setSelectedItem(item);
                          setIsPrintAll(false);
                        }
                      }}
                    >
                      {
                        !item.name.includes('divider')
                      && <td><input onClick={(e) => e.stopPropagation()} onChange={(e) => handleOnChange(e.target.checked, item.name)} checked={checkedItemsLocal.includes(item.name)} type="checkbox" /></td>
                      }
                      {
                        item.name.includes('divider')
                        && <td><input onClick={(e) => e.stopPropagation()} onChange={(e) => handleOnChangeDivider(e.target.checked, item.name, item.dividerItems)} checked={checkedDividers.includes(item.name)} type="checkbox" /></td>
                      }
                      <td colSpan={7}>{item.text}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
