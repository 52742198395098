import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import config from '../config';
import farm from '../services/farm';
import { useUserContext } from '../contexts/UserContext';

export interface MonthsColorInterface {
  farms: any[]
  farmId: string
  setFarmId: Dispatch<SetStateAction<string>>,
  gettingFarms: boolean
}

export default function useGetFarms(allFarms = false): MonthsColorInterface {
  const { user } = useUserContext();
  const [farmId, setFarmId] = useState('');
  const [farms, setFarms] = useState([]);
  const [gettingFarms, setGettingFarms] = useState(true);

  useEffect(() => {
    farm.get(1, '', 999999).then((res) => {
      let farms = res.data.data;
      if (user.type === config.userType.veterinarian) {
        farms = farms.filter((farm) => farm.veterinarian.veterinarian_id === user.login_id);
      }

      const farmCopy: any = [...farms];

      if (allFarms) {
        farmCopy.unshift({ farm_id: '全農場' });
      }
      setFarms(farmCopy);
      if (farms?.length > 0) {
        setFarmId(farmCopy[0].farm_id);
      }
    }).finally(() => {
      setGettingFarms(false);
    });
  }, []);
  return {
    setFarmId,
    farms,
    farmId,
    gettingFarms,
  };
}
