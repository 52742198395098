import React from "react";

interface numberSelectProps {
  startNumber: number;
  endNumber: number;
  firstOptionLabel?: string;
  selectedNumber: number;
  setSelectedNumber: (value: number) => void;
}

export default function NumberSelect(
  {
    startNumber,
    endNumber,
    firstOptionLabel,
    selectedNumber,
    setSelectedNumber
  }: numberSelectProps) {

  const start = startNumber;
  const end = endNumber;
  const numbers = Array.from({length: end - start + 1}, (_, index) => (start + index).toString());
  const numbersOptions = numbers.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));

  return (
    <select className="form-control" value={selectedNumber} onChange={(e) => setSelectedNumber(Number(e.target.value))}>
      {firstOptionLabel && <option value="">{firstOptionLabel}</option>}
      {numbersOptions}
    </select>
  )
}
