import React from 'react';
import ReportProductivityTree from './reportProductivityTree';
import ReportCardRanking from './ReportCardRanking';
import ReportRankingChart from './ReportRankingChart';
import ReportRankingRadarChart from './ReportRankingRadarChart';
import ReportTransition from './ReportTransition';
import ReportAllCard from './reportAllCard';
import ReportCard from './ReportCard';
import RegionChart from './RegionChart';
import ScaleChart from './ScaleChart';

const reportTransitionItems = [
  { position: '5', name: '成績の推移【No.1】', pageNumber: 1 },
  { position: '6', name: '成績の推移【No.2】', pageNumber: 2 },
  { position: '7', name: '成績の推移【No.3】', pageNumber: 3 },
  { position: '8', name: '成績の推移【No.4】', pageNumber: 4 },
  { position: '9', name: '成績の推移【No.5】', pageNumber: 5 },
  { position: '10', name: '成績の推移【No.6】', pageNumber: 6 },
];

const reportRankingChartItems = [
  { position: '11', name: '粗利益 (/母豚/年)', calc: '59' },
  { position: '12', name: '販売額 (/母豚/年)', calc: '57' },
  { position: '13', name: '粗利益 (/肉豚)', calc: '56' },
  { position: '14', name: '販売額 (/肉豚)', calc: '54' },
  { position: '15', name: '飼料費 (/肉豚)', calc: '55' },
  { position: '16', name: '粗利益 (/従業員/年)', calc: '71' },
  { position: '17', name: '粗利益 (/㎡/年)', calc: '72' },
  { position: '18', name: '売上飼料比率', calc: '52' },
  { position: '19', name: 'ワクチン・抗菌剤費 (/肉豚)', calc: '51' },
  { position: '20', name: '出荷枝肉重量 (/母豚/年) (kg)', calc: '47' },
  { position: '21', name: '出荷枝肉重量 (/㎡/年) (kg)', calc: '73' },
  { position: '22', name: '枝肉価格 (/kg)', calc: '43' },
  { position: '23', name: '出荷頭数 (/母豚/年)', calc: '45' },
  { position: '24', name: '枝肉重量 (/頭)  (kg)', calc: '46' },
  { position: '25', name: '離乳後事故率', calc: '53' },
  { position: '26', name: '飼料価格 (/kg)', calc: '44' },
  { position: '27', name: '飼料費 (/枝肉)', calc: '67' },
  { position: '28', name: '増体重 (g/日)', calc: '61' },
  { position: '29', name: '出荷日齢', calc: '50' },
  { position: '30', name: '農場枝肉FCR', calc: '48' },
  { position: '31', name: '肉豚枝肉FCR', calc: '76' },
  { position: '32', name: '肥育密度 (㎡/頭)', calc: '74' },
  { position: '33', name: '離乳子豚数 (/母豚/年)', calc: '60' },
  { position: '34', name: '離乳子豚数 (/クレート/年)', calc: '75' },
  { position: '35', name: '離乳子豚数 (/腹)', calc: '66' },
  { position: '36', name: '分娩回転率 (/年)', calc: '63' },
  { position: '37', name: '哺乳中死亡率', calc: '69' },
  { position: '38', name: '生存産子数 (/腹)', calc: '65' },
  { position: '39', name: '分娩率', calc: '62' },
  { position: '40', name: '総産子数 (/腹)', calc: '64' },
  { position: '41', name: '死産数 (/腹)', calc: '68' },
  { position: '42', name: '飼料費 (/母豚/年)', calc: '58' },
  { position: '43', name: 'ワクチン・抗菌剤費 (/母豚/年)', calc: '70' },
];

const regionItems = [
  { position: '44', name: '粗利益 (/母豚/年)', calc: '59' },
  { position: '45', name: '販売額 (/母豚/年)', calc: '57' },
  { position: '46', name: '粗利益 (/肉豚)', calc: '56' },
  { position: '47', name: '販売額 (/肉豚)', calc: '54' },
  { position: '48', name: '飼料費 (/肉豚)', calc: '55' },
  { position: '49', name: '粗利益 (/従業員/年)', calc: '71' },
  { position: '50', name: '粗利益 (/㎡/年)', calc: '72' },
  { position: '51', name: '売上飼料比率', calc: '52' },
  { position: '52', name: 'ワクチン・抗菌剤費 (/肉豚)', calc: '51' },
  { position: '53', name: '出荷枝肉重量 (/母豚/年) (kg)', calc: '47' },
  { position: '54', name: '出荷枝肉重量 (/㎡/年) (kg)', calc: '73' },
  { position: '55', name: '枝肉価格 (/kg)', calc: '43' },
  { position: '56', name: '出荷頭数 (/母豚/年)', calc: '45' },
  { position: '57', name: '枝肉重量 (/頭)  (kg)', calc: '46' },
  { position: '58', name: '離乳後事故率', calc: '53' },
  { position: '59', name: '飼料価格 (/kg)', calc: '44' },
  { position: '60', name: '飼料費 (/枝肉)', calc: '67' },
  { position: '61', name: '増体重 (g/日)', calc: '61' },
  { position: '62', name: '出荷日齢', calc: '50' },
  { position: '63', name: '農場枝肉FCR', calc: '48' },
  { position: '64', name: '肉豚枝肉FCR', calc: '76' },
  { position: '65', name: '肥育密度 (㎡/頭)', calc: '74' },
  { position: '66', name: '離乳子豚数 (/母豚/年)', calc: '60' },
  { position: '67', name: '離乳子豚数 (/クレート/年)', calc: '75' },
  { position: '68', name: '離乳子豚数 (/腹)', calc: '66' },
  { position: '69', name: '分娩回転率 (/年)', calc: '63' },
  { position: '70', name: '哺乳中死亡率', calc: '69' },
  { position: '71', name: '生存産子数 (/腹)', calc: '65' },
  { position: '72', name: '分娩率', calc: '62' },
  { position: '73', name: '総産子数 (/腹)', calc: '64' },
  { position: '74', name: '死産数 (/腹)', calc: '68' },
  { position: '75', name: '飼料費 (/母豚/年)', calc: '58' },
  { position: '76', name: 'ワクチン・抗菌剤費 (/母豚/年)', calc: '70' },
];

const scaleChartItems = [
  { position: '77', name: '粗利益 (/母豚/年)', calc: '59' },
  { position: '78', name: '販売額 (/母豚/年)', calc: '57' },
  { position: '79', name: '粗利益 (/肉豚)', calc: '56' },
  { position: '80', name: '販売額 (/肉豚)', calc: '54' },
  { position: '81', name: '飼料費 (/肉豚)', calc: '55' },
  { position: '82', name: '粗利益 (/従業員/年)', calc: '71' },
  { position: '83', name: '粗利益 (/㎡/年)', calc: '72' },
  { position: '84', name: '売上飼料比率', calc: '52' },
  { position: '85', name: 'ワクチン・抗菌剤費 (/肉豚)', calc: '51' },
  { position: '86', name: '出荷枝肉重量 (/母豚/年) (kg)', calc: '47' },
  { position: '87', name: '出荷枝肉重量 (/㎡/年) (kg)', calc: '73' },
  { position: '88', name: '枝肉価格 (/kg)', calc: '43' },
  { position: '89', name: '出荷頭数 (/母豚/年)', calc: '45' },
  { position: '90', name: '枝肉重量 (/頭)  (kg)', calc: '46' },
  { position: '91', name: '離乳後事故率', calc: '53' },
  { position: '92', name: '飼料価格 (/kg)', calc: '44' },
  { position: '93', name: '飼料費 (/枝肉)', calc: '67' },
  { position: '94', name: '増体重 (g/日)', calc: '61' },
  { position: '95', name: '出荷日齢', calc: '50' },
  { position: '96', name: '農場枝肉FCR', calc: '48' },
  { position: '97', name: '肉豚枝肉FCR', calc: '76' },
  { position: '98', name: '肥育密度 (㎡/頭)', calc: '74' },
  { position: '99', name: '離乳子豚数 (/母豚/年)', calc: '60' },
  { position: '100', name: '離乳子豚数 (/クレート/年)', calc: '75' },
  { position: '101', name: '離乳子豚数 (/腹)', calc: '66' },
  { position: '102', name: '分娩回転率 (/年)', calc: '63' },
  { position: '103', name: '哺乳中死亡率', calc: '69' },
  { position: '104', name: '生存産子数 (/腹)', calc: '65' },
  { position: '105', name: '分娩率', calc: '62' },
  { position: '106', name: '総産子数 (/腹)', calc: '64' },
  { position: '107', name: '死産数 (/腹)', calc: '68' },
  { position: '108', name: '飼料費 (/母豚/年)', calc: '58' },
  { position: '109', name: 'ワクチン・抗菌剤費 (/母豚/年)', calc: '70' },
];

const reportAllCardItems = [
  {
    position: '110', name: '全体成績（中央値）の推移【No.1】', calc: '49', pageNumber: 1,
  },
  {
    position: '111', name: '全体成績（中央値）の推移【No.2】', calc: '49', pageNumber: 2,
  },
  {
    position: '112', name: '全体成績（中央値）の推移【No.3】', calc: '49', pageNumber: 3,
  },
];

type Item = {
  bgStyle: string;
  name: string;
  text: string;
  component: JSX.Element;
  isDoublePrint: boolean;
  dividerItems?: string[];
};

const items: Item[] = [
  {
    bgStyle: 'linear-gradient(90deg,rgba(252,114,3,.2),rgba(252,114,3,.188) 50%,transparent)',
    name: 'divider1',
    text: '成績表',
    component: <></>,
    isDoublePrint: false,
    dividerItems: ['reportCard', 'reportCardRanking']
  },
  {
    bgStyle: '',
    name: 'reportCard',
    text: '1 成績表（全体）',
    component: <ReportCard largePageName="1 成績表（全体）" />,
    isDoublePrint: false,
  },
  {
    bgStyle: '',
    name: 'reportCardRanking',
    text: '2 成績表（全体）【ベンチマーキング項目別順位】',
    component: <ReportCardRanking largePageName="2 成績表（全体）【ベンチマーキング項目別順位】" />,
    isDoublePrint: false,
  },
  {
    bgStyle: 'linear-gradient(90deg,rgba(252,114,3,.2),rgba(252,114,3,.188) 50%,transparent)',
    name: 'divider2',
    text: '生産性ツリー',
    component: <></>,
    isDoublePrint: false,
    dividerItems: ['ReportProductivityTree']
  },
  {
    bgStyle: '',
    name: 'ReportProductivityTree',
    text: '3 生産性ツリー',
    component: <ReportProductivityTree largePageName="3 生産性ツリー" />,
    isDoublePrint: false,
  },
  {
    bgStyle: 'linear-gradient(90deg,rgba(252,114,3,.2),rgba(252,114,3,.188) 50%,transparent)',
    name: 'divider3',
    text: '項目別ランキング',
    component: <></>,
    isDoublePrint: false,
    dividerItems: ['reportRankingRadarChart']
  },
  {
    bgStyle: '',
    name: 'reportRankingRadarChart',
    text: '4 項目別ランキング',
    component: <ReportRankingRadarChart largePageName="4 項目別ランキング" />,
    isDoublePrint: false,
  },
  {
    bgStyle: 'linear-gradient(90deg,rgba(252,114,3,.2),rgba(252,114,3,.188) 50%,transparent)',
    name: 'divider4',
    text: '成績の推移',
    component: <></>,
    isDoublePrint: false,
    dividerItems: [...reportTransitionItems.map((item) => item.name)]
  },
  ...reportTransitionItems.map((item) => ({
    bgStyle: '',
    name: item.name,
    text: `${item.position} ${item.name}`,
    component: <ReportTransition key={item.name} pageNumber={item.pageNumber} largePageName={`${item.position} ${item.name}`} />,
    isDoublePrint: false,
  })),
  {
    bgStyle: 'linear-gradient(90deg,rgba(252,114,3,.2),rgba(252,114,3,.188) 50%,transparent)',
    name: 'divider5',
    text: '成績表個人',
    component: <></>,
    isDoublePrint: false,
    dividerItems: [...reportRankingChartItems.map((item) => `成績表個人${item.name}`)]

  },
  ...reportRankingChartItems.map((item) => ({
    bgStyle: '',
    name: `成績表個人${item.name}`,
    text: `${item.position} 成績表個人 ${item.name}`,
    component: <ReportRankingChart largePageName={`${item.position} 成績表個人 ${item.name}`} chartName={item.name} key={item.name} calc={item.calc} />,
    isDoublePrint: true,
  })),
  {
    bgStyle: 'linear-gradient(90deg,rgba(252,114,3,.2),rgba(252,114,3,.188) 50%,transparent)',
    name: 'divider6',
    text: '地域別集計',
    component: <></>,
    isDoublePrint: false,
    dividerItems: [...regionItems.map((item) => `地域別集計${item.name}`)]
  },
  ...regionItems.map((item) => ({
    bgStyle: '',
    name: `地域別集計${item.name}`,
    text: `${item.position} 地域別集計 ${item.name}`,
    component: <RegionChart largePageName={`${item.position} 地域別集計 ${item.name}`} chartName={item.name} key={item.name} calc={item.calc} />,
    isDoublePrint: true,
  })),
  {
    bgStyle: 'linear-gradient(90deg,rgba(252,114,3,.2),rgba(252,114,3,.188) 50%,transparent)',
    name: 'divider7',
    text: '規模別集計',
    component: <></>,
    isDoublePrint: false,
    dividerItems: [...scaleChartItems.map((item) => `規模別集計${item.name}`)]
  },
  ...scaleChartItems.map((item) => ({
    bgStyle: '',
    name: `規模別集計${item.name}`,
    text: `${item.position} 規模別集計 ${item.name}`,
    component: <ScaleChart largePageName={`${item.position} 規模別集計 ${item.name}`} chartName={item.name} key={item.name} calc={item.calc} />,
    isDoublePrint: true,
  })),
  {
    bgStyle: 'linear-gradient(90deg,rgba(252,114,3,.2),rgba(252,114,3,.188) 50%,transparent)',
    name: 'divider8',
    text: '全体成績',
    component: <></>,
    isDoublePrint: false,
    dividerItems: [...reportAllCardItems.map((item) => item.name)]
  },
  ...reportAllCardItems.map((item) => ({
    bgStyle: '',
    name: item.name,
    text: `${item.position} ${item.name}`,
    component: <ReportAllCard key={item.name} pageNumber={item.pageNumber} largePageName={`${item.position} ${item.name}`} />,
    isDoublePrint: false,
  })),
];

export default items;
