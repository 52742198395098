import React, {
  createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState,
} from 'react';
import dayjs from 'dayjs';
import { info, InfoData } from '../services/analysis';

interface AnalysisInterface {
  date: string,
  setDate: Dispatch<SetStateAction<string>>,
  fromMonth: string,
  setFromMonth: Dispatch<SetStateAction<string>>,
  toMonth: string,
  setToMonth: Dispatch<SetStateAction<string>>,
  year: string,
  setYear: Dispatch<SetStateAction<string>>,
  toYear: string,
  setToYear: Dispatch<SetStateAction<string>>,
  search: () => void
  searchCount: number,
  setSearchCount: Dispatch<SetStateAction<number>>,
  farm: string,
  setFarm: Dispatch<SetStateAction<string>>,
  textSize: string,
  setTextSize: Dispatch<SetStateAction<string>>,
  currentDataYear: string,
  currentDataToYear: string,
  currentDataFromMonth: string,
  currentDataToMonth: string,
  currentDataFarm: string,
  setCurrentDataDate: () => void
  infoData: InfoData,
  gettingData: boolean,
  setGettingData: Dispatch<SetStateAction<boolean>>,
  isPrintAll: boolean,
  setIsPrintAll: Dispatch<SetStateAction<boolean>>,
  dataLoading: boolean,
  setDataLoading: Dispatch<SetStateAction<boolean>>,
  checkedItems: string[],
  setCheckedItems: Dispatch<SetStateAction<string[]>>,
}

const AnalysisContext = createContext<AnalysisInterface>({} as AnalysisInterface);
AnalysisContext.displayName = 'AnalysisContext';

interface Props {
  children: JSX.Element,
}

export const AnalysisProvider: React.FC<Props> = ({ children }) => {
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [fromMonth, setFromMonth] = React.useState(dayjs().format('M'));
  const [toMonth, setToMonth] = React.useState(dayjs().format('M'));
  const [year, setYear] = React.useState(dayjs().format('YYYY'));
  const [toYear, setToYear] = React.useState(dayjs().format('YYYY'));
  const [farm, setFarm] = React.useState('');
  const [currentDataYear, setCurrentDataYear] = React.useState('');
  const [currentDataToYear, setCurrentDataToYear] = React.useState('');
  const [currentDataFromMonth, setCurrentDataFromMonth] = React.useState('');
  const [currentDataToMonth, setCurrentDataToMonth] = React.useState('');
  const [currentDataFarm, setCurrentDataFarm] = React.useState('');
  const [searchCount, setSearchCount] = useState(0);
  const search = () => setSearchCount(searchCount + 1);
  const [infoData, setInfoData] = useState({} as InfoData);
  const [textSize, setTextSize] = useState('');
  const [gettingData, setGettingData] = useState(true);
  const [isPrintAll, setIsPrintAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [dataLoading, setDataLoading] = useState(true);

  const setCurrentDataDate = () => {
    setCurrentDataYear(year);
    setCurrentDataToYear(toYear);
    setCurrentDataFromMonth(fromMonth);
    setCurrentDataToMonth(toMonth);
    setCurrentDataFarm(farm);
  };

  useEffect(() => {
    info(year, toYear, fromMonth, toMonth, farm).then((data) => {
      setInfoData(data.data);
    });
  }, [currentDataToMonth, currentDataFromMonth, currentDataYear, currentDataToYear, currentDataFarm]);

  const value = useMemo(
    () => ({
      dataLoading,
      setDataLoading,
      checkedItems,
      setCheckedItems,
      isPrintAll,
      setIsPrintAll,
      gettingData,
      setGettingData,
      textSize,
      setTextSize,
      infoData,
      farm,
      setFarm,
      currentDataFarm,
      setCurrentDataDate,
      currentDataYear,
      currentDataToYear,
      currentDataFromMonth,
      currentDataToMonth,
      fromMonth,
      setFromMonth,
      toMonth,
      setToMonth,
      year,
      setYear,
      toYear,
      setToYear,
      date,
      setDate,
      search,
      searchCount,
      setSearchCount,
    }),
    [
      farm,
      dataLoading,
      checkedItems,
      isPrintAll,
      date,
      year,
      toYear,
      toMonth,
      fromMonth,
      searchCount,
      currentDataYear,
      currentDataToYear,
      currentDataFromMonth,
      currentDataToMonth,
      currentDataFarm,
      infoData,
      textSize,
      gettingData],
  );

  return (
    <AnalysisContext.Provider
      value={value}
    >
      {children}
    </AnalysisContext.Provider>
  );
};

export const useAnalysisContext = (): AnalysisInterface => {
  const {
    dataLoading,
    setDataLoading,
    checkedItems,
    setCheckedItems,
    isPrintAll,
    setIsPrintAll,
    gettingData,
    setGettingData,
    textSize,
    setTextSize,
    infoData,
    farm,
    setFarm,
    currentDataFarm,
    setCurrentDataDate,
    currentDataYear,
    currentDataToYear,
    currentDataFromMonth,
    currentDataToMonth,
    fromMonth,
    setFromMonth,
    toMonth,
    setToMonth,
    year,
    setYear,
    toYear,
    setToYear,
    date,
    setDate,
    searchCount,
    search,
    setSearchCount,
  } = useContext(AnalysisContext);

  return {
    dataLoading,
    setDataLoading,
    checkedItems,
    setCheckedItems,
    isPrintAll,
    setIsPrintAll,
    gettingData,
    setGettingData,
    textSize,
    setTextSize,
    infoData,
    farm,
    setFarm,
    currentDataFarm,
    setCurrentDataDate,
    currentDataYear,
    currentDataToYear,
    currentDataFromMonth,
    currentDataToMonth,
    fromMonth,
    setFromMonth,
    toMonth,
    setToMonth,
    year,
    setYear,
    toYear,
    setToYear,
    date,
    setDate,
    searchCount,
    search,
    setSearchCount,
  };
};
