import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useAnalysisContext } from '../../contexts/AnalysisContext';

function useFetch<T>(
  getter: (year: string, toYear: string, month: string, toMonth: string, fromMonth: string) => Promise<AxiosResponse<T>>,
  isPrintAll: boolean,
  onLoading: (loading: boolean) => void,
  parseData?: (data: T) => any,
): { data: T, gettingData: boolean, farmName: string } {
  const [data, setData] = useState<T>(null);
  const [gettingData, setGettingData] = useState<boolean>(!!isPrintAll);
  const { search } = useLocation();
  const [query] = useState(queryString.parse(search));
  const [farmName, setFarmName] = useState('');

  const {
    toMonth, fromMonth, year, toYear, setCurrentDataDate, farm, searchCount,
  } = useAnalysisContext();

  const getData = () => {
    let yearLocal;
    let toYearLocal;
    let fromMonthLocal;
    let toMonthLocal;
    let farmLocal;
    if (isPrintAll) {
      yearLocal = query.year as string;
      toYearLocal = query.toYear as string;
      fromMonthLocal = query.fromMonth as string;
      toMonthLocal = query.toMonth as string;
      farmLocal = query.farm as string;
    } else {
      yearLocal = year;
      toYearLocal = toYear;
      fromMonthLocal = fromMonth;
      toMonthLocal = toMonth;
      farmLocal = farm;
    }
    setFarmName(farmLocal);
    if (!farmLocal) {
      return;
    }

    if (onLoading) onLoading(true);
    setGettingData(true);
    getter(yearLocal, toYearLocal, fromMonthLocal, toMonthLocal, farmLocal).then((data) => {
      setCurrentDataDate();
      if (data) {
        if (parseData) {
          const sortedData = parseData(data.data);
          setData(sortedData as T);
        } else {
          setData(data.data);
        }
      }
    }).finally(() => {
      if (onLoading) onLoading(false);
      setGettingData(false);
    });
  };

  useEffect(() => () => (onLoading ? onLoading(false) : null), []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (searchCount > 0) {
      getData();
    }
  }, [searchCount]);

  return { data, gettingData, farmName };
}

export default useFetch;
