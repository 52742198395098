import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

interface Props {
  onSearch: (text: string) => void;
}

const Search: React.FC<Props> = ({ onSearch }) => {
  const [text, setText] = React.useState('');

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(text);
    }
  };

  return (
    <>
      <div className="col">
        <div className="row row-cols-auto g-1 me-1">
          <div className="col">
            <label className="form-control-plaintext">検索：</label>
          </div>
          <div className="col">
            <input
              onKeyDown={handleKeyDown}
              value={text}
              onChange={(e) => setText(e.target.value)}
              className="form-control"
              placeholder="検索文字"
            />
          </div>
        </div>
      </div>
      <div className="col">
        <button className="btn btn-primary" type="button" onClick={() => onSearch(text)}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </button>
      </div>
    </>
  );
};

export default Search;
