import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import UnauthenticatedRoute from './UnauthenticatedRoute';
import Login from '../pages/Auth/Login';
import PrivateRoute from './PrivateRoute';
import PrivateOutlet from './PrivateOutlet';
import DataEntry from '../pages/DataEntry/DataEntry';
import { JspreadsheetProvider } from '../contexts/JspreadsheetContext';
import { AnalysisProvider } from '../contexts/AnalysisContext';
import Analysis from '../pages/Analysis/Analysis';
import { AnalysisMenuProvider } from '../contexts/AnalysisMenuContext';
import Farm from '../pages/Master/Farm/Farm';
import Veterinarian from '../pages/Master/Veterinarian/Veterinarian';
import Clerk from '../pages/Master/Clerk/Clerk';
import PrintAll from '../pages/Analysis/PrintAll';
import PrivateOutletSimple from './PrivateOutletSimple';
import MainPage from '../pages/Main/MainPage';
import Comment from '../pages/Comment/Comment';
import Report from '../pages/Master/Report/Report';
import CsvDownload from '../pages/Master/CsvDownload/CsvDownload';
import config from '../config';
import Notice from "../pages/Notice/Notice";
import FarmStatus from "../pages/FarmStatus/FarmStatus";

function Router(): React.ReactElement {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateOutlet />}>
          <Route path="/" element={<PrivateRoute feature="home:view"><MainPage /></PrivateRoute>} />
          <Route
            path="/data-entry"
            element={(
              <PrivateRoute feature="input:view">
                <JspreadsheetProvider>
                  <DataEntry />
                </JspreadsheetProvider>
              </PrivateRoute>
)}
          />
          <Route
            path="/analysis"
            element={(
              <PrivateRoute feature="analysis:view">
                <AnalysisMenuProvider><AnalysisProvider><Analysis /></AnalysisProvider></AnalysisMenuProvider>
              </PrivateRoute>
    )}
          />

          <Route path="/farm_master" element={(<PrivateRoute feature="user_management:view"><Farm /></PrivateRoute>)} />
          <Route path="/comment" element={(<PrivateRoute><Comment /></PrivateRoute>)} />
          <Route path="/notice" element={(<PrivateRoute><Notice /></PrivateRoute>)} />
          <Route path="/farm_status" element={(<PrivateRoute feature="user_management:view"><FarmStatus /></PrivateRoute>)} />
          <Route path="/veterinarian_master" element={(<PrivateRoute feature="user_management:view"><Veterinarian /></PrivateRoute>)} />
          <Route path="/clerk_master" element={(<PrivateRoute feature="user_management:view"><Clerk /></PrivateRoute>)} />
          <Route path="/report" element={(<PrivateRoute><Report /></PrivateRoute>)} />
          <Route path="/csv_download" element={(<PrivateRoute userType={[config.userType.clerk, config.userType.veterinarian]} feature="user_management:view"><CsvDownload /></PrivateRoute>)} />
        </Route>
        <Route path="/" element={<PrivateOutletSimple />}>
          <Route
            path="/print_all"
            element={(
              <PrivateRoute feature="analysis:view">
                <AnalysisMenuProvider><AnalysisProvider><PrintAll /></AnalysisProvider></AnalysisMenuProvider>
              </PrivateRoute>
                  )}
          />

        </Route>

        <Route path="/login" element={<UnauthenticatedRoute><Login /></UnauthenticatedRoute>} />
        <Route path="/*" element={<div> Not found </div>} />
      </Routes>
    </BrowserRouter>

  );
}
export default Router;
