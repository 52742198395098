import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../config';
import { decrypt, encrypt } from '../utils/encryption';

interface Authority {
  authority_group_id: number
  authority_group_name: string
  comment: string
  authorityList: {
    authority_id: number
    authority_name: string
    authority_feature: string
    comment: string
  }[]
}

interface ResponseUser {
  data: {
    user: User,
    token: string
    authority: Authority[]
  }
}

interface LoginUser {
  id: string,
  password: string,
  remember: boolean
}

interface User {
  name: string
  login_id: string
  type: number
  farm_id: string
  user_id: number
  veterinarian: {
    veterinarian_id: number
    veterinarian_name: string
    user_id: number
  }[]
}

interface UserContextInterface {
  isAuthenticated: boolean,
  user: User,
  loginUser: (data: LoginUser) => void,
  logoutUser: () => void,
  validationErrors: string[],
  loading: boolean,
  errorMessage: string,
  authority: Authority[]
  userCan: (feature: string) => boolean
}

const UserContext = createContext<UserContextInterface>({} as UserContextInterface);
UserContext.displayName = 'UserContext';

interface Props {
  children: JSX.Element
}

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User>({} as User);
  const [authority, setAuthority] = useState<Authority[]>([] as Authority[]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const userCan = (feature: string) => authority.some((item) => item.authorityList.some((authority) => authority.authority_feature === feature));

  useMemo(() => {
    const { cookieName } = config.auth;
    const cookiesList = Cookies.get();
    let userIdentity;
    if (cookieName in cookiesList) {
      const decrypted = decrypt(cookiesList[cookieName]);
      userIdentity = decrypted ? JSON.parse(decrypted) : null;
    }

    if (userIdentity) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      Cookies.remove(cookieName);
    }
    if (userIdentity) {
      const authUser = userIdentity.user;
      setUser(authUser);
      setAuthority(userIdentity.authority);
    }
  }, []);

  const loginUser = ({ id, password, remember }: LoginUser) => {
    setLoading(true);
    setValidationErrors([]);
    setErrorMessage('');
    axios.post<User, ResponseUser>(`${config.api.url}/auth/login`, {
      login_id: id,
      password,
    }).then((response) => {
      const { token, user, authority } = response.data;
      Cookies.set(config.auth.cookieName, encrypt(JSON.stringify({ token, user, authority })), {
        secure: config.env === 'production',
        expires: remember ? 365 : null, // if the user didn't select remember me, cookie expires on session end
      });

      setIsAuthenticated(true);
      location.href = '/';
    }).catch((error) => {
      if (error?.response?.data?.errors) {
        setValidationErrors(error.response.data.errors);
      }
      if (error?.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const logoutUser = () => {
    setIsAuthenticated(false);
    localStorage.clear();
    location.href = '/login';
  };

  const value = useMemo(
    () => ({
      userCan,
      authority,
      isAuthenticated,
      user,
      loginUser,
      logoutUser,
      validationErrors,
      loading,
      errorMessage,
    }),
    [isAuthenticated, errorMessage, authority],
  );

  return (
    <UserContext.Provider
      value={value}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = (): UserContextInterface => {
  const {
    userCan,
    authority,
    user,
    logoutUser,
    loginUser,
    isAuthenticated,
    validationErrors,
    loading,
    errorMessage,
  } = useContext(UserContext);

  return {
    userCan,
    authority,
    isAuthenticated,
    user,
    loginUser,
    logoutUser,
    validationErrors,
    loading,
    errorMessage,
  };
};
