import { AxiosResponse } from 'axios';
import api from '../contexts/GlobalContext';
import { Link } from '../components/Paginator';
import { defaultLimit } from './common';

export interface Veterinarian {
  user_id: number;
  login_id: string;
  name: string;
  type: number
  password: string;
  email: string;
}

interface VeterinarianResponse {
  data: Veterinarian[],
  links: Link[]
  current_page: number
  total: number
}

type VeterinarianDelete = '';

export const get = async (page: number, searchText?: string, limit?: number): Promise<AxiosResponse<VeterinarianResponse>> => api.get<VeterinarianResponse>(`/user/list?type=2&page=${page}&search=${searchText || ''}&limit=${limit || defaultLimit}`);

export const post = async (data: Veterinarian): Promise<AxiosResponse<Veterinarian>> => api.post<Veterinarian>('/user/register', { ...data, type: 2 });

export const put = async (data: Veterinarian): Promise<AxiosResponse<Veterinarian>> => api.post<Veterinarian>('/user/edit', data);

export const destroy = async (user_id: number) => api.post<VeterinarianDelete>('/user/delete', { user_id_list: [user_id] });

export default {
  get,
  post,
  put,
  destroy,
};
