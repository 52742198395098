import React, {
  useEffect, useRef, useState,
} from 'react';
import flatpickr from 'flatpickr';
import { Japanese } from 'flatpickr/dist/l10n/ja';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';

interface Props {
  onChange: (e: string) => void,
  initialDate?: string,
  monthsColor?: any
  id?: string,
  minDate?: string,
  maxDate?: string,
}

const DatePicker: React.FC<Props> = ({
  onChange, initialDate, monthsColor, id = 'date-picker-input--', minDate, maxDate,
}) => {
  const [date, setDate] = useState(initialDate || dayjs().format('YYYY/MM'));
  const ref = useRef(null);
  const onChangeHandler = (dates: Date[]) => {
    const pickedDate = dayjs(dates[0]);
    setDate(pickedDate.format('YYYY/MM'));
    onChange(pickedDate.format('YYYY-MM-DD'));
  };

  useEffect(() => {
    flatpickr(ref.current, {
      minDate,
      maxDate,
      disableMobile: true,
      onChange: onChangeHandler,
      locale: Japanese,
      mode: 'single',
      onDayCreate: (dObj, dStr, instance) => {
        setTimeout(() => {
          if (monthsColor) {
            try {
              document.querySelectorAll('.flatpickr-monthSelect-month').forEach((e: any) => {
                const monthNumber = Number(e.innerText.replaceAll('月', ''));
                if (monthsColor && monthsColor[instance.currentYear]) {
                  const obj = monthsColor[instance.currentYear].find((m: any) => m.registration_month === monthNumber);
                  if (obj && obj.color) {
                    if (e.classList.add) {
                      e.classList.add(obj.color);
                    }
                  }
                  if (!obj) {
                    e.classList.add('month-green');
                  }
                } else {
                  e.classList.add('month-green');
                }
              });
            } catch (error) {
              // console.log('error', error)
            }
          }
        }, 1);
      },
      onYearChange: (dObj, dStr, instance) => {
        setTimeout(() => {
          if (monthsColor) {
            try {
              document.querySelectorAll('.flatpickr-monthSelect-month').forEach((e: any) => {
                const monthNumber = Number(e.innerText.replaceAll('月', ''));
                if (monthsColor) {
                  const obj = monthsColor[instance.currentYear].find((m: any) => m.registration_month === monthNumber);
                  if (obj) {
                    e.classList.add(obj.color);
                  }
                }
              });
            } catch (error) {
              // console.log('error', error);
            }
          }
        }, 1);
      },
      plugins: [
        monthSelectPlugin({
          dateFormat: 'Y/m',
        }),
      ],
    });
  }, []);

  return (
    <>
      <div
        className="input-group input-group-icon"
        style={{ width: '150px' }}
      >
        <input
          ref={ref}
          onChange={() => ''}
          className="form-control"
          type="text"
          placeholder="yyyy/mm"
          id={id}
          value={date}
        />
        <label
          className="input-group-text-icon cursor-pointer"
          htmlFor={id}
        >
          <FontAwesomeIcon icon={faCalendarDays} />
        </label>
      </div>
    </>

  );
};

export default DatePicker;
