import yup from '../yup';

export const clerkSchema = yup.object().shape({
  name: yup.string()
    .nullable()
    .required('事務局名を入力してください。')
    .label('獣医師名'),
  login_id: yup.string()
    .nullable()
    .required('ログインIDを入力してください。')
    .label('ログインID'),
  password: yup
    .string()
    .when('$isCreate', (isCreate, passSchema) => (isCreate
      ? passSchema
      : passSchema.required('ログインパスワードを入力してください。'))),
});
