import React, {useEffect, useRef, useState,} from 'react';
import flatpickr from 'flatpickr';
import {Japanese} from 'flatpickr/dist/l10n/ja';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import dayjs from 'dayjs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarDays} from '@fortawesome/free-solid-svg-icons';

interface Props {
  onChange: (e: string) => void,
  initialDate?: string,
  id?: string,
}

const DatePicker: React.FC<Props> = (
  {
    onChange, initialDate, id = 'date-picker-input--'
  }) => {
  const [date, setDate] = useState(initialDate);
  const ref = useRef(null);
  const onChangeHandler = (dates: Date[]) => {
    const pickedDate = dayjs(dates[0]);
    setDate(pickedDate.format('MM'));
    onChange(pickedDate.format('MM'));
  };

  useEffect(() => {
    flatpickr(ref.current, {
      nextArrow: '',
      prevArrow: '',
      disableMobile: true,
      dateFormat: 'm',
      onOpen: () => {
        document.querySelectorAll('.flatpickr-current-month').forEach((e: any) => {
          e.style.display = 'none';
        });
        document.querySelectorAll('.flatpickr-prev-month').forEach((e: any) => {
          e.style.display = 'none';
        });
        document.querySelectorAll('.flatpickr-next-month').forEach((e: any) => {
          e.style.display = 'none';
        });
      },
      onChange: onChangeHandler,
      locale: Japanese,
      mode: 'single',
      plugins: [
        monthSelectPlugin({
          dateFormat: 'm',
          shorthand: true,
        }),
      ],
    });
  }, []);

  return (
    <>
      <div
        className="input-group input-group-icon"
        style={{width: '200px'}}
      >
        <input
          ref={ref}
          onChange={() => ''}
          className="form-control"
          type="text"
          placeholder="選択してください"
          id={id}
          value={date}
        />
        <label
          className="input-group-text-icon cursor-pointer"
          htmlFor={id}
        >
          <FontAwesomeIcon icon={faCalendarDays}/>
        </label>
      </div>
    </>

  );
};

export default DatePicker;
