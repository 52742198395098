import yup from '../yup';

export const commentSchema = yup.object().shape({
  to_user_id: yup.string()
    .nullable()
    .required('農場を選択してください。')
    .label('農場'),
  message: yup.string()
    .nullable()
    .required('コメントを入力してください。')
    .max(1000, '入力文字数は1000文字以下にしてください。')
    .label('コメント'),
});
