import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ModalComponent from '../../components/ModalComponent';
import Paginator from '../../components/Paginator';
import notice, {Notice as NoticeInterface, NoticeRequest, UserResponseNotice} from '../../services/notice';
import submit from '../../utils/submit';
import Search from '../../components/Search/Search';
import Table from '../../components/Table/Table';
import Card from '../Master/Card';
import {noticeSchema} from '../../validation/schemas/noticeSchema';
import {useUserContext} from '../../contexts/UserContext';
import Select from '../../components/Form/Select';
import farm from '../../services/farm';
import config from '../../config';
import TextArea from '../../components/Form/TextArea';
import veterinarian from "../../services/veterinarian";
import SelectGroup from "../../components/Form/SelectGroup";

function Notice() {
  const formRef = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [notices, setNotices] = useState<NoticeInterface[]>([] as NoticeInterface[]);
  const [selectedNotice, setSelectedNotice] = useState<NoticeInterface>(null);
  const [links, setLinks] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [total, setTotal] = useState(0);
  const [deleting, setDeleting] = useState(false);
  const [creating, setCreating] = useState(false);
  const [backendErrorMessage, setBackendErrorMessage] = useState('');
  const [users, setUsers] = useState({} as UserResponseNotice);
  const { user } = useUserContext();
  const [farms, setFarms] = useState([]);
  const [veterinarians, setVeterinarians] = useState([]);

  const {
    register, handleSubmit, formState: { errors }, reset,
  } = useForm<NoticeRequest>({
    mode: 'onBlur',
    resolver: yupResolver(noticeSchema),
    criteriaMode: 'all',
    defaultValues: selectedNotice,
  });

  useEffect(() => {
    farm.get(1, '', 999999).then((res) => {
      let farms = res.data.data;
      if (user.type === config.userType.veterinarian) {
        farms = farms.filter((farm) => farm.veterinarian.veterinarian_id === user.login_id);
      }
      setFarms(farms);
    });
  }, []);

  useEffect(() => {
    if (user.type === config.userType.clerk) {
      veterinarian.get(1, '', 999999).then((res) => {
        let veterinarians = res.data.data;
        setVeterinarians(veterinarians);
      });
    }
  }, []);

  const getNotices = (page?: number) => {
    if (!page) page = currentPage;
    notice.get(page, searchText).then((res) => {
      if (res.data.data.notices.length === 0 && currentPage > 1) {
        getNotices(currentPage - 1);
      } else {
        setNotices(res.data.data.notices);
        setUsers(res.data.data.users);
        setLinks(res.data.links);
        setCurrentPage(res.data.current_page);
        setTotal(res.data.total);
      }
    });
  };

  useEffect(() => {
    getNotices();
  }, [searchText]);

  const resetForm = () => {
    reset({
      notice: '',
    });
    setBackendErrorMessage('');
  };

  useEffect(() => {
    if (!selectedNotice) {
      resetForm();
    } else {
      reset(selectedNotice);
    }
  }, [selectedNotice]);

  const onDelete = () => {
    setDeleting(true);
    notice.destroy(selectedNotice.notice_id).then(() => {
      setTimeout(() => {
        getNotices();
      }, 50);
    }).finally(() => {
      setShowDeleteModal(false);
      setDeleting(false);
      resetForm();
    });
  };

  const changePage = (page: number) => {
    getNotices(page);
  };

  const postOk = () => {
    getNotices();
    resetForm();
    setShowCreateModal(false);
    setCreating(false);
  };

  const postNg = (err: any) => {
    setBackendErrorMessage(err?.response?.data?.notice || '');
    setCreating(false);
  };

  const onSubmit = handleSubmit(async (data) => {
    setCreating(true);
    notice.post(data).then(postOk).catch(postNg);
  });

  return (
    <main className="content p-3">
      <ModalComponent
        loading={creating}
        errorMessage={backendErrorMessage}
        closeOnConfirm={false}
        size="lg"
        title="お知らせを投稿"
        confirmButtonText="OK"
        onConfirm={() => submit(formRef)}
        show={showCreateModal}
        closeModal={() => {
          setSelectedNotice(null);
          setShowCreateModal(false);
        }}
      >
        <div className=" m-3">
          <form
            onSubmit={onSubmit}
            ref={formRef}
          >
            <TextArea title="お知らせ" name="notice" error={errors?.notice?.message} register={register} />
          </form>
        </div>
      </ModalComponent>

      <ModalComponent
        loading={deleting}
        closeOnConfirm={false}
        body="お知らせを削除してもよろしいですか？"
        title="削除確認"
        confirmButtonText="削除"
        confirmButtonColor="danger"
        onConfirm={onDelete}
        show={showDeleteModal}
        closeModal={() => { setShowDeleteModal(false); setSelectedNotice(null); }}
      />

      <Card createButtonText="お知らせを投稿" showCreateButton={user.type === config.userType.clerk || user.type === config.userType.veterinarian} title="お知らせ" subTitle="お知らせ一覧" onClickCreate={() => setShowCreateModal(true)}>
        <div className="row row-cols-auto g-1">
          <Search onSearch={(text) => setSearchText(text)} />
          <Paginator currentPage={currentPage} changePage={changePage} links={links} />
        </div>
        <Table>
          <thead>
            <tr>
              <th>投稿者</th>
              <th>お知らせ</th>
              <th>投稿日時</th>
              <th style={{ width: '9rem' }}>操作</th>
            </tr>
          </thead>
          <tbody>
            {notices.length === 0 && (
              <tr>
                <td colSpan={10} className="text-center">データはありません。</td>
              </tr>
            )}
            {
              notices && notices.map((notice) => (
                 <tr key={notice.notice_id}>
                   <td style={{ minWidth: '100px' }}>{users[notice.from_user_id].name || users[notice.from_user_id].farm_id}</td>
                   <td style={{ whiteSpace: 'normal' }}>{notice.notice}</td>
                   <td style={{ minWidth: '200px' }}>{dayjs(notice.created_at).format('YYYY/MM/DD HH:mm')}</td>
                   <td className="table-action text-center">
                     {
                        notice.from_user_id === user?.user_id && (
                        <div className="btn-group btn-group-sm">
                          <button
                            onClick={() => {
                              setSelectedNotice({ ...notice }); setShowDeleteModal(true);
                            }}
                            type="button"
                            className="btn btn-outline-secondary"
                          >
                            <FontAwesomeIcon icon={faTrash} className="me-1" />
                            削除
                          </button>
                        </div>
                        )
                     }
                   </td>
                 </tr>
               ))
             }
          </tbody>
        </Table>
        <div className="row row-cols-auto g-1 align-items-center">
          <div className="col ">
            <div className="page-item-count text-sm">
              全
              {' '}
              <span>{total}</span>
              {' '}
              件
            </div>
          </div>
          <Paginator currentPage={currentPage} changePage={changePage} links={links} />
        </div>
      </Card>
    </main>
  );
}

export default Notice;
