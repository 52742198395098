import { useCallback } from 'react';

export function useDownloadFile() {
  return useCallback((file: File) => {
    const link = document.createElement('a');
    link.download = file.name;
    link.href = URL.createObjectURL(file);
    link.click();
  }, []);
}
