import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ModalComponent from '../../../components/ModalComponent';
import Paginator from '../../../components/Paginator';
import clerk, { Clerk as ClerkType } from '../../../services/clerk';
import { clerkSchema } from '../../../validation/schemas/clerkSchema';
import submit from '../../../utils/submit';
import Input from '../../../components/Form/Input';
import Search from '../../../components/Search/Search';
import Table from '../../../components/Table/Table';
import ActionButton from '../../../components/Table/ActionButton';
import Card from '../Card';

function Clerk() {
  const formRef = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [users, setUsers] = useState<ClerkType[]>([] as ClerkType[]);
  const [selectedClerk, setSelectedClerk] = useState<ClerkType>(null);
  const [links, setLinks] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [total, setTotal] = useState(0);
  const [deleting, setDeleting] = useState(false);
  const [creating, setCreating] = useState(false);
  const [backendErrorMessage, setBackendErrorMessage] = useState('');

  const {
    register, handleSubmit, formState: { errors }, reset,
  } = useForm<ClerkType>({
    mode: 'onBlur',
    resolver: yupResolver(clerkSchema),
    context: { isCreate: !!selectedClerk?.user_id },
    criteriaMode: 'all',
    defaultValues: selectedClerk,
  });

  const getUsers = (page?: number) => {
    if (!page) page = currentPage;
    clerk.get(page, searchText).then((res) => {
      if (res.data.data.length === 0 && currentPage > 1) {
        getUsers(currentPage - 1);
      } else {
        setUsers(res.data.data);
        setLinks(res.data.links);
        setCurrentPage(res.data.current_page);
        setTotal(res.data.total);
      }
    });
  };

  useEffect(() => {
    getUsers();
  }, [searchText]);

  const resetForm = () => {
    reset({
      name: '',
      login_id: '',
      password: '',
    });
    setBackendErrorMessage('');
  };

  useEffect(() => {
    if (!selectedClerk) {
      resetForm();
    } else {
      reset(selectedClerk);
    }
  }, [selectedClerk]);

  const onDelete = () => {
    setDeleting(true);
    clerk.destroy(selectedClerk.user_id).then(() => {
      getUsers();
    }).finally(() => {
      setShowDeleteModal(false);
      setDeleting(false);
      resetForm();
    });
  };

  const changePage = (page: number) => {
    getUsers(page);
  };

  const postOk = () => {
    getUsers();
    resetForm();
    setShowCreateModal(false);
    setCreating(false);
  };

  const postNg = (err: any) => {
    setBackendErrorMessage(err?.response?.data?.message || '');
    setCreating(false);
  };

  const onSubmit = handleSubmit(async (data) => {
    setCreating(true);
    if (selectedClerk && selectedClerk.user_id) {
      clerk.put({ ...data, user_id: selectedClerk.user_id }).then(postOk).catch(postNg);
    } else {
      clerk.post(data).then(postOk).catch(postNg);
    }
  });

  return (
    <main className="content p-3">
      <ModalComponent
        loading={creating}
        errorMessage={backendErrorMessage}
        closeOnConfirm={false}
        size="lg"
        title={`事務局${selectedClerk ? '編集' : '新規作成'}`}
        confirmButtonText="OK"
        onConfirm={() => submit(formRef)}
        show={showCreateModal}
        closeModal={() => { setSelectedClerk(null); setShowCreateModal(false); }}
      >
        <div className=" m-3">
          <form
            onSubmit={onSubmit}
            ref={formRef}
          >
            <Input title="事務局名" name="name" error={errors?.name?.message} register={register} />
            <Input disabled={!!selectedClerk} title="ログインID" name="login_id" error={errors?.login_id?.message} register={register} />
            <Input type="password" title="ログインパスワード" name="password" error={errors?.password?.message} register={register} />
          </form>
        </div>
      </ModalComponent>

      <ModalComponent
        loading={deleting}
        closeOnConfirm={false}
        body={`${selectedClerk?.name || ''}を削除してもよろしいですか？`}
        title="削除確認"
        confirmButtonText="削除"
        confirmButtonColor="danger"
        onConfirm={onDelete}
        show={showDeleteModal}
        closeModal={() => { setShowDeleteModal(false); setSelectedClerk(null); }}
      />

      <Card title="ユーザー管理" subTitle="事務局一覧" onClickCreate={() => setShowCreateModal(true)}>
        <div className="row row-cols-auto g-1">
          <Search onSearch={(text) => setSearchText(text)} />
          <Paginator currentPage={currentPage} changePage={changePage} links={links} />
        </div>
        <Table>
          <thead>
            <tr>
              <th>名前</th>
              <th>ログインID</th>
              <th>権限</th>
              <th style={{ width: '9rem' }}>操作</th>
            </tr>
          </thead>
          <tbody>
            {users.length === 0 && (
              <tr>
                <td colSpan={10} className="text-center">データはありません。</td>
              </tr>
            )}
            {
                users && users.map((user) => (
                  <tr key={user.user_id}>
                    <td>{user.name}</td>
                    <td>{user.login_id}</td>
                    <td>事務局</td>
                    <td className="table-action text-center">
                      <ActionButton
                        onClickDelete={() => {
                          setSelectedClerk({ ...user }); setShowDeleteModal(true);
                        }}
                        onClickEdit={() => { setSelectedClerk({ ...user }); setShowCreateModal(true); }}
                      />
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </Table>
        <div className="row row-cols-auto g-1 align-items-center">
          <div className="col ">
            <div className="page-item-count text-sm">
              全
              {' '}
              <span>{total}</span>
              {' '}
              件
            </div>
          </div>
          <Paginator currentPage={currentPage} changePage={changePage} links={links} />
        </div>
      </Card>
    </main>
  );
}

export default Clerk;
