import { AxiosResponse } from 'axios';
import api from '../contexts/GlobalContext';
import { Link } from '../components/Paginator';
import { defaultLimit } from './common';

export interface Clerk {
  user_id: number;
  login_id: string;
  name: string;
  type: number
  password: string;
}

interface ClerkResponse {
  data: Clerk[],
  links: Link[]
  current_page: number
  total: number
}

type ClerkDelete = '';

export const get = async (page: number, searchText?: string, limit?: number): Promise<AxiosResponse<ClerkResponse>> => api.get<ClerkResponse>(`/user/list?type=1&page=${page}&search=${searchText || ''}&limit=${limit || defaultLimit}`);

export const post = async (data: Clerk): Promise<AxiosResponse<Clerk>> => api.post<Clerk>('/user/register', { ...data, type: 1 });

export const put = async (data: Clerk): Promise<AxiosResponse<Clerk>> => api.post<Clerk>('/user/edit', data);

export const destroy = async (user_id: number) => api.post<ClerkDelete>('/user/delete', { user_id_list: [user_id] });

export default {
  get,
  post,
  put,
  destroy,
};
