import { AxiosResponse } from 'axios';
import api from '../contexts/GlobalContext';
import { Farm } from '../pages/Master/Farm/types';
import { Link } from '../components/Paginator';
import { defaultLimit } from './common';

export interface Pref {
  pref_code: string;
  pref_name: string;
}

export interface Prefs {
  prefs: Pref[]
}

interface FarmResponse {
  data: Farm[],
  links: Link[]
  current_page: number
  total: number
}

export interface searchFarmForm {
  farm_id?: string,
  year: number,
  month?: number,
  registration_state?: string
}

export interface SearchFarmResponse {
  data: Farm[],
  current_page: number
  total: number
}

export type FarmInfo =
  Record<number, { color?: string, registration_month: number, registration_state: number | null | undefined, registration_year: number }[]>;

type FarmDelete = '';

export const save = async (): Promise<AxiosResponse<Prefs>> => api.get<Prefs>('/pref');

export const get = async (page: number, searchText?: string, limit?: number): Promise<AxiosResponse<FarmResponse>> => api.get<FarmResponse>(`/farm/list?page=${page}&search=${searchText || ''}&limit=${limit || defaultLimit}`);

export const searchFarms = async (page: number, searchForm?: searchFarmForm, limit?: number): Promise<AxiosResponse<any>> => api.get<SearchFarmResponse>(`/farm/searchFarm?page=${page}&limit=${limit || defaultLimit}`, { params: searchForm });

export const getYearInfo = async (farmId: string): Promise<AxiosResponse<FarmInfo>> => api.get('/farm/yearInfo', { params: { farm_id: farmId } });

export const post = async (data: Farm): Promise<AxiosResponse<Farm>> => api.post<Farm>('/farm/register', data);

export const put = async (data: Farm): Promise<AxiosResponse<Farm>> => api.post<Farm>('/farm/edit', data);

export const destroy = async (user_id: number) => api.post<FarmDelete>('/farm/delete', { farm_user_id_list: [user_id] });

export default {
  get,
  getYearInfo,
  post,
  put,
  destroy,
  searchFarms,
};
