import flatten from 'flat';

export const isObject = (variable: unknown): boolean => Object.prototype.toString.call(variable) === '[object Object]';
export const verifyObject = (object: unknown): number[] | null => {
  if (object && isObject(object)) {
    const data = flatten(object) as string[];
    const invalid = [];
    for (let key = 0; key < data.length; key++) {
      const value = data[key];
      if (value == null || Number.isNaN(value)) {
        console.log(`${key} is not set`);
        invalid.push(key);
      }
    }
    return invalid;
  }
  return null;
};
