import { AxiosResponse } from 'axios';
import api from '../contexts/GlobalContext';

export interface Calc {
  [key: string]: {
    isWithinRange: boolean;
    max: number | null;
    min: number | null;
    name: string;
    unit: string;
    value: number | null;
  };
}

export interface DataEntry {
  calcs: Calc;
  farm_id: string;
  registration_year: number;
  registration_month: number;
  registration_state: number;
  create_date: string;
  update_date: string;
  variety_male: string;
  variety_female: string;
  mother_pig: string;
  units_shipped: string;
  self_produced_candidates: string;
  shipping_carcass_weight: string;
  total_carcass_sales_amount: string;
  place_expenses: string;
  fare: string;
  net_income_of_total_carcass_sales: string;
  total_feed_purchase_amount_overall: string;
  total_feed_purchase_amount_sire_pig: string;
  total_feed_purchase_price_overall: string;
  fund_reserve_fund: string;
  fund_compensation_money: string;
  total_feed_purchase_price_sire_pig: string;
  vaccine_antibacterial_agent_costs: string;
  pigs_in_stock_after_weaning: string;
  suckling_pigs_in_stock: string;
  management_form: string;
  accidents_after_weaning: string;
  copulation: string;
  confinement: string;
  total_offspring: string;
  surviving_offspring: string;
  wean_piglets: string;
  pig_farm_workers: string;
  delivery_crates: string;
  fattening_area: string;
  presence_or_absence_of_prrs: string;
  piglets_shipped: string;
  net_income_of_piglet_sales: string;
  day_old_of_shipping_piglets: string;
  average_shipping_weight_of_piglets: string;
  lastMonth: DataEntry
  twoMonthsAgo: DataEntry
}

export interface Farm {
  farm_id: string
  farm_name: string
  farm_delegate_name: string
  zip_code: string
  pref_code: string
  address: string
  phone_number: string
  fax_number: string
  create_date: string
  update_date: string
  pref_name: string
}

export interface BackendData {
  currentMonth: DataEntry;
  lastMonth: DataEntry;
  twoMonthsAgo: DataEntry;
  farm: Farm;
}

export const getData = async (date: string, farmId: string): Promise<AxiosResponse<BackendData>> => api.get<BackendData>('/farm/monthly', {
  params: {
    registration_year: date.split('-')[0],
    registration_month: Number(date.split('-')[1]),
    farm_id: farmId,
  },
});

export const getDataEntryPage = async (date: string, farmId: string, isFromEditing: boolean): Promise<AxiosResponse<BackendData>> => api.get<BackendData>('/farm/monthly/getJsDataEntryPage', {
  params: {
    registration_year: date.split('-')[0],
    registration_month: Number(date.split('-')[1]),
    farm_id: farmId,
    updateFromPreviousMonth: isFromEditing ? '' : 1,
  },
});

export const sendData = async (form: Partial<DataEntry>): Promise<AxiosResponse<DataEntry>> => api.post<DataEntry>('/farm/monthly', form);

export const cancelState = async (form: Partial<DataEntry>): Promise<AxiosResponse<DataEntry>> => api.post<DataEntry>('/farm/monthly/cancel', form);
