import React, { useEffect, useState } from 'react';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  LinearScale,
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  Tooltip, PointElement, LineElement,
} from 'chart.js';

import { Bar, Line } from 'react-chartjs-2';
import { Spinner } from 'react-bootstrap';
import {
  getBarData, getLineData,
} from '../../services/analysis';
import { useAnalysisContext } from '../../contexts/AnalysisContext';
import If from '../../components/If/If';
import formatNumber from '../../utils/formatNumber';
import string from '../../utils/string';
import formatDate from '../../utils/formatDate';

ChartJS.register(
  annotationPlugin,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  BarElement,
  Tooltip,
);

const numberFormat = (number: number) => {
  if (!number) return '0';
  return number.toLocaleString('ja-JP', { maximumFractionDigits: 4 });
};

interface Props {
  calc: string
  breakBefore?: boolean
  onLoading?: (isLoading: boolean) => void
  chartName?: string
  largePageName?: string
  isPrintAll?: boolean
  printYear?: string
  printToYear?: string
  printFromMonth?: string
  printToMonth?: string
}

const ReportRankingChart: React.FC<Props> = ({
  calc, breakBefore, onLoading, chartName, largePageName, isPrintAll, printYear, printToYear, printFromMonth, printToMonth,
}) => {
  const [barChartData, setBarChartData] = useState<any>({
    labels: [0],
    datasets: [{ label: '' }],
  });
  const {
    searchCount, toMonth, fromMonth, year, toYear, setCurrentDataDate, farm, currentDataYear, currentDataToYear, currentDataFromMonth, currentDataToMonth,
  } = useAnalysisContext();

  const [lineOptions, setLineOptions] = useState({});

  const { search } = useLocation();
  const [query] = useState(queryString.parse(search));

  const [lineData] = useState({
    labels: [''],
    datasets: [
      {
        label: '上位10%',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderColor: 'rgba(230, 78, 0, 0.8)',
        pointColor: 'rgba(230, 78, 0, 1.0)',
        borderWidth: 1,
        pointRadius: 4,
        hitRadius: 5,
        pointStyle: 'rectRot',
        pointHoverBackgroundColor: 'rgba(255, 255, 255, 1.0)',
        pointHoverBorderColor: 'rgba(230, 78, 0, 1.0)',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 5,
        data: [0],
      },
      {
        label: '中央値',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderColor: 'rgba(127, 127, 127, 0.8)',
        pointColor: 'rgba(127, 127, 127, 1.0)',
        borderWidth: 1,
        pointRadius: 4,
        hitRadius: 5,
        pointStyle: 'rect',
        pointHoverBackgroundColor: 'rgba(255, 255, 255, 1.0)',
        pointHoverBorderColor: 'rgba(127, 127, 127, 1.0)',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 6,
        data: [0],
        borderDash: [5, 5],
      },
      {
        label: '下位10%',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderColor: 'rgba(68, 173 ,230, 0.8)',
        pointColor: 'rgba(68, 173 ,230, 1.0)',
        borderWidth: 1,
        pointRadius: 4,
        hitRadius: 5,
        pointStyle: 'rectRot',
        pointHoverBackgroundColor: 'rgba(255, 255, 255, 1.0)',
        pointHoverBorderColor: 'rgba(68, 173 ,230, 1.0)',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 5,
        data: [0],
      },
      {
        label: '個人値',
        backgroundColor: 'rgba(7, 96, 184, 0.9)',
        borderColor: 'rgba(7, 96, 184, 0.8)',
        pointColor: 'rgba(7, 96, 184, 1.0)',
        borderWidth: 2,
        pointRadius: 5,
        hitRadius: 5,
        pointStyle: 'circle',
        pointHoverBackgroundColor: 'rgba(7, 96, 184, 1.0)',
        pointHoverBorderColor: 'rgba(7, 96, 184, 1.0)',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 6,
        data: [0],
      },
    ],
  });

  const [barUserPoint] = useState({
    display: true,
    type: 'label' as const,
    backgroundColor: 'rgba(0,0,0,0)',
    color: '#dd0000',
    content: '★',
    font: {
      size: 32,
    },
    position: {
      x: 'center' as const,
      y: 'center' as const,
    },
    yAdjust: 0,
    xAdjust: 0,
    xMin: 0,
    xMax: 0,
    yMax: 0,
    yMin: 0,
  });

  const [barOptions, setBarOptions] = useState<any>(null);

  const [currentFarmAverage, setCurrentFarmAverage] = useState('0');
  const [pageName, setPageName] = useState('');
  const [dataLoaded, setDataLoaded] = useState(true);
  const [lineDataOk, setLineDataOk] = useState(false);
  const [barDataOK, setBarDataOk] = useState(false);

  useEffect(() => {
    if (barDataOK && lineDataOk) {
      setDataLoaded(true);
      if (onLoading) onLoading(false);
      setLineDataOk(false);
      setBarDataOk(false);
    }
  }, [barDataOK, lineDataOk]);

  let yearLocal;
  let toYearLocal;
  let fromMonthLocal;
  let toMonthLocal;
  let farmLocal: string;

  useEffect(() => () => (onLoading ? onLoading(false) : null), []);
  const [farmName, setFarmName] = useState('');
  const getData = () => {
    if (isPrintAll) {
      yearLocal = query.year as string;
      toYearLocal = query.toYear as string;
      fromMonthLocal = query.fromMonth as string;
      toMonthLocal = query.toMonth as string;
      farmLocal = query.farm as string;
    } else {
      yearLocal = year;
      toYearLocal = toYear;
      fromMonthLocal = fromMonth;
      toMonthLocal = toMonth;
      farmLocal = farm;
    }

    if (!farmLocal) {
      return;
    }
    if (onLoading) onLoading(true);
    setDataLoaded(false);
    setFarmName(farmLocal);
    getBarData(calc, yearLocal, toYearLocal, fromMonthLocal, toMonthLocal, farmLocal).then(({ data }) => {
      setCurrentDataDate();
      setPageName(data.name);

      const newObj2 = {
        ...barChartData,
      };
      newObj2.labels = data.axisX.map((item) => numberFormat(Number(item)));

      setBarChartData({
        labels: [0],
        datasets: [
          {
            display: true,
            label: chartName || '',
            backgroundColor: 'rgba(92,188,238, 0.8)',
            borderColor: 'rgba(21,139,202, 0.8)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(92,188,238, 1.0)',
            hoverBorderColor: 'rgba(21,139,202, 1.0)',
            hoverBorderWidth: 1,
            data: Object.keys(data.data).map((key) => ({
              x: Number(key) + data.tickX / 2, y: data.data[key].length,
            })),
            categoryPercentage: 1.0,
            barPercentage: 1.0,
          },
        ],
      });

      let currentFarmXPosition = 0;
      let currentFarmYPosition = 0;
      Object.keys(data.data).forEach((key) => {
        const currentFarmIndex = data.data[key].findIndex((item) => item.farm_id === farmLocal);
        if (currentFarmIndex !== -1) {
          currentFarmXPosition = Number(key);
          currentFarmYPosition = currentFarmIndex;
        }
      });
      const nextItem = currentFarmXPosition + data.tickX;
      if (Number(nextItem) !== currentFarmXPosition) {
        const diff = (Number(nextItem) - currentFarmXPosition) / 2;
        currentFarmXPosition += diff;
      }
      barUserPoint.xMin = currentFarmXPosition;
      barUserPoint.xMax = currentFarmXPosition;
      barUserPoint.yMin = currentFarmYPosition;
      barUserPoint.yMax = currentFarmYPosition;

      try {
        if (!data?.data || Object.keys(data.data).length === 0) {
          barUserPoint.display = false;
        }
      } catch (e) {
        console.log('e', e);
      }

      setBarOptions({
        animation: false as const,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'linear' as const,
            offset: false as const,
            grid: {
              offset: false,
            },
            min: Number(data.axisX[0]),
            max: Number(data.axisX[data.axisX.length - 1]),
            ticks: {
              stepSize: data.tickX,
              autoSkip: true,
              maxRotation: 40,
              minRotation: 40,
              callback(index: any): any {
                return `${formatNumber(index)}`;
              },
              font: {
                size: 13,
              },
            },
            title: {
              display: true,
              text: string.format(data.unit, '(', ')'),
            },
          },
          y: {
            suggestedMin: data.axisY[0],
            suggestedMax: data.axisY[data.axisY.length - 1],
            ticks: {
              stepSize: data.tickY,
              font: {
                size: 13,
              },
            },
            title: {
              display: true,
              text: '(件)',
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (items: any) => {
                if (!items.length) {
                  return '';
                }
                const item = items[0];
                const { x } = item.parsed;
                const min = (x - data.tickX / 2).toLocaleString();
                const max = (x + data.tickX / 2).toLocaleString();
                return `X軸: ${min}～${max}`;
              },
            },
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: 10,
                lineHeight: 1.0,
              },
              boxWidth: 12,
              boxHeight: 12,
              usePointStyle: false,
            },
          },
          annotation: {
            annotations: {
              barUserPoint,
            },
          },
        },
      });
      setCurrentDataDate();
    }).catch(() => {
      barUserPoint.xMin = 0;
      barUserPoint.xMax = 0;
      barUserPoint.yMin = 0;
      barUserPoint.yMax = 0;
      barChartData.labels = [''];
      barChartData.datasets[0].data = [0];
    }).finally(() => {
      setBarDataOk(true);
    });

    getLineData(calc, yearLocal, toYearLocal, fromMonthLocal, toMonthLocal, farmLocal).then(({ data }) => {
      const suggestedMin = Math.min(...data.axisY);

      setLineOptions({
        animation: false as const,
        responsive: true as const,
        maintainAspectRatio: false as const,
        scales: {
          x: {
            ticks: {
              maxRotation: 40,
              minRotation: 40,
              font: {
                size: 13,
              },
            },
            title: {
              display: true,
              text: '(年)',
            },
          },
          y: {
            min: data?.axisY?.[0],
            max: data?.axisY?.[(data.axisY?.length || 0) - 1],
            afterBuildTicks: (axis: any) => {
              axis.ticks = data.axisY?.map((item: any) => ({
                value: Number(item || 0),
              }));
            },
            suggestedMin,
            ticks: {
              callback(val: any) {
                return formatNumber(Number(val || 0));
              },
              stepSize: data.tickY,
              font: {
                size: 13,
              },
            },
            title: {
              display: true,
              text: string.format(data.unit, '(', ')'),
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'x',
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: 10,
                lineHeight: 1.0,
              },
              boxWidth: 12,
              boxHeight: 12,
              usePointStyle: true,
              pointStyle: 'line',
            },
          },
        },
        layout: {
          padding: {
            top: 0,
            right: 0,
          },
        },
      });

      lineData.datasets[0].data = Object.values(data.data).map((item) => item.top10);
      lineData.datasets[1].data = Object.values(data.data).map((item) => item.center);
      lineData.datasets[2].data = Object.values(data.data).map((item) => item.bottom10);
      lineData.datasets[3].data = Object.values(data.data).map((item) => item.current_farm);
      lineData.labels = data.axisX.map((item) => String(item));
      const { currentFarmValue } = data;
      setCurrentFarmAverage(formatNumber(currentFarmValue || null, data.unit === '円' ? '¥' : '', data.unit === '円' ? '' : data.unit));
    }).catch(() => {
      lineData.datasets[0].data = [0];
      lineData.datasets[1].data = [0];
      lineData.datasets[2].data = [0];
      lineData.datasets[3].data = [0];
      lineData.labels = [''];
    }).finally(() => {
      setLineDataOk(true);
    });
  };

  useEffect(() => {
    if (isPrintAll) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (searchCount > 0) {
      getData();
    }
  }, [searchCount]);

  return (
    <>
      <If test={!dataLoaded}>
        <div className="text-center p-5">
          <Spinner animation="border" />
        </div>
      </If>
      <section className="" id="reportRankingChart" style={{ display: !dataLoaded ? 'none' : 'block' }}>
        <div className={`${breakBefore ? 'page-break-before' : ''}`} />
        <div className="m-2 row">
          <div className="flex-fill text-sm-start col-4">
            農場ID:
            {farmName}
            <label className="text-muted fs-sm" style={{ fontSize: '0.8rem' }}>
              （期間:
              <span>
                {printYear ?? currentDataYear}
                /
                { formatDate(printFromMonth ?? currentDataFromMonth, 'MM')}
                ～
                {printToYear ?? currentDataToYear}
                /
                {formatDate(printToMonth ?? currentDataToMonth, 'MM')}
                )
              </span>
            </label>
          </div>
          <div className="flex-fill text-sm-center fw-bold col-4">
            {pageName}
          </div>
          <div className="flex-fill text-sm-end text-sm col-4">
            {largePageName}
          </div>
        </div>
        <div className="border-bottom my-1" />
        <div className="text-end">
          <span className="border-bottom border-2 border-danger py-1 px-2">
            <span className="text-sm me-4">{chartName}</span>
            <span className="fw-bold">
              {currentFarmAverage}
            </span>
          </span>
        </div>
        <div className="p-2 mt-2">
          <div className="row">
            <div className="col col-6 align-self-center ">
              <div className=" chart chartReportLowBox">
                {
                    dataLoaded && (
                    <Line style={{ maxHeight: '250px', height: '20px !important' }} id="chartLine" redraw data={lineData} options={lineOptions} />
                    )
                }
              </div>
            </div>
            <div className="col col-6 align-self-center ">
              {
                dataLoaded && (
                  <div className="chart chartReportLowBox">
                    <Bar style={{ maxHeight: '250px', height: '20px !important' }} id="chartLine" options={barOptions} data={barChartData} />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ReportRankingChart;
