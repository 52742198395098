import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

interface Props {
  onClickDownload: () => void;
  onClickDelete: () => void;
  showDeleteButton?: boolean;
}

const ActionButtonDownload: React.FC<Props> = ({ onClickDelete, onClickDownload, showDeleteButton }) => (
  <div className="btn-group btn-group-sm">
    <button
      type="button"
      className="btn btn-outline-secondary dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      操作を選択
    </button>
    <div className="dropdown-menu">
      <button
        type="button"
        onClick={() => onClickDownload()}
        className="dropdown-item"
      >
        <FontAwesomeIcon icon={faDownload} />
        {' '}
        ダウンロード
      </button>
        {showDeleteButton && (
          <button
            type="button"
            onClick={() => onClickDelete()}
            className="dropdown-item"
          >
            <FontAwesomeIcon icon={faTrash} />
            {' '}
            削除
          </button>
        )}
    </div>
  </div>
);

export default ActionButtonDownload;
