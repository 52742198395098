import yup from '../yup';

export const farmSchema = yup.object().shape({
  farm_id: yup.string()
    .nullable()
    .required('ユーザーIDを入力してください。')
    .label('ユーザーID'),
  pref_code: yup.string()
    .nullable()
    .required('都道府県を選択してください。')
    .label('都道府県'),
  veterinarian_user_id: yup.string()
    .nullable()
    .required('担当獣医師を選択してください。')
    .label('担当獣医師'),
  password: yup
    .string()
    .when('$isCreate', (isCreate, passSchema) => (isCreate
      ? passSchema
      : passSchema.required('ログインパスワードを入力してください。'))),
});
