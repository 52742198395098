import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import dayjs from 'dayjs';
import { useAnalysisContext } from '../../contexts/AnalysisContext';
import menuItems from './MenuItems';
import If from '../../components/If/If';
import config from '../../config';

function PrintAll() {
  const {
    dataLoading, setDataLoading,
  } = useAnalysisContext();

  let pageBreakCount = 0;
  const [checkedItems, setCheckedItems] = useState([]);
  const { search } = useLocation();
  const [query] = useState(queryString.parse(search));
  const [loadingItems, setLoadingItems] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleOnLoading = (isLoading: boolean, item: string) => {
    if (isLoading) {
      setLoadingItems((prev) => [...prev, item]);
    } else {
      setLoadingItems((prev) => prev.filter((i) => i !== item));
    }
  };

  const yearLocal = query.year as string;
  const fromMonthLocal = query.fromMonth as string;
  const toYearLocal = query.toYear as string;
  const toMonthLocal = query.toMonth as string;
  const farmLocal = query.farm as string;

  useEffect(() => {
    if (loadingItems.length === 0) {
      setDataLoading(false);
    } else {
      setDataLoading(true);
    }
  }, [loadingItems]);

  useEffect(() => {
    const items = query.items as string;
    setCheckedItems(items?.split(',') || []);
  }, []);

  return (
    <>
      <If test={dataLoading}>
        <div className="text-center p-5">
          <Spinner animation="border" />
        </div>
      </If>
      <div className="px-5" style={{ opacity: dataLoading ? '0' : '1' }}>
        {
        checkedItems.length === 0
          ? <div className="text-center">選択項目はありません。</div>

          : (
            <>
              <div className="d-flex justify-content-end py-3 d-print-none">
                <button type="button" className="btn btn-primary d-print-none" id="btnPrint" onClick={() => window.close()}>戻る</button>
                <button type="button" className="btn btn-primary d-print-none ms-3" id="btnPrint" disabled={dataLoading || loading} onClick={() => window.print()}>印刷</button>
              </div>

              <div style={{
                position: 'relative',
                textAlign: 'center',
                margin: '30px 0 10px 0 ',
              }}
              >
                <img style={{ width: '100%' }} src={`${config.api.url}/assets/img/analysis.jpg`} alt="" />
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '33%',
                  }}
                >
                  <h1 style={{ fontSize: '2.8vw' }}>
                    {farmLocal}
                    {' '}
                    {dayjs(`${yearLocal}/${fromMonthLocal}`).format('YYYY年M月')}
                    {' '}
                    〜
                    {' '}
                    {dayjs(`${toYearLocal}/${toMonthLocal}`).format('YYYY年M月')}
                  </h1>
                </div>

              </div>

              {
              checkedItems.map((item) => {
                const menuItem = menuItems.find((item2) => item2.name === item);
                const currentIsDoublePrint = menuItem.isDoublePrint;
                if (currentIsDoublePrint) {
                  pageBreakCount++;
                }
                return React.cloneElement(
                  menuItems.find((item2) => item2.name === item).component,
                  {
                    printYear: yearLocal, printFromMonth: fromMonthLocal, printToYear: toYearLocal, printToMonth: toMonthLocal, key: item, isMultiplePrint: true, breakBefore: pageBreakCount % 2 !== 0, isPrintAll: true, onLoading: (isLoading: boolean) => handleOnLoading(isLoading, item),
                  },
                );
              })
      }
            </>
          )

      }
      </div>
    </>
  );
}

export default PrintAll;
