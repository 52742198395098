import { AxiosResponse } from 'axios';
import api from '../contexts/GlobalContext';
import { Link } from '../components/Paginator';
import { defaultLimit } from './common';
import FileUtils from '../utils/file.utils';

export interface Report {
  id: number
  file_name: string
  uploader_name: string
  file_path: number;
  comment: string;
  uploaded_by: number;
  created_at: string;
  deleted_by: number;
  deleted_at: string;
}

export interface ReportPost {
  files: File[];
  comment: string
}

interface ReportResponse {
  data: Report[],
  links: Link[]
  current_page: number
  total: number
}

type ReportDelete = '';

export const get = async (page: number, searchText?: string, limit?: number): Promise<AxiosResponse<ReportResponse>> => api.get<ReportResponse>(`/report/list?type=1&page=${page}&search=${searchText || ''}&limit=${limit || defaultLimit}`);

export const post = async (data: any): Promise<AxiosResponse<Report>> => api.post<Report>('/report', data);

export const destroy = async (id: number) => api.post<ReportDelete>(`/report/delete/${id}`);

const download = async (report: any): Promise<File> => {
  const res = await api.post(`/report/download/${report.id}`);
  return FileUtils.fromBase64(`${res.data}`, report.file_name || 'file', { type: 'application/pdf' });
};

export default {
  get,
  post,
  destroy,
  download,
};
