import React, {
  createContext, Dispatch, SetStateAction, useContext, useMemo, useState,
} from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../config';
import { decrypt } from '../utils/encryption';

interface GlobalInterface {
  jspreadsheetDataHasChanged: boolean,
  setJspreadsheetDataHasChanged: Dispatch<SetStateAction<boolean>>,
  serverErrorMessage: string,
  setServerErrorMessage: Dispatch<SetStateAction<string>>,
}

const GlobalContext = createContext<GlobalInterface>({} as GlobalInterface);
GlobalContext.displayName = 'GlobalContext';

interface Props {
  children: JSX.Element,
}
const api = axios.create({
  baseURL: config.api.url,
});
export default api;

export const GlobalProvider: React.FC<Props> = ({ children }) => {
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  api.interceptors.request.use(async (axiosConfig) => {
    const cookiesList = Cookies.get();
    if (config.auth.cookieName in cookiesList) {
      try {
        const decrypted = decrypt(cookiesList[config.auth.cookieName]);
        const parsed = JSON.parse(decrypted);
        if (parsed?.token && axiosConfig.headers) {
          axiosConfig.headers.authorization = `Bearer ${parsed.token}`;
        }
      } catch (error) {
        console.error('Token error', error);
        Cookies.remove(config.auth.cookieName);
      }
    }
    return axiosConfig;
  });

  api.interceptors.response.use((response) => {
    setServerErrorMessage('');
    return response;
  }, (error) => {
    if (error?.response?.status >= 400) {
      if (error.response && error.response.status === 401) {
        Cookies.remove(config.auth.cookieName);
        return {};
      }
      const message = (error?.response?.status >= 400 && error?.response?.status <= 499)
        ? error?.response?.data?.message
        : 'Server error!';
      setServerErrorMessage(message);
      console.warn(message);
    } else {
      setServerErrorMessage('');
    }

    return Promise.reject(error);
  });

  const [jspreadsheetDataHasChanged, setJspreadsheetDataHasChanged] = useState(false);

  const value = useMemo(
    () => ({
      serverErrorMessage,
      jspreadsheetDataHasChanged,
      setJspreadsheetDataHasChanged,
      setServerErrorMessage,
    }),
    [jspreadsheetDataHasChanged, serverErrorMessage],
  );

  return (
    <GlobalContext.Provider
      value={value}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = (): GlobalInterface => {
  const {
    jspreadsheetDataHasChanged, setJspreadsheetDataHasChanged, serverErrorMessage, setServerErrorMessage,
  } = useContext(GlobalContext);

  return {
    jspreadsheetDataHasChanged, setJspreadsheetDataHasChanged, serverErrorMessage, setServerErrorMessage,
  };
};
