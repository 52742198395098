import { AxiosResponse } from 'axios';
import api from '../contexts/GlobalContext';

export interface Pref {
  pref_code: string;
  pref_name: string;
}

export interface Prefs {
  prefs: Pref[]
}
const get = async (): Promise<AxiosResponse<Prefs>> => api.get<Prefs>('/pref');

export default {
  get,
};
