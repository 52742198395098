import React, {
  createContext, Dispatch, SetStateAction, useContext, useMemo, useState,
} from 'react';
import dayjs from 'dayjs';
import {
  BackendData, Calc, DataEntry, getData, getDataEntryPage,
} from '../services';

interface JspreadsheetInterface {
  backendData: BackendData,
  setData: Dispatch<SetStateAction<BackendData>>,
  yearMonth: string,
  setYearMonth: Dispatch<SetStateAction<string>>,
  farmName: string,
  setFarmName: Dispatch<SetStateAction<string>>,
  getJsData: (date: string, farmId: string, showSpinner?: boolean) => void,
  getJsDataEntryPage: (date: string, farmId: string, showSpinner?: boolean, isFromEditing?: boolean) => void,
  entryData: string[],
  setEntryData: Dispatch<SetStateAction<string[]>>,
  gettingData: boolean,
  setGettingData: Dispatch<SetStateAction<boolean>>,
  sendingData: boolean,
  setSendingData: Dispatch<SetStateAction<boolean>>,
  date: string,
  setDate: Dispatch<SetStateAction<string>>,
  backendErrorMessage: string,
  setBackendErrorMessage: Dispatch<SetStateAction<string>>,
  calcsData: Calc,
  setCalcsData: Dispatch<SetStateAction<Calc>>,
  outOfRangeCount: number,
  setOutOfRangeCount: Dispatch<SetStateAction<number>>,

}

const JspreadsheetContext = createContext<JspreadsheetInterface>({} as JspreadsheetInterface);
JspreadsheetContext.displayName = 'JspreadsheetContext';

interface Props {
  children: JSX.Element,
}

export const JspreadsheetProvider: React.FC<Props> = ({ children }) => {
  const [backendData, setBackendData] = useState<BackendData>(null);
  const [entryData, setEntryData] = useState<string[]>(null);
  const [yearMonth, setYearMonth] = useState(dayjs().format('YYYY/MM'));
  const [farmName, setFarmName] = useState('');
  const [gettingData, setGettingData] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [backendErrorMessage, setBackendErrorMessage] = useState('');
  const [calcsData, setCalcsData] = useState(null);
  const [outOfRangeCount, setOutOfRangeCount] = useState(0);

  const getJsData = async (date: string, farmId: string, showSpinner = true) => {
    if (showSpinner) setGettingData(true);
    getData(date, farmId).then((res) => {
      setFarmName(farmId);
      if (res.data.currentMonth === null) {
        res.data.currentMonth = {} as DataEntry;
      }
      if (res.data.lastMonth === null) {
        res.data.lastMonth = {} as DataEntry;
      }
      if (res.data.twoMonthsAgo === null) {
        res.data.twoMonthsAgo = {} as DataEntry;
      }
      setBackendData(res.data);
      if (res.data.currentMonth) {
        setCalcsData(res?.data?.currentMonth?.calcs);
      }
    }).finally(() => {
      setGettingData(false);
    });
  };

  const getJsDataEntryPage = async (date: string, farmId: string, showSpinner = true, isFromEditing = false) => {
    if (showSpinner) setGettingData(true);
    getDataEntryPage(date, farmId, isFromEditing).then((res) => {
      setFarmName(farmId);
      if (res.data.currentMonth === null) {
        res.data.currentMonth = {} as DataEntry;
      }
      if (res.data.lastMonth === null) {
        res.data.lastMonth = {} as DataEntry;
      }
      if (res.data.twoMonthsAgo === null) {
        res.data.twoMonthsAgo = {} as DataEntry;
      }
      setBackendData(res.data);
      if (res.data.currentMonth) {
        setCalcsData(res?.data?.currentMonth?.calcs);
      }
    }).finally(() => {
      setGettingData(false);
    });
  };

  const value = useMemo(
    () => ({
      sendingData,
      setSendingData,
      outOfRangeCount,
      setOutOfRangeCount,
      calcsData,
      setCalcsData,
      setGettingData,
      backendErrorMessage,
      setBackendErrorMessage,
      date,
      setDate,
      entryData,
      setEntryData,
      gettingData,
      getJsData,
      getJsDataEntryPage,
      yearMonth,
      setYearMonth,
      farmName,
      setFarmName,
      backendData,
      setData: setBackendData,
    }),
    [backendData, gettingData, entryData, yearMonth, farmName, date, backendErrorMessage, calcsData, outOfRangeCount, sendingData],
  );

  return (
    <JspreadsheetContext.Provider
      value={value}
    >
      {children}
    </JspreadsheetContext.Provider>
  );
};

export const useJspreadsheetContext = (): JspreadsheetInterface => {
  const {
    sendingData,
    setSendingData,
    outOfRangeCount,
    setOutOfRangeCount,
    calcsData,
    setCalcsData,
    yearMonth,
    backendData,
    setData,
    setYearMonth,
    farmName,
    setFarmName,
    getJsData,
    getJsDataEntryPage,
    gettingData,
    entryData,
    setEntryData,
    setGettingData,
    date,
    setDate,
    backendErrorMessage,
    setBackendErrorMessage,
  } = useContext(JspreadsheetContext);

  return {
    sendingData,
    setSendingData,
    outOfRangeCount,
    setOutOfRangeCount,
    calcsData,
    setCalcsData,
    yearMonth,
    backendData,
    setData,
    setYearMonth,
    farmName,
    setFarmName,
    getJsData,
    getJsDataEntryPage,
    gettingData,
    entryData,
    setEntryData,
    setGettingData,
    date,
    setDate,
    backendErrorMessage,
    setBackendErrorMessage,
  };
};
