import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useAnalysisContext } from '../../contexts/AnalysisContext';
import {
  ReportCardResult, resultSheetBenchmark,
} from '../../services/analysis';
import formatNumber from '../../utils/formatNumber';
import If from '../../components/If/If';
import useFetch from './analisysHook';
import formatDate from '../../utils/formatDate';

interface Props {
  onLoading?: (isLoading: boolean) => void
  largePageName?: string
  isPrintAll?: boolean
  printYear?: string
  printToYear?: string
  printFromMonth?: string
  printToMonth?: string
}

const ReportCardRanking: React.FC<Props> = ({
  onLoading, largePageName, isPrintAll, printYear, printToYear, printFromMonth, printToMonth,
}) => {
  // name: 成績表（全体）
  const {
    currentDataYear, currentDataToYear, textSize, currentDataToMonth, currentDataFromMonth,
  } = useAnalysisContext();
  const parseData = (data: ReportCardResult) => Object.values(data.data).sort((a, b) => {
    if (a.current_farm.score < b.current_farm.score) {
      return -1;
    } if (a.current_farm.score > b.current_farm.score) {
      return 1;
    }
    return 0;
  });

  const { data, gettingData, farmName } = useFetch<ReportCardResult>(resultSheetBenchmark, isPrintAll, onLoading, parseData);

  let data2 = [];
  if (data) {
    data2 = Object.values(data).sort((a, b) => {
      if (a.current_farm.score < b.current_farm.score) {
        return -1;
      } if (a.current_farm.score > b.current_farm.score) {
        return 1;
      }
      return a.current_farm.rank - b.current_farm.rank;
    });
  }

  return (
    <>
      <If test={gettingData}>
        <div className="text-center p-5">
          <Spinner animation="border" />
        </div>
      </If>
      <section id="reportCardRanking" style={{ display: gettingData ? 'none' : 'block' }}>
        <div className="page-break-before" />
        <div className="mt-2 row">
          <div className="flex-fill text-sm-start  col-4">
            農場ID:
            {farmName}
            <label className="text-muted fs-sm" style={{ fontSize: '0.8rem' }}>
              （期間:
              <span>
                {printYear ?? currentDataYear}
                /
                { formatDate(printFromMonth ?? currentDataFromMonth, 'MM')}
                ～
                {printToYear ?? currentDataToYear}
                /
                {formatDate(printToMonth ?? currentDataToMonth, 'MM')}
                )
              </span>
            </label>
          </div>
          <div className="flex-fill text-sm-center fw-bold col-4">
            {printYear ?? currentDataYear}
            年
            成績表（全体）【ベンチマーキング項目別順位】
          </div>
          <div className="flex-fill text-sm-end text-sm col-4">{largePageName}</div>
        </div>
        <div className="border-bottom my-1" />
        <div className="text-sm text-dark mt-1">
          凡例：
          <span className="rankA"> A </span>
          ～上位10%
          <span className="rankB"> B </span>
          上位10%～25%
          <span className="rankC"> C </span>
          上位25%～50%
          <span className="rankD"> D </span>
          上位50%～75%
          <span className="rankE"> E </span>
          上位75%～90%
          <span className="rankF"> F </span>
          上位90%～
        </div>
        <div className="table-responsive mt-1">
          <table
            className={`table align-middle table-striped table-standard table-bordered table-tbody-nowrap table-sm text-sm-print text-${textSize}`}
            id="tblRanking"
          >
            <thead>
              <tr>
                <th>順位</th>
                <th>判定</th>
                <th>項目</th>
                <th>結果</th>
                <th>データ数</th>
                <th>上位10%</th>
                <th>上位25%</th>
                <th>中央値</th>
                <th>下位25%</th>
                <th>下位10%</th>
              </tr>
            </thead>
            <tbody>
              {
              !data2
              && (
              <tr>
                <td colSpan={10} className="text-center">データはありません。</td>
              </tr>
              )
          }
              {
              data2 && Object.values(data2).map((item) => (
                <tr key={item.name}>
                  <td className="text-end">{formatNumber(item.current_farm.rank)}</td>
                  <td className={`rank${item.current_farm.score}`}>{item.current_farm.score}</td>
                  <td>{item.name}</td>
                  <td className="text-end">{formatNumber(item.current_farm.value, item.unit === '円' ? '¥' : '', item.unit === '円' ? '' : item.unit)}</td>
                  <td className="text-end">{formatNumber(item.data_count)}</td>
                  <td className="text-end">{formatNumber(item.top10, item.unit === '円' ? '¥' : '', item.unit === '円' ? '' : item.unit)}</td>
                  <td className="text-end">{formatNumber(item.top25, item.unit === '円' ? '¥' : '', item.unit === '円' ? '' : item.unit)}</td>
                  <td className="text-end">{formatNumber(item.center, item.unit === '円' ? '¥' : '', item.unit === '円' ? '' : item.unit)}</td>
                  <td className="text-end">{formatNumber(item.bottom25, item.unit === '円' ? '¥' : '', item.unit === '円' ? '' : item.unit)}</td>
                  <td className="text-end">{formatNumber(item.bottom10, item.unit === '円' ? '¥' : '', item.unit === '円' ? '' : item.unit)}</td>
                </tr>
              ))
          }
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default ReportCardRanking;
