import React from 'react';

type Option = {
  value: string;
  label: string;
};

interface Props {
  title: string;
  items: Option[];
  error: string;
  register: (name: any) => Record<string, unknown>;
  name: string;
}

const Select: React.FC<Props> = ({
  title, items, error, register, name,
}) => (
  <div className="mb-3 row">
    <label className="col-form-label col-sm-2 text-sm-start" id="validatione1">{title}</label>
    <div className="col-sm-10">
      <select {...register(name)} className={`form-control ${error ? 'is-invalid' : ''}`}>
        <option value="">--選択してください--</option>
        { items.map((item) => <option key={item.value} value={item.value}>{item.label}</option>) }
      </select>
      <div className="invalid-feedback">{error}</div>
    </div>
  </div>
);

export default Select;
