import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import SideMenu from './SideMenu';
import { useAnalysisContext } from '../../contexts/AnalysisContext';
import '../../assets/css/print.css';
import menuItems from './MenuItems';
import '../../assets/css/style.jasv.analysis.css';
import { useAnalysisMenuContext } from '../../contexts/AnalysisMenuContext';
import { useGlobalContext } from '../../contexts/GlobalContext';
import PrintAll from './PrintAll';
import { useUserContext } from '../../contexts/UserContext';
import Select from '../../components/Select';
import config from '../../config';
import DatePicker from '../../components/Form/DatePicker';
import useMonthsColor from '../../hooks/useMonthsColor';
import useGetFarms from '../../hooks/useGetFarms';

function Analysis() {
  const {
    setFarm,
    isPrintAll,
    year,
    toYear,
    fromMonth,
    toMonth,
    setToMonth,
    setYear,
    setToYear,
    setFromMonth,
    search,
    infoData,
    setTextSize,
    textSize,
    checkedItems,
  } = useAnalysisContext();
  const {
    selectedItem, isShowingSideMenu, setIsShowingSideMenu,
  } = useAnalysisMenuContext();
  const {
    farmId, farms, setFarmId,
  } = useGetFarms();
  const {
    datePickerKey, setDatePickerKey, farmYearInfo, getInfo,
  } = useMonthsColor(farms, farmId);

  const handleFromMonthChange = (e: string) => {
    const fromMonth = dayjs(e).format('M');
    const year = dayjs(e).format('YYYY');
    if (Number(fromMonth) > Number(toMonth)) {
      
    }
    setToMonth(fromMonth);
    setToYear(year);
  };

  const { userCan, user } = useUserContext();
  const { serverErrorMessage, setServerErrorMessage } = useGlobalContext();
  const setTextSmall = () => setTextSize('sm');
  const setTextNormal = () => setTextSize('');
  const [toMonthKey, setToMonthKey] = useState(0);

  useMemo(() => {
    setServerErrorMessage('');
  }, []);

  useEffect(() => {
    setDatePickerKey((prev) => prev + 1);
  }, [farmYearInfo]);

  useEffect(() => {
    if (user.type === config.userType.farm) {
      setFarm(user.farm_id);
      search();
    }
  }, []);

  useEffect(() => {
    setFarm(farmId);
  }, [farmId]);

  useEffect(() => {
    if (farmId && toMonth && fromMonth) {
      getInfo();
      search();
    }
  }, [farmId, toMonth, fromMonth, year, toYear]);

  return (
    <main className="content p-3 py-0-print">
      <div className="container-fluid p-0">
        <div className="mb-1 d-print-none">
          <div className="row">
            <div className="col-auto align-self-center">
              <h3 className="h3 d-inline align-middle">データ解析</h3>
            </div>
            <div className="col-auto align-self-center">
              <div className="row row-cols-auto g-1">
                {
                    userCan('input:search')
                    && (
                    <>
                      <div className="col">
                        <div className="row row-cols-auto g-1 me-1">
                          <label className="col col-form-label">農場：</label>
                          <div className="col">
                            <Select
                              hasSelectMessage={farms.length === 0}
                              disabled={user.type === config.userType.farm}
                              value={farmId}
                              options={farms?.map((farm) => ({ value: farm.farm_id, label: farm.farm_id }))}
                              onChange={(param) => {
                                setFarm(param);
                                setFarmId(param);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row row-cols-auto g-1 me-2">
                          <div className="col">
                            <label
                              className="form-control-plaintext"
                            >
                              年月：
                            </label>
                          </div>
                          <div className="col">
                            {/* date picker fromMonth */}
                            <DatePicker
                              id="fromMonth"
                              key={datePickerKey}
                              initialDate={dayjs(`${year}/${fromMonth}`).format('YYYY/MM')}
                              onChange={(e) => {
                                setToMonthKey((prev) => prev + 1);
                                handleFromMonthChange(e);
                                setFromMonth(dayjs(e).format('M'));
                                setYear(dayjs(e).format('YYYY'));
                              }}
                              monthsColor={farmYearInfo}
                            />
                          </div>

                          <div className="mt-2">
                            〜
                          </div>

                          <div className="col">
                            {/* date picker toMonth */}
                            <DatePicker
                              minDate={(() => {
                                if (fromMonth && year) {
                                  return dayjs(`${year}/${fromMonth}/01`).format('YYYY/MM/DD');
                                }
                                return null;
                              })()}
                              maxDate={(() => {
                                if (fromMonth && year) {
                                  if(fromMonth == '1') {
                                    return dayjs(`${year}/12/31`).format('YYYY/MM/DD');
                                  } else {
                                    return dayjs(`${Number(year)+1}/${Number(fromMonth)-1}/01`).format('YYYY/MM/DD');
                                  }
                                }
                                return null;
                              })()}
                              id="toMonth"
                              key={datePickerKey + toMonthKey}
                              initialDate={dayjs(`${toYear}/${toMonth}/01`).format('YYYY/MM') || dayjs().format('YYYY/MM')}
                              onChange={(e) => {
                                setToMonth(dayjs(e).format('M'));
                                setToYear(dayjs(e).format('YYYY'));
                              }}
                              monthsColor={farmYearInfo}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                    )
                }
              </div>
            </div>
            <div className="col-sm-auto ms-sm-auto align-self-center">
              <div className="float-sm-end mb-1">
                {
                  userCan('analysis:print') && (
                  <button disabled={checkedItems.length > 0 && isPrintAll} type="button" className="btn btn-primary" id="btnPrint" onClick={() => window.print()}>印刷</button>
                  )
                }
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">

            <div className="row g-1">

              { isShowingSideMenu && <SideMenu />}

              {/* main card */}
              <div className={`col-12 col-md-9 col-12-print py-0-print ${!isShowingSideMenu ? 'col-md-12' : ''}`} id="areaData">
                <div className="card">
                  <div className="card-header p-2 d-print-none">
                    <div className="row row-cols-auto">
                      <div className="col">
                        <div className="d-flex">
                          <div className="m-0 p-0">
                            <button
                              type="button"
                              className="btn  text-sm m-0 me-1 p-0 px-1"
                              id="btnMenuHide"
                            >
                              <i
                                className="fa-solid fa-chevron-left"
                              />
                            </button>
                            <button
                              onClick={() => setIsShowingSideMenu(!isShowingSideMenu)}
                              type="button"
                              className="btn  text-sm m-0 me-1 p-0 px-1"
                              id="btnMenuShow"
                            >
                              <FontAwesomeIcon icon={isShowingSideMenu ? faChevronLeft : faChevronRight} />
                            </button>
                          </div>
                          <h5 className="card-title">解析データ</h5>
                        </div>
                      </div>
                      <div className="col">
                        <label className="text-muted fs-sm">
                          (参照データ:
                          {' '}
                          <span>
                            {infoData.count}
                            件
                          </span>
                          ）
                        </label>
                      </div>
                      {
                       (!selectedItem.text.includes('地域別集計') && !['reportRankingRadarChart'].includes(selectedItem.name) && !selectedItem.text.includes('成績表個人')) && (
                       <div className="col ms-sm-auto">
                         <button
                           disabled={textSize === 'sm'}
                           onClick={() => setTextSmall()}
                           type="button"
                           className="btn btn-outline-secondary btn-xs fw-light"
                           id="btnFontSmall"
                         >
                           小
                         </button>
                         <button
                           disabled={textSize === ''}
                           onClick={() => setTextNormal()}
                           type="button"
                           className="ms-1 btn btn-outline-secondary btn-xs fw-light"
                           id="btnFontNormal"
                         >
                           中
                         </button>
                       </div>
                       )
                      }
                    </div>
                  </div>

                  {/* card body */}
                  <div className="card-body p-2 py-0-print">
                    {
                      serverErrorMessage
                      && (
                      <div
                        className=" d-print-none"
                        style={{
                          width: '100%', color: '#FFFFFF', backgroundColor: '#ff6565', padding: '5px', borderRadius: '5px',
                        }}
                      >
                        {serverErrorMessage}
                      </div>
                      )
                    }
                    {
                      isPrintAll
                        ? <PrintAll />
                        : menuItems.find((item) => item.name === selectedItem.name).component
                    }
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Analysis;
