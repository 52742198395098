import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import App from './App';
import './assets/css/bootstrap.min.css';
import './assets/css/bootstrap-print.css';
import './assets/css/main-style.scss';
import './assets/css/base.css';
import './assets/css/rcstyle.scss';
import './assets/css/style.jasv.scss';
import './assets/plugins/flatpickr/flatpickr.min.css';
import './assets/plugins/flatpickr/plugins/monthSelect/style.min.css';
import './assets/css/custom.flatpickr.css';

import { UserProvider } from './contexts/UserContext';
import { GlobalProvider } from './contexts/GlobalContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <UserProvider>
    <GlobalProvider>
      <HelmetProvider>
        <Helmet titleTemplate="%s | ベンチマーキングWEBシステム " defaultTitle="ベンチマーキングWEBシステム" />
        <App />
      </HelmetProvider>
    </GlobalProvider>
  </UserProvider>
  ,
  // </React.StrictMode>,
);
