import React from 'react';

interface Props {
  title: string;
  error: string;
  register: (name: any) => Record<string, unknown>;
  name: string;
  disabled?: boolean;
  rows?: number;
}

const TextArea: React.FC<Props> = ({
  title, error, register, name, disabled, rows = 2,
}) => (
  <div className="mb-3 row">
    <label className="col-form-label col-sm-2 text-sm-start">{title}</label>
    <div className="col-sm-10">
      <textarea readOnly={disabled} {...register(name)} disabled={disabled} className={`form-control ${error ? 'is-invalid' : ''}`} rows={rows} />
      <div className="invalid-feedback">{error}</div>
    </div>
  </div>
);

export default TextArea;
