import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useAnalysisContext } from '../../contexts/AnalysisContext';
import { ProductivityTree, productivityTreeData } from '../../services/analysis';
import formatNumber from '../../utils/formatNumber';
import If from '../../components/If/If';
import useFetch from './analisysHook';
import formatDate from '../../utils/formatDate';

interface Props {
  onLoading?: (isLoading: boolean) => void
  largePageName?: string
  isPrintAll?: boolean
  printYear?: string
  printToYear?: string
  printFromMonth?: string
  printToMonth?: string
}

const ReportProductivityTree: React.FC<Props> = ({
  onLoading, largePageName, isPrintAll, printYear, printToYear, printFromMonth, printToMonth,
}) => {
  // name: 生産性ツリー
  const {
    currentDataYear, currentDataToYear, textSize, currentDataFromMonth, currentDataToMonth,
  } = useAnalysisContext();
  const { data, gettingData, farmName } = useFetch<ProductivityTree>(productivityTreeData, isPrintAll, onLoading);
  const getValue = (calc: number) => formatNumber(data?.data?.[`calc${calc}`]?.current_farm.value, data?.data?.[`calc${calc}`]?.unit === '円' ? '¥' : '', data?.data?.[`calc${calc}`]?.unit === '円' ? '' : data?.data?.[`calc${calc}`]?.unit) || 0;
  const getRank = (calc: number) => data?.data?.[`calc${calc}`]?.current_farm.rank || 0;
  const getScore = (calc: number) => data?.data?.[`calc${calc}`]?.current_farm.score || '';
  return (
    <>
      <If test={gettingData}>
        <div className="text-center p-5">
          <Spinner animation="border" />
        </div>
      </If>
      <section className="" id="reportProductivityTree" style={{ display: gettingData ? 'none' : 'block' }}>
        <div className="page-break-before" />
        <div className="mt-2 row">
          <div className="flex-fill text-sm-start col-4">
            農場ID:
            {farmName}
            <label className="text-muted fs-sm" style={{ fontSize: '0.8rem' }}>
              （期間:
              <span>
                {printYear ?? currentDataYear}
                /
                { formatDate(printFromMonth ?? currentDataFromMonth, 'MM')}
                ～
                {printToYear ?? currentDataToYear}
                /
                {formatDate(printToMonth ?? currentDataToMonth, 'MM')}
                )
              </span>
            </label>
          </div>
          <div className="flex-fill text-sm-center fw-bold col-4">
            {printYear ?? currentDataYear}
            年 生産性ツリー
          </div>
          <div className="flex-fill text-sm-end text-sm  col-4">{largePageName}</div>
        </div>
        <div className="border-bottom my-1" />

        <div className={`fw-bolder px-3 my-3 tree-text-${textSize}`} style={{ lineHeight: '1.0' }} id="treeTable">

          <div className="d-flex justify-content-center">
            <div style={{ width: '100%' }}>
              <div className="text-center">
                粗利益
                <span className="tree-sub-text">（母豚/年）</span>
                <span className={`rank${getScore(59)}`}>{getScore(59)}</span>
                <span className="tree-sub-text ps-2">
                  { `${getRank(59)}位 ${getValue(59)}`}
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <div style={{ width: '28.125%' }} />
            <div style={{ width: '46.875%' }}>
              <div className="border-start border-secondary" style={{ height: '15px', marginLeft: '50%' }} />
              <div className="border border-bottom-0 border-secondary" style={{ height: '10px' }} />
            </div>
          </div>
          <div className="d-flex">
            <div style={{ width: '50%' }}>
              <div className="d-flex">
                <div style={{ width: '37.5%' }} />
                <div style={{ width: '62.5%' }}>
                  <div className="d-flex justify-content-between">
                    <div className="text-start">
                      販売額
                      <span className="tree-sub-text">（母豚/年）</span>
                      <span className={`rank${getScore(57)}`}>{getScore(57)}</span>
                      <br />
                      <span className="tree-sub-text ps-2">
                        { `${getRank(57)}位 ${getValue(57)}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: '50%' }}>
              <div className="d-flex">
                <div style={{ width: '37.5%' }} />
                <div style={{ width: '62.5%' }}>
                  <div className="d-flex justify-content-between">
                    <div className="text-start">
                      飼料費
                      <span className="tree-sub-text">（母豚/年）</span>
                      <span className={`rank${getScore(58)}`}>{getScore(58)}</span>
                      <br />
                      <span className="tree-sub-text ps-2">
                        { `${getRank(58)}位 ${getValue(58)}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '25%' }} />
              <div style={{ width: '62.5%' }}>
                <div className="border-start border-secondary" style={{ height: '15px', marginLeft: '50%' }} />
                <div className="border border-bottom-0 border-secondary" style={{ height: '10px' }} />
              </div>
            </div>
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '50%' }} />
              <div style={{ width: '50%' }}>
                <div className="border-start border-secondary" style={{ height: '100%' }} />
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '12.5%' }} />
              <div style={{ width: '87.5%' }}>
                <div className="d-flex justify-content-between">
                  <div>
                    出荷枝肉重量
                    <span className="tree-sub-text">（母豚/年）</span>
                    <span className={`rank${getScore(47)}`}>{getScore(47)}</span>
                    <br />
                    <span className="tree-sub-text ps-2">
                      { `${getRank(47)}位 ${getValue(47)}`}
                    </span>
                  </div>
                  <div className="text-center">×</div>
                  <div>
                    枝肉価格
                    <span className="tree-sub-text">（/kg）</span>
                    <span className={`rank${getScore(43)}`}>{getScore(43)}</span>
                    <br />
                    <span className="tree-sub-text ps-2">
                      { `${getRank(43)}位 ${getValue(43)}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '50%' }} />
              <div style={{ width: '50%' }}>
                <div className="border-start border-secondary text-end" style={{ height: '100%' }}>
                  <div className="d-flex justify-content-end">
                    <div className="text-start">
                      売上飼料比率
                      <span className={`rank${getScore(52)}`}>{getScore(52)}</span>
                      <br />
                      <span className="tree-sub-text ps-2">
                        { `${getRank(52)}位 ${getValue(52)}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '18.75%' }} />
              <div style={{ width: '62.5%' }}>
                <div className="border-start border-secondary" style={{ height: '15px', marginLeft: '10%' }} />
                <div className="border border-bottom-0 border-secondary" style={{ height: '10px' }} />
              </div>
            </div>
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '37.5%' }} />
              <div style={{ width: '50%' }}>
                <div className="border-start border-secondary" style={{ height: '15px', marginLeft: '25%' }} />
                <div className="border border-bottom-0 border-secondary" style={{ height: '10px' }} />
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '6.25%' }} />
              <div style={{ width: '87.5%' }}>
                <div className="d-flex justify-content-between">
                  <div className="text-start">
                    出荷頭数
                    <span className="tree-sub-text">（母豚/年）</span>
                    <span className={`rank${getScore(45)}`}>{getScore(45)}</span>
                    <br />
                    <span className="tree-sub-text ps-2">
                      { `${getRank(45)}位 ${getValue(45)}`}
                    </span>
                  </div>
                  <div className="text-center">×</div>
                  <div className="text-start">
                    枝肉重量
                    <span className="tree-sub-text">（/頭）</span>
                    <span className={`rank${getScore(46)}`}>{getScore(46)}</span>
                    <br />
                    <span className="tree-sub-text ps-2">
                      { `${getRank(46)}位 ${getValue(46)}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '31.25%' }} />
              <div style={{ width: '62.5%' }}>
                <div className="d-flex justify-content-between">
                  <div className="text-start">
                    飼料金額
                  </div>
                  <div className="text-center">÷</div>
                  <div className="text-center">
                    母豚数
                    <br />
                    <span className="tree-sub-text">
                      { `${getRank(5)}位 ${getValue(5)}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '18.75%' }} />
              <div style={{ width: '62.5%' }}>
                <div className="border-start border-secondary" style={{ height: '20px' }} />
              </div>
            </div>
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '31.25%' }} />
              <div style={{ width: '56.25%' }}>
                <div
                  className="border-start border-secondary"
                  style={{ height: '10px', marginLeft: '11.5%' }}
                />
                <div className="border border-bottom-0 border-secondary" style={{ height: '10px' }} />
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '18.75%' }} />
              <div style={{ width: '62.5%' }}>
                <div className="text-center">
                  <div className="border-start border-secondary" style={{ height: '100%' }}>
                    離乳後事故率
                    {' '}
                    <span className={`rank${getScore(53)}`}>{getScore(53)}</span>
                    <br />
                    <span className="tree-sub-text ps-2">
                      { `${getRank(53)}位 ${getValue(53)}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '25%' }} />
              <div style={{ width: '75%' }}>
                <div className="d-flex justify-content-between">
                  <div className="text-start">
                    総飼料購入量
                  </div>
                  <div className="text-center">×</div>
                  <div className="text-start">
                    飼料価格
                    <span className="tree-sub-text">（/kg）</span>
                    <span className={`rank${getScore(44)}`}>{getScore(44)}</span>
                    <br />
                    <span className="tree-sub-text ps-2">
                      { `${getRank(44)}位 ${getValue(44)}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '18.75%' }} />
              <div style={{ width: '62.5%' }}>
                <div className="border-start border-secondary" style={{ height: '20px' }} />
              </div>
            </div>
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '31.25%' }} />
              <div style={{ width: '68.75%' }}>
                <div className="border-start border-secondary" style={{ height: '20px' }} />
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '12.5%' }} />
              <div style={{ width: '87.5%' }}>
                <div className="text-left">
                  離乳子豚数
                  <span className="tree-sub-text">（母豚/年）</span>
                  <span className={`rank${getScore(60)}`}>{getScore(60)}</span>
                  <br />
                  <span className="tree-sub-text ps-2">
                    { `${getRank(60)}位 ${getValue(60)}`}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '31.25%' }} />
              <div style={{ width: '68.75%' }}>
                <div className="border-start border-secondary text-end" style={{ height: '100%' }}>
                  <div className="d-flex justify-content-end">
                    <div className="text-start">
                      飼料費
                      <span className="tree-sub-text">（/枝肉）</span>
                      <span className={`rank${getScore(67)}`}>{getScore(67)}</span>
                      <br />
                      <span className="tree-sub-text ps-2">
                        { `${getRank(67)}位 ${getValue(67)}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '12.5%' }} />
              <div style={{ width: '75%' }}>
                <div className="border-start border-secondary" style={{ height: '10px', marginLeft: '8%' }} />
                <div className="border border-bottom-0 border-secondary" style={{ height: '10px' }} />
              </div>
            </div>
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '25%' }} />
              <div style={{ width: '62.5%' }}>
                <div className="border-start border-secondary" style={{ height: '10px', marginLeft: '10%' }} />
                <div className="border border-bottom-0 border-secondary" style={{ height: '10px' }} />
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '6.25%' }} />
              <div style={{ width: '93.75%' }}>
                <div className="d-flex justify-content-between">
                  <div className="text-start">
                    離乳子豚数
                    <span className="tree-sub-text">（/腹）</span>
                    <span className={`rank${getScore(66)}`}>{getScore(66)}</span>
                    <br />
                    <span className="tree-sub-text ps-2">
                      { `${getRank(66)}位 ${getValue(66)}`}
                    </span>
                  </div>
                  <div className="text-start">
                    分娩回転率
                    <span className="tree-sub-text">（/年）</span>
                    <span className={`rank${getScore(63)}`}>{getScore(63)}</span>
                    <br />
                    <span className="tree-sub-text ps-2">
                      { `${getRank(63)}位 ${getValue(63)}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '12.5%' }} />
              <div style={{ width: '87.5%' }}>
                <div className="d-flex justify-content-between">
                  <div className="text-start">
                    出荷枝肉重量
                  </div>
                  <div className="text-center">×</div>
                  <div className="text-start">
                    農場枝肉FCR&nbsp;
                    <span className={`rank${getScore(48)}`}>{getScore(48)}</span>
                    <br />
                    <span className="tree-sub-text ps-2">
                      { `${getRank(48)}位 ${getValue(48)}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '12.5%' }} />
              <div style={{ width: '50%' }}>
                <div className="border-start border-secondary" style={{ height: '20px' }} />
              </div>
            </div>
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '18.75%' }} />
              <div style={{ width: '68.75%' }}>
                <div className="border-start border-secondary" style={{ height: '10px', marginLeft: '10%' }} />
                <div className="border border-bottom-0 border-secondary" style={{ height: '10px' }} />
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '12.5%' }} />
              <div style={{ width: '43.75%' }}>
                <div className="text-center">
                  <div className="border-start border-secondary" style={{ height: '100%' }}>
                    ほ乳中死亡率
                    <br />
                    <span className="tree-sub-text">
                      { `${getRank(69)}位 ${getValue(69)}`}
                    </span>
                  </div>
                </div>
              </div>
              <div style={{ width: '43.75%' }}>
                <div className="text-center">
                  分娩率 &nbsp;
                  <span className={`rank${getScore(62)}`}>{getScore(62)}</span>
                  <br />
                  <span className="tree-sub-text">
                    { `${getRank(62)}位 ${getValue(62)}`}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '12.5%' }} />
              <div style={{ width: '81.25%' }}>
                <div className="d-flex justify-content-between">
                  <div className="text-start">
                    出荷枝肉重量
                    <span className="tree-sub-text">（母豚/年）</span>
                    <span className={`rank${getScore(47)}`}>{getScore(47)}</span>
                    <br />
                    <span className="tree-sub-text ps-2">
                      { `${getRank(47)}位 ${getValue(47)}`}
                    </span>
                  </div>
                  <div className="text-center">×</div>
                  <div className="text-center">
                    母豚数
                    <br />
                    <span className="tree-sub-text">
                      { `${getRank(5)}位 ${getValue(5)}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '12.5%' }} />
              <div style={{ width: '43.75%' }}>
                <div className="border-start border-secondary" style={{ height: '20px' }} />
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '6.25%' }} />
              <div style={{ width: '93.75%' }}>
                <div className="d-flex justify-content-between">
                  <div className="text-start">
                    生存子豚数
                    <span className="tree-sub-text">（/腹）</span>
                    <span className={`rank${getScore(65)}`}>{getScore(65)}</span>
                    <br />
                    <span className="tree-sub-text">
                      { `${getRank(65)}位 ${getValue(65)}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '25%' }} />
              <div style={{ width: '75%' }}>
                <div className="text-left">
                  ワクチン・抗菌剤費
                  <span className="tree-sub-text">（/肉豚）</span>
                  <span className={`rank${getScore(51)}`}>{getScore(51)}</span>
                  <br />
                  <span className="tree-sub-text ps-2">
                    { `${getRank(51)}位 ${getValue(51)}`}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '6.25%' }} />
              <div style={{ width: '62.5%' }}>
                <div className="border-start border-secondary" style={{ height: '10px', marginLeft: '10%' }} />
                <div className="border border-bottom-0 border-secondary" style={{ height: '10px' }} />
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '81.25%' }}>
                <div className="d-flex justify-content-between">
                  <div className="text-start">
                    総産子数
                    <span className="tree-sub-text">（/腹）</span>
                    <span className={`rank${getScore(64)}`}>{getScore(64)}</span>
                    <br />
                    <span className="tree-sub-text">
                      { `${getRank(64)}位 ${getValue(64)}`}
                    </span>
                  </div>
                  <div className="text-start">
                    死産数
                    <span className="tree-sub-text">（/腹）</span>
                    <span className={`rank${getScore(68)}`}>{getScore(68)}</span>
                    <br />
                    <span className="tree-sub-text">
                      { `${getRank(68)}位 ${getValue(68)}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex" style={{ width: '50%' }}>
              <div style={{ width: '25%' }} />
              <div style={{ width: '75%' }}>
                <div className="text-start">
                  増体重
                  <span className="tree-sub-text">（g/日）</span>
                  <span className={`rank${getScore(61)}`}>{getScore(61)}</span>
                  <br />
                  <span className="tree-sub-text ps-2">
                    { `${getRank(61)}位 ${getValue(61)}`}
                  </span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default ReportProductivityTree;
