const FarmStatusCode = {
  Input: 0,
  Done: 1,
  Committed: 2,
}

enum FarmStatusLabel {
  Input = '未入力',
  Done = '完了',
  Committed = '確定',
}

 namespace FarmStatus {
  export function getFarmStatusLabel(farmStatus: number): string {
    if (farmStatus === FarmStatusCode.Input) {
      return '';
    }
    if (farmStatus === FarmStatusCode.Done) {
      return FarmStatusLabel.Done;
    }
    if (farmStatus === FarmStatusCode.Committed) {
      return FarmStatusLabel.Committed;
    }
  }
}

export default FarmStatus;


